import JobActionTypes, { JobsErrorType } from './types';

export interface SetErrorAction {
    type: JobActionTypes.SET_ERROR;
    payload: JobsErrorType | null;
}

const setError = (errorMessage: JobsErrorType | null): SetErrorAction => ({
    type: JobActionTypes.SET_ERROR,
    payload: errorMessage,
});

export default setError;
