import { languages } from '../translations/_lang.json';

type language = {
    keyLang: string;
    label: string;
};

export const getAllLangs = (): language[] => languages.sort((a, b) => (a.label < b.label ? -1 : 1));

export const isAValidLanguage = (lang: string): boolean => languages.find((l) => l.keyLang === lang) !== undefined;

export const extractLangFromIso639 = (isoCode: string): string => isoCode.split('-')[0];
