import classNames from 'classnames';
import React, { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    validatePassordLength,
    validatePasswordConfirmation,
    validateCGUApproval,
} from 'core/domain/behavior/validateSignup';
import { FavoriteCommunicationChannel } from 'core/domain/model/user';
import signUserUp from 'core/useCases/session/signUserUp';

import { TextInput, CheckboxInput } from 'components/form/lib';
import { UserData } from 'core/useCases/session/types';
import { signupErrorSelector } from 'core/useCases/session/selectors';
import signUserUpFetcher from 'fetchers/signupUserFetcher';
import ErrorText from '../lib/ErrorMessage/ErrorText';
import styles from './index.module.scss';
import UploadResume from './UploadResume';
import FavoriteCommunicationChannels from './FavoriteCommunicationChannels';

const AVIZIO_CGU_LINK = 'https://www.aviz.io/legal#cgu';
const AVIZIO_PRIVACY_GUIDE_LINK = 'https://www.avizio.fr/legal#privacy';

interface FavoriteCommunicationChannelChoice {
    value: FavoriteCommunicationChannel;
    label: string;
}

interface FormValues {
    password: string;
    verification: string;
    hasReadCgu: boolean;
    resume: FileList;
    favoriteCommunicationChannels: FavoriteCommunicationChannelChoice[];
}

interface SignupFormProps {
    userData: UserData;
}

const SignupForm: FC<SignupFormProps> = ({ userData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const signupError = useSelector(signupErrorSelector);

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            password: '',
            verification: '',
            favoriteCommunicationChannels: [],
        },
    });

    const { handleSubmit, getValues, formState } = methods;

    const { isValid } = formState;

    const onSubmit = handleSubmit(async ({ password, ...values }) => {
        const resume = values.resume && values.resume.length > 0 ? values.resume.item(0) : null;
        const favoriteCommunicationChannels = values.favoriteCommunicationChannels.map((channel) => channel.value);

        dispatch(signUserUp(signUserUpFetcher)(userData.id, password, resume, favoriteCommunicationChannels));
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                {signupError && <ErrorText text={t(`signup.errorMessages.${signupError}`)} />}
                <div className={styles.formContainer}>
                    <TextInput
                        errorMessage="signup.errorMessages.length"
                        label="signup.password.label"
                        name="password"
                        placeholder="signup.password.placeholder"
                        type="password"
                        validate={validatePassordLength}
                    />
                    <TextInput
                        errorMessage="signup.errorMessages.verification"
                        label="signup.verification.label"
                        name="verification"
                        placeholder="signup.verification.placeholder"
                        type="password"
                        validate={(value) => validatePasswordConfirmation(getValues('password'), value)}
                    />
                    <FavoriteCommunicationChannels description="signup.favoriteCommunicationChannels.description" />
                    <p className={styles.notifDisclaimer}>{t('signup.favoriteCommunicationChannels.subtitle')}</p>
                    <UploadResume
                        description="signup.resume.description"
                        label="signup.resume.label"
                        resumeFileName={userData.resumeFileName}
                        resumeLink={userData.resumeLink}
                    />
                    <CheckboxInput
                        errorMessage="signup.errorMessages.checkbox"
                        label="signup.cguAcknowledgement.label"
                        labelData={{ cguLink: AVIZIO_CGU_LINK, privacyLink: AVIZIO_PRIVACY_GUIDE_LINK }}
                        name="hasReadCgu"
                        validate={validateCGUApproval}
                    />
                </div>
                <div className={classNames(styles.formActions)}>
                    <button className={styles.button} disabled={!isValid} type="submit">
                        {t('signup.submit')}
                    </button>
                </div>
            </form>
        </FormProvider>
    );
};

export default SignupForm;
