import User from 'core/domain/model/user';
import { ThunkAction } from 'core/store';
import { setSessionUserAction } from './logInAction';
import SessionActionTypes, { UpdateUserPasswordErrorType } from './types';

export interface UpdateUserPasswordErrorAction {
    type: SessionActionTypes.UPDATE_USER_PASSWORD_ERROR;
    payload: UpdateUserPasswordErrorType | null;
}

export const updateUserPasswordErrorAction = (
    message: UpdateUserPasswordErrorType | null,
): UpdateUserPasswordErrorAction => ({
    type: SessionActionTypes.UPDATE_USER_PASSWORD_ERROR,
    payload: message,
});

export type UserPasswordFetcher = (
    password: string,
    newPassword: string,
    newPasswordConfirmation: string,
) => Promise<User>;

type OnFinish = () => void;
type OnError = () => void;

const updateUserPassword = (userInformationsFetcher: UserPasswordFetcher, onFinish: OnFinish, onError: OnError) => (
    password: string,
    newPassword: string,
    newPasswordConfirmation: string,
): ThunkAction => async (dispatch) => {
    try {
        const user = await userInformationsFetcher(password, newPassword, newPasswordConfirmation);
        dispatch(setSessionUserAction(user));
        dispatch(updateUserPasswordErrorAction(null));
        onFinish();
    } catch (error) {
        dispatch(updateUserPasswordErrorAction(error.message));
        onError();
    }
};

export default updateUserPassword;
