import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { useController } from 'react-hook-form';
import { RangeValue } from 'rc-picker/lib/interface';
import styles from './index.module.scss';
import 'antd/dist/antd.css';

interface DateTimePickerInputProps {
    disabled?: boolean;
    name: string;
    validate?(value: string): boolean;
    placeholder: string | string[];
    defaultValue?: Date | RangeValue<Date>;
    onValueChange?(): void;
    type: 'time' | 'date' | 'datetime' | 'timerange';
}

// andt trick to replace momentjs with date-fns
const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

const DateTimePickerInput: FC<DateTimePickerInputProps> = ({
    disabled = false,
    defaultValue,
    name,
    placeholder,
    validate,
    onValueChange,
    type,
}) => {
    const { t } = useTranslation();
    const {
        field: { onChange, ref },
    } = useController({ name, defaultValue, rules: { validate } });

    switch (type) {
        case 'date':
            return (
                <DatePicker
                    ref={ref}
                    allowClear={false}
                    className={styles.datetimePicker}
                    defaultValue={defaultValue as Date}
                    disabled={disabled}
                    dropdownClassName={styles.dropdownn}
                    format="DD/MM/YYYY"
                    onChange={(date) => {
                        // update value in rhf
                        onChange(date?.toUTCString());

                        if (onValueChange) {
                            // trigger hook
                            onValueChange();
                        }
                    }}
                    placeholder={t(placeholder)}
                    showNow
                />
            );
        case 'timerange':
            return (
                <DatePicker.RangePicker
                    ref={ref}
                    allowClear={false}
                    className={styles.range}
                    defaultValue={defaultValue as RangeValue<Date>}
                    disabled={disabled}
                    dropdownClassName={styles.dropdownn}
                    format="dd/MM/yyyy HH:mm"
                    minuteStep={15}
                    onChange={(date) => {
                        if (date) {
                            onChange(date.map((d) => d?.toUTCString()));
                        }

                        if (onValueChange) {
                            // trigger hook
                            onValueChange();
                        }
                    }}
                    placeholder={placeholder as [string, string]}
                    showSecond={false}
                    showNow
                    showTime
                />
            );
        case 'datetime':
            return (
                <DatePicker
                    ref={ref}
                    allowClear={false}
                    className={styles.datetimePicker}
                    defaultValue={defaultValue as Date}
                    disabled={disabled}
                    dropdownClassName={styles.dropdownn}
                    format="DD/MM/YYYY kk:mm"
                    onChange={(date) => {
                        // update value in rhf
                        onChange(date?.toUTCString());

                        if (onValueChange) {
                            // trigger hook
                            onValueChange();
                        }
                    }}
                    placeholder={t(placeholder)}
                    showTime={{ minuteStep: 15, format: 'HH:mm' }}
                    showNow
                />
            );
        default:
            return (
                <DatePicker
                    ref={ref}
                    allowClear={false}
                    className={styles.datetimePicker}
                    defaultValue={defaultValue as Date}
                    disabled={disabled}
                    dropdownClassName={styles.dropdownn}
                    format="DD/MM/YYYY kk:mm"
                    onChange={(date) => {
                        // update value in rhf
                        onChange(date?.toUTCString());

                        if (onValueChange) {
                            // trigger hook
                            onValueChange();
                        }
                    }}
                    placeholder={t(placeholder)}
                    showTime={{ minuteStep: 15, format: 'HH:mm' }}
                    showNow
                />
            );
    }
};

export default DateTimePickerInput;
