import classNames from 'classnames';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import BACKEND_URL from 'utils/backendUrl';
import { TextInput } from '../lib/index';
import { validateEmail } from '../../../core/domain/behavior/validateLogin';
import { POST } from '../../../utils/http';
import useModal from '../../../hooks/modal';
import FormModal from '../../layouts/shared/Modal/FormModal';
import styles from './index.module.scss';

interface FormValues {
    email: string;
}

interface ResponseData {
    token: string;
}

const resetPasswordRequest = async (email: string) => {
    try {
        const response = (await POST(`${BACKEND_URL}/users/${email}/reset-password`)) as ResponseData;

        return response;
    } catch (error) {
        return error;
    }
};

const ResetPasswordForm: FC = () => {
    const { t } = useTranslation();
    const { isOpen, toggleModal } = useModal();
    const history = useHistory();

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            email: '',
        },
    });

    const { handleSubmit, formState } = methods;

    const { isValid } = formState;

    const onSubmit = handleSubmit(async (values) => {
        const { email } = values;
        toggleModal();
        await resetPasswordRequest(email);
    });

    const onCloseModal = () => {
        toggleModal();
        history.push('/login');
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <div className={styles.formContainer}>
                    <TextInput
                        errorMessage="resetPassword.errorMessages.text"
                        label="resetPassword.email.label"
                        name="email"
                        placeholder="resetPassword.email.placeholder"
                        type="email"
                        validate={validateEmail}
                    />
                </div>
                <div className={classNames(styles.formActions)}>
                    <button className={styles.button} disabled={!isValid} type="submit">
                        {t('resetPassword.submit')}
                    </button>
                </div>
                {isOpen && (
                    <FormModal closeModal={onCloseModal} titleKey="resetPassword.modal.title">
                        <div className={styles.modal}>
                            <p>{t('resetPassword.modal.content')}</p>
                        </div>
                    </FormModal>
                )}
            </form>
        </FormProvider>
    );
};

export default ResetPasswordForm;
