import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Visibility from 'images/visibility.svg';
import Invisible from 'images/invisible.svg';
import ErrorMessage from '../ErrorMessage';
import styles from './index.module.scss';

interface TextInputProps {
    name: string;
    label: string;
    validate?(value: string): boolean;
    disabled?: boolean;
    placeholder?: string;
    errorMessage?: string;
    type?: 'text' | 'password' | 'email';
    showPassword?: boolean;
}

const TextInput: FC<TextInputProps> = ({
    name,
    label,
    validate,
    placeholder,
    errorMessage,
    type = 'text',
    disabled = false,
    showPassword,
}) => {
    const { register } = useFormContext();
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [inputType, setInputType] = useState(type);

    useEffect(() => {
        if (visible) {
            setInputType('text');
        } else {
            setInputType(type);
        }
    }, [visible]);

    return (
        <div className={styles.textInputWrapper}>
            <label className={styles.label} htmlFor={name}>
                {t(label)}
            </label>
            <div className={styles.input}>
                <input
                    ref={register({ validate })}
                    className={styles.textInput}
                    disabled={disabled}
                    name={name}
                    type={inputType}
                    {...(placeholder && { placeholder: t(placeholder) })}
                />
                {type === 'password' && showPassword && (
                    <button className={styles.showPwd} onClick={() => setVisible(!visible)} type="button">
                        {!visible ? (
                            <img alt="show password" src={Visibility} />
                        ) : (
                            <img alt="hide password" src={Invisible} />
                        )}
                    </button>
                )}
            </div>

            <ErrorMessage errorMessage={errorMessage} name={name} />
        </div>
    );
};

export default TextInput;
