import { createSelector } from 'reselect';
import { compareAsc, compareDesc, parseISO } from 'date-fns';
import { Selector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Job, { JobStage } from 'core/domain/model/job';
import { RootState } from 'core/store';
import LoadingState from '../types';
import NextStep, { getLabel, NextStepDefaultLabel } from '../../domain/model/nextStep';
import { JobsErrorType, UserInterestErrorType } from './types';

export type StagedJobs = Record<JobStage, Job[]>;

const jobsSelector = (state: RootState): Job[] => state.job.list;

export const jobsFormatter = (jobs: Job[]): StagedJobs =>
    jobs
        .sort((a, b) => compareDesc(parseISO(a.updatedAt), parseISO(b.updatedAt)))
        .reduce(
            (_jobs, job) => {
                const { stage } = job;
                const sameStageJobs = _jobs[stage];

                return { ..._jobs, [stage]: [...sameStageJobs, job] };
            },
            {
                [JobStage.SUGGESTION]: [],
                [JobStage.ONGOING]: [],
                [JobStage.OFFER]: [],
                [JobStage.PLACED]: [],
                [JobStage.NOT_CONCLUDE]: [],
            },
        );

export const stagedJobsSelector = createSelector(jobsSelector, jobsFormatter);

export const jobsAreLoadingSelector = (state: RootState): boolean => state.job.loading === LoadingState.LOADING;
export const jobsAreLoadedSelector = (state: RootState): boolean => state.job.loading === LoadingState.LOADED;
export const jobsErrorSelector = (state: RootState): JobsErrorType | null => state.job.error;

export const activeJobSelector = (id: string | undefined) => (state: RootState): Job | null => {
    const job = state.job.list.find((_job) => _job.crmId === id);

    return job || null;
};

const alphabeticalSort = (a: string, b: string) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }

    return 0;
};

const getFullLabel = (nextStep: NextStep | undefined, t: TFunction) => {
    const label = getLabel(nextStep);

    if (!label) {
        return null;
    }

    if (label.default) {
        return t(`jobCard.expand.tabs.myNextStep.content.form.label.options.${label.value}`);
    }

    return label.value;
};

export const activeJobNextStepsSelector = (id: string | undefined) => (state: RootState): NextStep[] => {
    const { t } = useTranslation();

    const job = state.job.list.find((_job) => _job.crmId === id);

    return (
        job?.nextSteps.sort((nextStep1, nextStep2) => {
            if (nextStep1.defaultLabel === NextStepDefaultLabel.POSITION_ON_JOB) {
                return -1;
            }

            if (nextStep2.defaultLabel === NextStepDefaultLabel.POSITION_ON_JOB) {
                return 1;
            }

            const dateDifference = compareAsc(
                new Date(nextStep1.dueDate).setSeconds(0),
                new Date(nextStep2.dueDate).setSeconds(0),
            );

            if (dateDifference !== 0) {
                return dateDifference;
            }

            const nextStep1Label = getFullLabel(nextStep1, t);
            const nextStep2Label = getFullLabel(nextStep2, t);

            if (!nextStep1Label || !nextStep2Label) {
                return 0;
            }

            return alphabeticalSort(nextStep1Label, nextStep2Label);
        }) || []
    );
};

const nextStepsLabelFormatter = (nextSteps: NextStep[]): string | null => {
    const { t } = useTranslation();

    if (!nextSteps.length) {
        return null;
    }

    const nextStep = nextSteps.find((_nextStep) => !_nextStep.done);

    return getFullLabel(nextStep, t);
};

export const nextStepLabelSelector = (id: string | undefined): Selector<RootState, string | null> =>
    createSelector(activeJobNextStepsSelector(id), nextStepsLabelFormatter);

const hasBeenSentSelector = (job: Job | null) => !!job && job.userInterestHasBeenSent;

export const userInterestHasBeenSentSelector = (id: string): Selector<RootState, boolean> =>
    createSelector(activeJobSelector(id), hasBeenSentSelector);

export const userInterestIsLoadingSelector = (state: RootState): boolean =>
    state.job.userInterestLoading === LoadingState.LOADING;

export const userInterestErrorSelector = (state: RootState): UserInterestErrorType | null =>
    state.job.userInterestError;

export const refreshJobListSelector = (state: RootState): boolean | null => state.job.refresh;
