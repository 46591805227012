import React, { FC, useState } from 'react';
import PlaceholderLogo from '../../images/logo-placeholder.svg';
import Job from '../../core/domain/model/job';
import styles from './index.module.scss';

interface CompanyLogoProps {
    job: Job;
}

const CompanyLogo: FC<CompanyLogoProps> = ({ job }) => {
    const [logo, setLogo] = useState((job && job.company.avatarUrl) || PlaceholderLogo);

    const onError = () => {
        setLogo(PlaceholderLogo);
    };

    return <img alt="company logo" className={styles.companyLogo} onError={onError} src={logo} />;
};

export default CompanyLogo;
