import TalentEvent from 'core/domain/model/event';
import { EventsFetcher } from 'core/useCases/events/setEventsAction';
import { JobsErrorType } from 'core/useCases/jobs/types';
import BACKEND_URL from 'utils/backendUrl';
import { GET } from 'utils/http';

interface GetEventsResponse {
    events: TalentEvent[];
}

const eventsFetcher: EventsFetcher = async () => {
    try {
        const response = (await GET<GetEventsResponse>(`${BACKEND_URL}/google-apis/events`)) as GetEventsResponse;

        return response.events;
    } catch (error) {
        throw new Error(JobsErrorType.RETRIVAL_ERROR);
    }
};

export default eventsFetcher;
