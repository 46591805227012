import TalentNotification from 'core/domain/model/talentNotification';
import { ThunkAction } from 'core/store';
import SessionActionTypes from './types';

export interface SetNotifications {
    type: SessionActionTypes.SET_NOTIFICATIONS;
    payload: TalentNotification[];
}

export const setNotifications = (talentNotifications: TalentNotification[]): SetNotifications => ({
    type: SessionActionTypes.SET_NOTIFICATIONS,
    payload: talentNotifications,
});

export type ReadAllNotificationsFetcher = (id: string) => Promise<TalentNotification[]>;

const readAllNotifications = (readAllNotificationsFetcher: ReadAllNotificationsFetcher) => (
    id: string,
): ThunkAction => async (dispatch) => {
    const talentNotification = await readAllNotificationsFetcher(id);
    dispatch(setNotifications(talentNotification));
};

export default readAllNotifications;
