import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './index.module.scss';

const ActivityPlaceholder: FC = () => (
    <div className={styles.jobCardWrapper}>
        <div className={styles.jobCardContent}>
            <div className={styles.jobInfos}>
                <div className={classNames(styles.textPlaceholder, styles.placeholder)} />
            </div>
            <div className={styles.companyAvatarWrapper}>
                <div className={styles.companyLogo}>
                    <div className={classNames(styles.avatarPlaceholder, styles.placeholder)} />
                </div>
                <div className={classNames(styles.avatarTextPlaceholder, styles.placeholder)} />
            </div>
            <div className={styles.jobInfos}>
                <div className={classNames(styles.textPlaceholder, styles.placeholder)} />
            </div>
        </div>
    </div>
);

export default ActivityPlaceholder;
