import TalentEvent from 'core/domain/model/event';
import { CreateEventsFetcher } from 'core/useCases/events/createEventAction';
import { JobsErrorType } from 'core/useCases/jobs/types';
import BACKEND_URL from 'utils/backendUrl';
import { POST } from 'utils/http';

interface CreateEventResponse {
    event: TalentEvent;
}

export type CreateEventArgs = {
    id?: string;
    userId: string;
    title: string;
    startTime?: Date;
    endTime?: Date;
    attendees: string[];
    isRemote: boolean;
    place?: string;
    isCustom?: boolean;
};

const createCustomEventsFetcher: CreateEventsFetcher = async (payload: CreateEventArgs) => {
    try {
        const response = (await POST<CreateEventResponse>(
            `${BACKEND_URL}/calendar-events/create`,
            payload,
        )) as CreateEventResponse;

        return response.event;
    } catch (error) {
        throw new Error(JobsErrorType.RETRIVAL_ERROR);
    }
};

export default createCustomEventsFetcher;
