import React, { FC } from 'react';
import Job from 'core/domain/model/job';
import JobCard, { JobcardVariant } from 'components/JobCard';
import useExpandedJob from 'hooks/expandedJob';
import EmptyColumn, { EmptyColumnVariant } from '../EmptyColumn';

interface JobsProps {
    jobs: Job[];
    isEmpty: boolean;
    placeholder: string;
    light: boolean;
}

const Jobs: FC<JobsProps> = ({ jobs, isEmpty, placeholder, light }) => {
    const { expandedJob } = useExpandedJob();

    if (isEmpty) {
        return (
            <EmptyColumn
                placeholder={placeholder}
                variant={light ? EmptyColumnVariant.OUTLINED : EmptyColumnVariant.BASE}
            />
        );
    }

    return (
        <>
            {jobs.map((job) => (
                <JobCard
                    key={job.id}
                    expanded={expandedJob?.crmId === job.crmId}
                    job={job}
                    variant={light ? JobcardVariant.GOLD : JobcardVariant.BASE}
                />
            ))}
        </>
    );
};

export default Jobs;
