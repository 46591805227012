import { Tooltip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import logUserOut from 'core/useCases/session/logOutAction';
import useToggle from 'hooks/toggle';
import information from 'images/information.svg';
import megaphoneIcon from 'images/icon-megaphone.svg';
import notificationFullIcon from 'images/Notification-full.svg';
import notificationIcon from 'images/Notification.svg';
import useModal from 'hooks/modal';
import { sessionSelector } from 'core/useCases/session/selectors';
import User from 'core/domain/model/user';
import gtmAttributes from 'utils/gtmAttributes';
import toggleNotification from 'core/useCases/session/toggleNotification';
import readAllNotifications from 'core/useCases/session/readAllNotifications';
import toggleNotificationFetcher from 'fetchers/toggleNotificationFetcher';
import readAllNotificationsFetcher from 'fetchers/readAllNotificationsFetcher';
import logoutFetcher from 'fetchers/logoutFetcher';
import MenuDesktop from '../../MenuDesktop';
import InitialsIcon from '../../../shared/InitialsIcon';
import PopupDisconnect from '../../../shared/PopupDisconnect';
import { HELP_FAQ_LINK, RECOMMEND_TALENT_LINK } from '../../../../../utils/constants';
import NotificationsDesktop from '../../NotificationsDesktop';
import styles from './index.module.scss';

interface HeaderConnectedProps {
    title: string | null;
    additionalFeature(): React.ReactNode | void;
}

const HeaderConnected: React.FC<HeaderConnectedProps> = ({ title, additionalFeature }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector(sessionSelector) as User;

    const [menuIsOpen, toggleMenu] = useToggle(false);
    const [notificationsIsOpen, toggleNotificationsIsOpen] = useToggle(false);
    const { isOpen: submenuIsOpen, openModal, closeModal } = useModal();

    const hasUnreadNotifications = Boolean(user?.talentNotifications?.filter((n) => !n.read)?.length);

    const lougUserOut = () => {
        dispatch(logUserOut(logoutFetcher)());
        closeModal();
    };

    const onToggleNotification = (id: string) => {
        dispatch(toggleNotification(toggleNotificationFetcher)(id));
    };

    const readAll = () => {
        dispatch(readAllNotifications(readAllNotificationsFetcher)(user.id));
    };

    const defaultFeature = () => {
        return <span>Toto</span>;
    };

    return (
        <>
            <header className={styles.fullNav}>
                {additionalFeature ? additionalFeature() : defaultFeature()}
                <div className={styles.navTitle}>
                    <h1>{title}</h1>
                </div>
                <div className={styles.nav}>
                    <Tooltip placement="bottom" title={t('header.menu.notifications') as string} arrow>
                        <div className={styles.navRight}>
                            <button data-gtm-id="header-menu" onClick={toggleNotificationsIsOpen} type="button">
                                <img
                                    alt="notification"
                                    src={hasUnreadNotifications ? notificationFullIcon : notificationIcon}
                                />
                            </button>
                        </div>
                    </Tooltip>
                    <Tooltip placement="bottom" title={t('header.recommendation') as string} arrow>
                        <div className={styles.navRight}>
                            <a
                                href={RECOMMEND_TALENT_LINK}
                                rel="noreferrer noopener"
                                target="_blank"
                                {...gtmAttributes('header-reco')}
                            >
                                <img alt="recommend-talent" src={megaphoneIcon} />
                            </a>
                        </div>
                    </Tooltip>
                    <Tooltip placement="bottom" title={t('header.help') as string} arrow>
                        <div className={styles.navRight}>
                            <a
                                href={HELP_FAQ_LINK}
                                rel="noreferrer noopener"
                                target="_blank"
                                {...gtmAttributes('header-faq')}
                            >
                                <img alt="help" src={information} />
                            </a>
                        </div>
                    </Tooltip>
                    <div className={styles.navRight}>
                        <button data-gtm-id="header-menu" onClick={toggleMenu} type="button">
                            <InitialsIcon user={user} />
                        </button>
                    </div>
                </div>
            </header>
            {notificationsIsOpen && (
                <NotificationsDesktop
                    hasUnreadNotifications={hasUnreadNotifications}
                    notifications={user.talentNotifications}
                    onClose={toggleNotificationsIsOpen}
                    onToggleNotification={onToggleNotification}
                    readAll={readAll}
                />
            )}
            {menuIsOpen && <MenuDesktop onClose={toggleMenu} onDisconnect={openModal} user={user} />}
            {submenuIsOpen && <PopupDisconnect onAccept={lougUserOut} onCancel={closeModal} />}
        </>
    );
};

export default HeaderConnected;
