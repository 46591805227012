import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormModal from '../FormModal';
import talentManagerSelector from '../../../../../core/useCases/talentManager/selectors';
import { AVIZIO_HOME_LINK } from '../../../../../utils/constants';
import clockIcon from '../../../../../images/clock.svg';
import mailIcon from '../../../../../images/icon-mail.svg';
import styles from './index.module.scss';

interface StopProcessModalProps {
    closeModal(): void;
}

const StopProcessModal: FC<StopProcessModalProps> = ({ closeModal }) => {
    const talentManager = useSelector(talentManagerSelector);
    const { t } = useTranslation();

    return (
        <FormModal
            closeModal={closeModal}
            titleKey="jobCard.expand.stop.modalTitle"
            titleParams={{ talentManager: talentManager?.firstName }}
        >
            <div className={styles.modalWrapper}>
                <p>{t('jobCard.expand.stop.modalContent', { talentManager: talentManager?.firstName })}</p>
                <div className={styles.modalButtons}>
                    <a href={talentManager?.calendarLink || AVIZIO_HOME_LINK} rel="noreferrer noopener" target="_blank">
                        <img alt="clock" src={clockIcon} />
                        {t('talentManagerContact.book', { talentManager: talentManager?.firstName })}
                    </a>
                    <a href={`mailto:${talentManager?.email}`} rel="noreferrer noopener" target="_blank">
                        <img alt="comment" src={mailIcon} />
                        {t('jobCard.expand.tabs.process.content.sendMessage', {
                            talentManager: talentManager?.firstName,
                        })}
                    </a>
                </div>
                <p>
                    {t('jobCard.expand.tabs.process.content.phoneNumber')}
                    <a
                        className={styles.modalPhone}
                        href={`tel:${talentManager?.contactNumber}`}
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        {talentManager?.contactNumber}
                    </a>
                </p>
            </div>
        </FormModal>
    );
};

export default StopProcessModal;
