import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Job from 'core/domain/model/job';
import styles from './index.module.scss';

interface ProcessMessageProps {
    talentManagerName: string | undefined;
    job: Job;
}

const ProcessMessage: FC<ProcessMessageProps> = ({ talentManagerName, job }) => {
    const { t } = useTranslation();

    const {
        stage,
        company: { name: companyName },
    } = job;

    return (
        <>
            <h2 className={styles.jobCardExpandElement}>
                {t(`jobCard.expand.tabs.process.content.${job.stage}.title`)}
            </h2>
            <p className={styles.jobCardExpandElement}>
                {t(`jobCard.expand.tabs.process.content.${stage}.content.part1`, { talentManager: talentManagerName })}
                <span className={styles.jobCardExpandBottomContentCompany}>{companyName}</span>
                {t(`jobCard.expand.tabs.process.content.${stage}.content.part2`, { talentManager: talentManagerName })}
            </p>
            <p className={styles.jobCardExpandElement}>
                {t(`jobCard.expand.tabs.process.content.${stage}.content.part3`, { talentManager: talentManagerName })}
            </p>
        </>
    );
};

export default ProcessMessage;
