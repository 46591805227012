import NotificationConfig from 'core/domain/model/notificationConfig';
import User from 'core/domain/model/user';
import { ThunkAction } from 'core/store';
import { setSessionUserAction } from './logInAction';

export type UserNotifConfigFetcher = (userId: string, configs: NotificationConfig[]) => Promise<User>;

type OnFinish = () => void;

const updateUserNotifConfig = (userNotifConfigFetcher: UserNotifConfigFetcher, onFinish: OnFinish) => (
    userId: string,
    configs: NotificationConfig[],
): ThunkAction => async (dispatch) => {
    try {
        const user = await userNotifConfigFetcher(userId, configs);
        dispatch(setSessionUserAction(user));
        onFinish();
    } catch (err) {
        console.error(err);
    }
};

export default updateUserNotifConfig;
