import React, { FC } from 'react';
import Job from 'core/domain/model/job';
import TabElement from '../TabElement';
import JobDescription from './JobDescription';
import styles from './index.module.scss';
import JobInfos from './JobInfos';

interface AboutJobProps {
    job: Job;
}

export type AboutJobCompType = FC<AboutJobProps>;

const AboutJob: AboutJobCompType = ({ job }) => {
    return (
        <TabElement>
            <div className={styles.blocks}>
                <JobDescription job={job} />
                <JobInfos job={job} />
            </div>
        </TabElement>
    );
};

export default AboutJob;
