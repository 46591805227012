import User from '../../domain/model/user';
import { ThunkAction } from '../../store';
import { updateUserPasswordErrorAction } from './updateUserPassword';

export type UserNewPasswordFetcher = (
    id: string,
    token: string,
    newPassword: string,
    newPasswordConfirmation: string,
) => Promise<User>;

const updateUserNewPassword = (userInformationFetcher: UserNewPasswordFetcher) => (
    id: string,
    token: string,
    newPassword: string,
    newPasswordConfirmation: string,
): ThunkAction => async (dispatch, getState, { history }) => {
    try {
        await userInformationFetcher(id, token, newPassword, newPasswordConfirmation);
        history.push('/login');

        dispatch(updateUserPasswordErrorAction(null));
    } catch (error) {
        console.error('error', error);
        dispatch(updateUserPasswordErrorAction(error.message));
    }
};

export default updateUserNewPassword;
