import NotificationConfig from './notificationConfig';
import TalentNotification from './talentNotification';

export enum FavoriteCommunicationChannel {
    PHONE_CALL = 'phoneCall',
    EMAIL = 'email',
    SMS = 'sms',
    WHATSAPP = 'whatsapp',
}

interface GoogleToken {
    // eslint-disable-next-line camelcase
    access_token: string;
    // eslint-disable-next-line camelcase
    expiry_date: number;
    // eslint-disable-next-line camelcase
    refresh_token: string;
    scope: string;
    // eslint-disable-next-line camelcase
    token_type: string;
}

interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string | null;
    crmId: string;
    isActivated: boolean;
    activationToken: string | null;
    resetPasswordToken: string | null;
    favoriteCommunicationChannels: FavoriteCommunicationChannel[];
    resumeFileName: string | null;
    resumeLink: string | null;
    talentNotifications: TalentNotification[];
    notificationsConfigs: NotificationConfig[];
    googleToken: GoogleToken;
}

export default User;
