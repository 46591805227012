import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import styles from './index.module.scss';

interface SelectInputProps {
    placeholder: string;
    inputParams: AutocompleteRenderInputParams;
}

const SelectInput: FC<SelectInputProps> = ({ inputParams, placeholder }) => {
    const { t } = useTranslation();

    return (
        <TextField
            {...inputParams}
            InputLabelProps={{ shrink: true }}
            className={styles.input}
            placeholder={t(placeholder)}
            variant="outlined"
        />
    );
};

export default SelectInput;
