import BACKEND_URL from 'utils/backendUrl';
import { POST } from 'utils/http';
import User from 'core/domain/model/user';
import { LoginErrorType } from 'core/useCases/session/types';

interface ResponseData {
    user: User;
}

const loginFetcher = async (email: string, password: string): Promise<User> => {
    try {
        const response = (await POST(`${BACKEND_URL}/auth/login`, {
            email: email.toLowerCase(),
            password,
        })) as ResponseData;

        return response.user;
    } catch (error) {
        if (error.status === 401) {
            throw new Error(LoginErrorType.AUTH_ERROR);
        }

        throw new Error(LoginErrorType.UNKNOWN_ERROR);
    }
};

export default loginFetcher;
