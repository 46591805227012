import classNames from 'classnames';
import React, { FC } from 'react';
import HeaderDisconnected from './HeaderDisconnected';
import styles from './index.module.scss';

const DisconnectedLayoutMobile: FC = ({ children }) => (
    <>
        <HeaderDisconnected />
        <div className={styles.baseContainer}>
            <div className={classNames(styles.centeredContainer, styles.centeredContainerSized)}>{children}</div>
        </div>
    </>
);

export default DisconnectedLayoutMobile;
