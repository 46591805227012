import NextStep, { NextStepCustomLabel, NextStepDefaultLabel } from 'core/domain/model/nextStep';
import { ThunkAction } from 'core/store';
import { setNextStepLabels } from '../session/getNextStepLabelOptions';
import NextStepActionTypes from './types';

export interface SetNextStepAction {
    type: NextStepActionTypes.SET_NEXT_STEP;
    payload: NextStep;
}

export const setNextStepAction = (nextStep: NextStep): SetNextStepAction => ({
    type: NextStepActionTypes.SET_NEXT_STEP,
    payload: nextStep,
});

export type CreateNextStepFetcher = (jobCrmId: string) => Promise<NextStep>;

export const createNextStep = (nextStepFetcher: CreateNextStepFetcher) => (jobCrmId: string): ThunkAction => async (
    dispatch,
) => {
    const nextStep = await nextStepFetcher(jobCrmId);
    dispatch(setNextStepAction(nextStep));
};

export interface Label {
    default: boolean;
    value: string | NextStepDefaultLabel;
    existing: boolean;
}

export interface NextStepData {
    label: Label;
    dueDate: string | null;
    done: boolean;
}

export type UpdateNextStepFetcher = (
    jobCrmId: string,
    nextStepId: string,
    nextStepData: NextStepData,
) => Promise<{ nextStep: NextStep; labels: NextStepCustomLabel[] }>;

export const updateNextStep = (nextStepFetcher: UpdateNextStepFetcher) => (
    jobCrmId: string,
    nextStepId: string,
    nextStepData: NextStepData,
): ThunkAction => async (dispatch) => {
    const data = {
        label: nextStepData.label || null,
        dueDate: nextStepData.dueDate || null,
        done: !!nextStepData.done,
    };

    const { nextStep, labels } = await nextStepFetcher(jobCrmId, nextStepId, data);
    dispatch(setNextStepAction(nextStep));
    dispatch(setNextStepLabels(labels));
};
