import { MutableRefObject, useEffect } from 'react';

interface Params {
    ref: MutableRefObject<HTMLDivElement | null>;
    submenuIsOpen: boolean;
    callback: () => void;
}

function useClickOutside({ ref, submenuIsOpen, callback }: Params): void {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLDivElement;

            if (ref.current && !ref.current.contains(target) && !submenuIsOpen) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, submenuIsOpen]);
}

export default useClickOutside;
