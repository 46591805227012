import { ThunkAction } from 'core/store';
import LoadingState from '../types';
import { setSessionLoading } from './setLoadingAction';
import SessionActionTypes from './types';

export interface ClearSessionUserAction {
    type: SessionActionTypes.CLEAR_SESSION_USER;
}

export const clearSessionUserAction = (): ClearSessionUserAction => ({
    type: SessionActionTypes.CLEAR_SESSION_USER,
});

export type LogoutFetcher = () => Promise<void>;

const logUserOut = (logoutFetcher: LogoutFetcher) => (): ThunkAction => async (dispatch) => {
    try {
        await logoutFetcher();
    } catch (error) {
        // TODO : Handle potential error
    } finally {
        // Log user out locally anyway
        dispatch(clearSessionUserAction());
        dispatch(setSessionLoading(LoadingState.LOADED));
    }
};

export default logUserOut;
