import User from 'core/domain/model/user';
import BACKEND_URL from 'utils/backendUrl';
import { GET } from 'utils/http';

interface ResponseData {
    user: User;
}

const sessionFetcher = async (): Promise<User> => {
    const { user } = (await GET(`${BACKEND_URL}/users/me`)) as ResponseData;

    return user;
};

export default sessionFetcher;
