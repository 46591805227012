import TalentEvent from 'core/domain/model/event';
import { ThunkAction } from '../../store';
import LoadingState from '../types';
import setError from './setError';
import setLoading from './setLoading';
import EventActionTypes from './types';

export interface SetEventsAction {
    type: EventActionTypes.SET_EVENTS;
    payload: TalentEvent[];
}

export const setEvents = (jobs: TalentEvent[]): SetEventsAction => ({
    type: EventActionTypes.SET_EVENTS,
    payload: jobs,
});

export type EventsFetcher = (backendUrl: string, isMock?: boolean) => Promise<TalentEvent[]>;

const listUserEventsAction = (eventsFetcher: EventsFetcher, isMock?: boolean) => (): ThunkAction => async (
    dispatch,
    getState,
    { backendUrl },
) => {
    dispatch(setLoading(LoadingState.LOADING));
    try {
        const events = await eventsFetcher(backendUrl, isMock);
        dispatch(setError(null));
        dispatch(setEvents(events));
    } catch (error) {
        dispatch(setError(error.message));
    } finally {
        dispatch(setLoading(LoadingState.LOADED));
    }
};

export default listUserEventsAction;
