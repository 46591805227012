import { useLocation } from 'react-router';
import qs from 'qs';

const useQueryParams = (): unknown => {
    const location = useLocation();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    return params;
};

export default useQueryParams;
