import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleModal from 'components/layouts/shared/Modal/SimpleModal';
import Spinner from 'components/layouts/shared/Spinner';
import styles from './index.module.scss';

interface AskModalProps {
    titleKey: string;
    titleData?: Record<string, unknown>;
    submitValues(text: string): void;
    cancel(): void;
    loading: boolean;
    subtitleKey: string;
}

const AskModal: FC<AskModalProps> = ({ submitValues, cancel, loading, titleKey, subtitleKey, titleData }) => {
    const { t } = useTranslation();
    const [text, setText] = useState('');

    return (
        <SimpleModal>
            {loading ? (
                <>
                    <div className="spinnerWrapper">
                        <Spinner />
                    </div>
                    <p className="messageWrapper">{t('popup.loading')}</p>
                </>
            ) : (
                <>
                    <h2 className={styles.title}>{t(titleKey, titleData)}</h2>
                    <p className={styles.subtitle}>{t(subtitleKey)}</p>
                    <div className={styles.textInputWrapper}>
                        <textarea
                            className={styles.textInput}
                            onChange={(e) => setText(e.target.value)}
                            rows={8}
                            value={text}
                        />
                    </div>
                    <div className="popupButtonsWrapper">
                        <button onClick={cancel} type="button">
                            {t('myInformations.confirmation.cancel')}
                        </button>
                        <button disabled={!text} onClick={() => submitValues(text)} type="button">
                            {t('myInformations.confirmation.confirm')}
                        </button>
                    </div>
                </>
            )}
        </SimpleModal>
    );
};

export default AskModal;
