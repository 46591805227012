import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StagedJobs } from 'core/useCases/jobs/selectors';
import useColumns, { Column as ColumnType } from 'hooks/columns';
import useExpandedJob from 'hooks/expandedJob';
import Column from '../shared/Column';
import useQueryParams from '../../../utils/queryParams';
import Carousel from './Carousel';

const useActiveColumn = (jobs: StagedJobs): number | undefined => {
    const { t } = useTranslation();
    const columns = useColumns(jobs);
    const { expandedJob } = useExpandedJob();
    const { stage: stageUrl } = useQueryParams() as { stage: string };
    const stage = t(`columns.columnUrl.${stageUrl}`);

    const expandedColumnIndex = expandedJob ? columns.findIndex((column) => column.stage === expandedJob.stage) : -1;
    const activeColumnIndex = columns.findIndex((column) => column.stage === stage);

    return expandedColumnIndex === -1 ? activeColumnIndex : expandedColumnIndex;
};

interface KanbanDesktopProps {
    jobs: StagedJobs;
    columns: ColumnType[];
}

const KanbanMobile: FC<KanbanDesktopProps> = ({ jobs, columns }) => {
    const activeColumn = useActiveColumn(jobs);

    return (
        <Carousel activeFrameIndex={activeColumn}>
            {columns.map(({ title, backgroundType, placeholder, alwaysVisible, stage }) => (
                <Column
                    key={stage}
                    alwaysVisible={alwaysVisible}
                    backgroundType={backgroundType}
                    jobs={jobs[stage]}
                    placeholder={placeholder}
                    title={title}
                />
            ))}
        </Carousel>
    );
};

export default KanbanMobile;
