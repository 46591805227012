import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LoginForm from 'components/form/LoginForm';

const LoginPage: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <h2>{t('login.title')}</h2>
            <LoginForm />
        </>
    );
};

export default LoginPage;
