/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import syncSession from 'core/useCases/session/syncSessionAction';
import sessionFetcher from 'fetchers/sessionFetcher';

const AuthInitializer: FC = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(syncSession(sessionFetcher)());
    }, []);

    return <>{children}</>;
};

export default AuthInitializer;
