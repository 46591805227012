import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import gtmAttributes from 'utils/gtmAttributes';
import talentManagerSelector from '../../core/useCases/talentManager/selectors';
import { AVIZIO_HOME_LINK } from '../../utils/constants';
import styles from './index.module.scss';

const TalentManagerContact: FC = () => {
    const { t } = useTranslation();
    const talentManager = useSelector(talentManagerSelector);

    return (
        <div className={styles.tmcWrapper}>
            <div className={styles.talentManagerTitle}>{t('talentManagerContact.title')}</div>
            <span>
                {talentManager?.firstName} {talentManager?.lastName}
            </span>
            <a
                href={`tel:${talentManager?.contactNumber}`}
                rel="noreferrer noopener"
                target="_blank"
                {...gtmAttributes('talenblock-tel')}
            >
                <span>{talentManager?.contactNumber}</span>
            </a>
            <a
                href={`mailto:${talentManager?.email}`}
                rel="noreferrer noopener"
                target="_blank"
                {...gtmAttributes('talenblock-email')}
            >
                <span>{talentManager?.email}</span>
            </a>
            <div className={styles.bookButtonContainer}>
                <a
                    href={talentManager?.calendarLink || AVIZIO_HOME_LINK}
                    rel="noreferrer noopener"
                    target="_blank"
                    {...gtmAttributes('talenblock-rdv')}
                >
                    <div className={styles.bookButton}>
                        {t('talentManagerContact.book2', { talentManager: talentManager?.firstName })}
                    </div>
                </a>
            </div>
        </div>
    );
};

export default TalentManagerContact;
