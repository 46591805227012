import React, { FC } from 'react';
import User from 'core/domain/model/user';
import ConnectedLayoutMobile from './ConnectedLayout';
import DisconnectedLayoutMobile from './DisconnectedLayout';

interface MobileLayoutProps {
    user: User | null;
}

const MobileLayout: FC<MobileLayoutProps> = ({ children, user }) => {
    const isConnected = !!user;

    const LayoutComponent = isConnected ? ConnectedLayoutMobile : DisconnectedLayoutMobile;

    return <LayoutComponent>{children}</LayoutComponent>;
};

export default MobileLayout;
