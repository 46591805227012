import User from 'core/domain/model/user';
import { NextStepCustomLabel } from 'core/domain/model/nextStep';
import TalentNotification from 'core/domain/model/talentNotification';
import LoadingState from '../types';
import { SetUserDataAction } from './validateCredentials';
import { SetSessionUserAction, LogInErrorAction } from './logInAction';
import { ClearSessionUserAction } from './logOutAction';
import { SignupErrorAction } from './signUserUp';
import SessionActionTypes, { LoginErrorType, SignupErrorType, UpdateUserPasswordErrorType, UserData } from './types';
import { UpdateUserPasswordErrorAction } from './updateUserPassword';
import { SetSessionLoadingAction } from './setLoadingAction';
import { SetNextStepLabelsAction } from './getNextStepLabelOptions';
import { SetReadAction } from './toggleNotification';
import { SetNotifications } from './readAllNotifications';
import { SelectMenuItem } from './selectMenuItem';

const getCurrentMenuItem = (): number => {
    if (window.location.pathname.includes('mes-process')) {
        return 1;
    }
    if (window.location.pathname.includes('mon-activite')) {
        return 2;
    }

    return 1;
};

export interface SessionState {
    user: User | null;
    userData: UserData | null;
    loginErrorType: LoginErrorType | null;
    signupErrorType: SignupErrorType | null;
    updateUserPasswordErrorType: UpdateUserPasswordErrorType | null;
    loading: LoadingState;
    nextStepCustomLabels: NextStepCustomLabel[];
    menuItem: number;
}

const init = {
    user: null,
    userData: null,
    loginErrorType: null,
    signupErrorType: null,
    updateUserPasswordErrorType: null,
    loading: LoadingState.NOT_LOADED,
    nextStepCustomLabels: [],
    menuItem: getCurrentMenuItem(),
};

type Action =
    | SetSessionUserAction
    | LogInErrorAction
    | ClearSessionUserAction
    | SignupErrorAction
    | SetUserDataAction
    | SetSessionLoadingAction
    | UpdateUserPasswordErrorAction
    | SetNextStepLabelsAction
    | SetReadAction
    | SetNotifications
    | SelectMenuItem;

const sessionReducer = (state: SessionState = init, action: Action): SessionState => {
    let user;
    switch (action.type) {
        case SessionActionTypes.SET_SESSION_USER:
            return { ...state, user: action.payload };
        case SessionActionTypes.CLEAR_SESSION_USER:
            return { ...state, user: null };
        case SessionActionTypes.LOG_IN_ERROR:
            return { ...state, loginErrorType: action.payload };
        case SessionActionTypes.SIGN_UP_ERROR:
            return { ...state, signupErrorType: action.payload };
        case SessionActionTypes.UPDATE_USER_PASSWORD_ERROR:
            return { ...state, updateUserPasswordErrorType: action.payload };
        case SessionActionTypes.SET_USER_DATA:
            return { ...state, userData: action.payload };
        case SessionActionTypes.SET_SESSION_LOADING:
            return { ...state, loading: action.payload };
        case SessionActionTypes.SET_NEXT_STEP_LABELS:
            return { ...state, nextStepCustomLabels: action.payload };
        case SessionActionTypes.SET_READ_NOTIFICATION:
            user = JSON.parse(JSON.stringify(state.user));
            if (user) {
                user.talentNotifications = user?.talentNotifications?.map((notification: TalentNotification) =>
                    notification.id === action.payload.id ? action.payload : notification,
                );
            }

            return { ...state, user };
        case SessionActionTypes.SET_NOTIFICATIONS:
            user = JSON.parse(JSON.stringify(state.user));
            user.talentNotifications = action.payload;

            return { ...state, user };
        case SessionActionTypes.SELECT_MENU_ITEM:
            return { ...state, menuItem: action.payload };
        default:
            return state;
    }
};

export default sessionReducer;
