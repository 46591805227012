import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import avizioLogo from 'images/logo-avizio-main.svg';
import Spinner from '../Spinner';
import styles from './index.module.scss';

const SessionLoadingScreen: FC = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div>
                <img alt="Logo Avizio" src={avizioLogo} />
            </div>
            <div className={styles.loaderWrapper}>
                <div className={styles.spinner}>
                    <Spinner />
                </div>
                <p className={styles.message}>{t('sessionLoadingMessage')}</p>
            </div>
        </div>
    );
};

export default SessionLoadingScreen;
