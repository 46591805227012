import User from 'core/domain/model/user';
import { UserInformationsFetcher } from 'core/useCases/session/updateUserInformations';
import BACKEND_URL from 'utils/backendUrl';
import { HttpBody, PUT } from 'utils/http';

interface ResponseData {
    user: User;
}

const userInformationsFetcher: UserInformationsFetcher = async (
    firstName,
    lastName,
    email,
    phone,
    favoriteCommunicationChannels,
    resume,
) => {
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('favoriteCommunicationChannels', JSON.stringify(favoriteCommunicationChannels));
    if (resume) {
        formData.append('resume', resume);
    }
    const body: HttpBody = formData;

    const response = (await PUT<ResponseData>(`${BACKEND_URL}/users/me`, body)) as ResponseData;

    return response.user;
};

export default userInformationsFetcher;
