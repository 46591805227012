import { TalentManager } from 'core/domain/model/collaborator';
import BACKEND_URL from 'utils/backendUrl';
import { GET } from 'utils/http';

interface GetTalentManagerResponse {
    talentManager: TalentManager;
}

const talentManagerFetcher = async (): Promise<TalentManager> => {
    try {
        const response = (await GET<GetTalentManagerResponse>(
            `${BACKEND_URL}/users/talent-manager`,
        )) as GetTalentManagerResponse;

        return response.talentManager;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export default talentManagerFetcher;
