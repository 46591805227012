// eslint-disable-next-line no-useless-escape
const EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const validateFirstName = (firstName: string): boolean => {
    return !!firstName.trim();
};

export const validateLastName = (lastName: string): boolean => {
    return !!lastName.trim();
};

export const validateEmail = (email: string): boolean => {
    const value = email.trim();

    return EMAIL_REGEXP.test(value);
};

export const validatePhone = (): boolean => {
    return true;
};

const validateMyInformations = (firstName: string, lastName: string, email: string): boolean => {
    return validateFirstName(firstName) && validateLastName(lastName) && validateEmail(email) && validatePhone();
};

export default validateMyInformations;
