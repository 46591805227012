import React, { FC } from 'react';
import BaseModal from '../BaseModal';
import styles from './index.module.scss';

interface JobCardExpandModalProps {
    closeModal?(): void;
}

const JobCardExpandModal: FC<JobCardExpandModalProps> = ({ children, closeModal }) => (
    <BaseModal closeModal={closeModal}>
        <div className={styles.container} {...(closeModal && { onClick: (e) => e.stopPropagation() })}>
            <div className={styles.jobCardExpandWrapper}>{children}</div>
        </div>
    </BaseModal>
);

export default JobCardExpandModal;
