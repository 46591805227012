/* eslint-disable react/no-danger */
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import newTabIcon from 'images/icon-new-tab-gray.svg';
import trashIcon from 'images/icon-trash.svg';
import ConfirmationModal from '../ConfirmationModal';
import useModal from '../../../../hooks/modal';
import styles from './index.module.scss';

interface FileInputProps {
    name: string;
    label: string;
    description?: string;
    validate(value: boolean): boolean;
    errorMessage?: string;
    fileName: string | null;
    link: string | null;
}

const FileInput: FC<FileInputProps> = ({
    name,
    label,
    description,
    validate,
    fileName: initialFileName,
    link: initialLink,
}) => {
    const { t } = useTranslation();
    const { register, watch } = useFormContext();

    const [fileName, setFileName] = useState(initialFileName);
    const [link, setLink] = useState(initialLink);
    const { isOpen, openModal, closeModal } = useModal();

    const fileList: FileList = watch(name, []);

    const file = fileList[0];

    const inputRef = register({ validate });

    useEffect(() => {
        if (file) {
            setFileName(file.name);
            setLink(null);
        }
    }, [file, fileList]);

    const openFilePdf = () => {
        if (link) {
            window.open(link as string, '_blank');
        }
    };

    const deleteFile = () => {
        closeModal();
        setFileName(null);
        setLink(null);
    };

    return (
        <div className={styles.fileInput}>
            <p className={styles.label}>{t(label)}</p>
            {description && <p dangerouslySetInnerHTML={{ __html: t(description) }} className={styles.description} />}
            <div className={classNames(styles.input, styles.fake, { [styles.hide]: !fileName })}>
                <div className={classNames(styles.fileName, { [styles.disabled]: !link })}>
                    <button onClick={openFilePdf} type="button">
                        <p>{fileName}</p>
                        {link && <img alt="open file" src={newTabIcon} />}
                    </button>
                    <button onClick={openModal} type="button">
                        <img alt="delete file" src={trashIcon} />
                    </button>
                </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={classNames(styles.input, styles.fake, { [styles.hide]: !!fileName })}>
                <div className={styles.addButton}>{t('add')}</div>
                <input ref={inputRef} accept="application/pdf" multiple={false} name={name} type="file" />
            </label>
            {isOpen && (
                <ConfirmationModal
                    cancel={closeModal}
                    loading={false}
                    submitValues={deleteFile}
                    titleKey="signup.deleteCV.confirmation"
                />
            )}
        </div>
    );
};

export default FileInput;
