import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'images/logo-avizio-main.svg';
import { OUR_CLIENTS_LINK, NETWORK_LINK, WHO_ARE_WE_LINK } from 'utils/constants';
import styles from './index.module.scss';

const HeaderDisonnected: React.FC = () => {
    const { t } = useTranslation();

    return (
        <header className={styles.fullNav}>
            <div className={styles.logoBox}>
                <img alt="Logo avizio" src={logo} />
            </div>
            <div className={styles.navTitle}>
                <h1>{t('header.titleDisconnected')}</h1>
            </div>
            <div className={styles.nav}>
                <div className={styles.navRight}>
                    <a href={OUR_CLIENTS_LINK} rel="noreferrer noopener" target="_blank">
                        <span>{t('ourClients')}</span>
                    </a>
                </div>
                <div className={styles.navRight}>
                    <a href={NETWORK_LINK} rel="noreferrer noopener" target="_blank">
                        <span>{t('network')}</span>
                    </a>
                </div>
                <div className={styles.navRight}>
                    <a href={WHO_ARE_WE_LINK} rel="noreferrer noopener" target="_blank">
                        <span>{t('whoAreWe')}</span>
                    </a>
                </div>
            </div>
        </header>
    );
};

export default HeaderDisonnected;
