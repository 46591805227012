enum EventActionTypes {
    SET_EVENTS = 'SET_EVENTS',
    SET_EVENTS_LOADING = 'SET_EVENTS_LOADING',
    SET_ERROR = 'SET_ERROR',
    CREATE_EVENT = 'CREATE_EVENT',
}

export enum EventsErrorType {
    RETRIVAL_ERROR = 'retrivalError',
}

export default EventActionTypes;
