import React, { FC } from 'react';
import { StagedJobs } from 'core/useCases/jobs/selectors';
import { Column as ColumnType } from 'hooks/columns';
import Column from '../shared/Column';

interface KanbanDesktopProps {
    jobs: StagedJobs;
    columns: ColumnType[];
}

const KanbanDesktop: FC<KanbanDesktopProps> = ({ jobs, columns }) => (
    <>
        {columns.map(({ title, backgroundType, placeholder, alwaysVisible, stage }) => (
            <Column
                key={stage}
                alwaysVisible={alwaysVisible}
                backgroundType={backgroundType}
                jobs={jobs[stage]}
                placeholder={placeholder}
                title={title}
            />
        ))}
    </>
);

export default KanbanDesktop;
