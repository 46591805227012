import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tabs as MaterialTabs } from '@material-ui/core';
import Job from 'core/domain/model/job';
import { Tab as TabType } from 'hooks/tabs';
import gtmAttributes from 'utils/gtmAttributes';
import styles from './index.module.scss';

interface TabProps {
    alt: string;
    active: boolean;
    gtmId: string;
    icon: string;
    label: string;
    to: string;
}

const Tab: FC<TabProps> = ({ alt, active, icon, label, to, gtmId }) => {
    const { t } = useTranslation();

    return (
        <Link
            className={classNames(styles.tabWrapper, { [styles.active]: active })}
            to={to}
            {...gtmAttributes(`expand-${gtmId}`)}
        >
            <img alt={t(alt)} src={icon} />
            <p>{t(label)}</p>
        </Link>
    );
};

interface TabsProps {
    activeTabIndex: number | undefined;
    job: Job;
    tabs: TabType[];
}

const Tabs: FC<TabsProps> = ({ activeTabIndex = 0, job, tabs }) => {
    return (
        <MaterialTabs
            className={styles.tabsWrapper}
            classes={{ root: 'tabsWrapper' }}
            scrollButtons="on"
            style={{ minHeight: '30px' }}
            value={activeTabIndex}
            variant="scrollable"
        >
            {tabs.map((tab, index) => (
                <Tab
                    key={tab.to}
                    active={activeTabIndex === index}
                    alt={tab.alt}
                    gtmId={tab.gtmId}
                    icon={tab.icon}
                    label={tab.label}
                    to={`/mes-process/jobs/${job.crmId}/${tab.to}`}
                />
            ))}
        </MaterialTabs>
    );
};

export default Tabs;
