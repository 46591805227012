import React, { FC, useMemo, useEffect } from 'react';
import { ArrayField, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Job from 'core/domain/model/job';
import { Label, NextStepData } from 'core/useCases/nextSteps/setNextStepAction';
import NextStep from 'core/domain/model/nextStep';
import NextStepElement from '../NextStepElement';
import styles from './index.module.scss';
import EmptyList from './EmptyList';

const useSplitNextSteps = (nextSteps: Partial<ArrayField<NextStep, 'formId'>>[]) => {
    return useMemo(() => {
        const notDone: number[] = [];
        const done: number[] = [];
        nextSteps.forEach((nextStep, index) => {
            // eslint-disable-next-line no-unused-expressions
            nextStep.done ? done.push(index) : notDone.push(index);
        });

        return [done, notDone];
    }, [nextSteps]);
};

interface NextStepFormValue {
    id: string;
    dueDate: string;
    done: boolean;
    label: Label;
}

export interface FormValues {
    nextSteps: NextStepFormValue[];
}

interface NextStepFormProps {
    job: Job;
    initialNextSteps: NextStep[];
    create(): void;
    update(nextStepId: string, nextStepData: NextStepData): void;
    remove(id: string): void;
}

const NextStepForm: FC<NextStepFormProps> = ({ initialNextSteps, job, update, remove }) => {
    const { t } = useTranslation();

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: { nextSteps: initialNextSteps },
    });

    const { fields: nextSteps } = useFieldArray<NextStep, 'formId'>({
        control: methods.control,
        name: 'nextSteps',
        keyName: 'formId',
    });

    useEffect(() => {
        methods.reset({ ...{ nextSteps: initialNextSteps } });
    }, [initialNextSteps]);

    const [done, notDone] = useSplitNextSteps(nextSteps);

    return (
        <FormProvider {...methods}>
            <div className={styles.nextStepsForm}>
                {nextSteps.length <= 0 ? (
                    <EmptyList job={job} />
                ) : (
                    <form className={styles.formContainer}>
                        <div>
                            {notDone.map((index) => (
                                <NextStepElement
                                    key={nextSteps[index].formId}
                                    index={index}
                                    name={`nextSteps[${index}]`}
                                    nextStep={nextSteps[index]}
                                    remove={remove}
                                    update={update}
                                />
                            ))}
                        </div>
                        {done.length > 0 && (
                            <div>
                                <div className={styles.myNextStepHeader}>
                                    <h2>{t('jobCard.expand.tabs.myNextStep.done')}</h2>
                                </div>
                                <hr />
                                {done.map((index) => (
                                    <NextStepElement
                                        key={nextSteps[index].formId}
                                        index={index}
                                        name={`nextSteps[${index}]`}
                                        nextStep={nextSteps[index]}
                                        remove={remove}
                                        update={update}
                                    />
                                ))}
                            </div>
                        )}
                    </form>
                )}
            </div>
        </FormProvider>
    );
};

export default NextStepForm;
