import User from 'core/domain/model/user';
import { ThunkAction } from 'core/store';
import { setSessionUserAction } from './logInAction';

export type UserInformationsFetcher = (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    favoriteCommunicationChannels: string[],
    resume: File | null,
) => Promise<User>;

type OnFinish = () => void;

const updateUserInformations = (userInformationsFetcher: UserInformationsFetcher, onFinish: OnFinish) => (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    favoriteCommunicationChannels: string[],
    resume: File | null,
): ThunkAction => async (dispatch) => {
    try {
        const user = await userInformationsFetcher(
            firstName,
            lastName,
            email,
            phone,
            favoriteCommunicationChannels,
            resume,
        );
        dispatch(setSessionUserAction(user));
        onFinish();
    } catch (error) {
        console.error(error);
    }
};

export default updateUserInformations;
