import NotificationConfig from 'core/domain/model/notificationConfig';
import User from 'core/domain/model/user';
import { UserNotifConfigFetcher } from 'core/useCases/session/updateUserNotifConfig';
import BACKEND_URL from 'utils/backendUrl';
import { HttpBody, POST } from 'utils/http';

interface ResponseData {
    user: User;
}

const userNotifConfigFetcher: UserNotifConfigFetcher = async (userId: string, configs: NotificationConfig[]) => {
    const body: HttpBody = { configs };

    const response = (await POST<ResponseData>(
        `${BACKEND_URL}/users/${userId}/update-notif-config`,
        body,
    )) as ResponseData;

    return response.user;
};

export default userNotifConfigFetcher;
