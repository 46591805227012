import SessionActionTypes from './types';

export interface SelectMenuItem {
    type: SessionActionTypes.SELECT_MENU_ITEM;
    payload: number;
}

const selectMenuItem = (item: number): SelectMenuItem => ({
    type: SessionActionTypes.SELECT_MENU_ITEM,
    payload: item,
});

export default selectMenuItem;
