import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { activeJobSelector, jobsAreLoadedSelector } from 'core/useCases/jobs/selectors';
import Job from 'core/domain/model/job';

interface UseExpandedJob {
    expandedJob: Job | null;
    loaded: boolean;
}

const useExpandedJob = (): UseExpandedJob => {
    const params = useParams<{ id: string }>();

    const expandedJob = useSelector(activeJobSelector(params.id));
    const loaded = useSelector(jobsAreLoadedSelector);

    return { expandedJob, loaded };
};

export default useExpandedJob;
