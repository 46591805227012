import { History } from 'history';
import { AnyAction, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware, { ThunkAction as BaseThunkAction } from 'redux-thunk';
import rootReducer, { appReducer } from 'core/useCases/reducer';
import BACKEND_URL from 'utils/backendUrl';
import history from 'utils/history';

export type RootState = ReturnType<typeof appReducer>;

export interface ExtraArgument {
    backendUrl: string;
    history: History;
}

export type ThunkAction = BaseThunkAction<void, RootState, ExtraArgument, AnyAction>;

const composedEnhancer = composeWithDevTools(
    applyMiddleware(
        thunkMiddleware.withExtraArgument({
            backendUrl: BACKEND_URL,
            history,
        }),
    ),
);

const store = createStore(rootReducer, composedEnhancer);

export default store;
