import { SendInterestFetcher } from 'core/useCases/jobs/sendUserInterest';
import { UserInterestErrorType } from 'core/useCases/jobs/types';
import BACKEND_URL from 'utils/backendUrl';
import { POST } from 'utils/http';

const sendInterestFetcher: SendInterestFetcher = async (jobCrmId: string, interest, reasonWhy) => {
    try {
        await POST(`${BACKEND_URL}/jobs/${jobCrmId}/interest`, { interest, reasonWhy });
    } catch (error) {
        if (error.status === 400) {
            throw new Error(UserInterestErrorType.INVALID_JOB_STAGE);
        }
    }
};

export default sendInterestFetcher;
