/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { ErrorMessage } from '../..';
import Option from './Option';
import SelectInput from './SelectInput';
import styles from './index.module.scss';

export type SelectOption = {
    value: string;
    label: string;
};

interface MultiSelectInputProps {
    defaultValues: SelectOption[];
    name: string;
    label: string;
    description: string;
    errorMessage: string;
    validate?(value: SelectOption[]): boolean;
    placeholder: string;
    options: SelectOption[];
}

const MultiSelectInput: FC<MultiSelectInputProps> = ({
    defaultValues,
    description,
    errorMessage,
    label,
    name,
    options,
    placeholder,
    validate,
}) => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    const {
        field: { onChange, ref },
    } = useController({
        name,
        control,
        rules: { validate },
    });

    return (
        <div className={styles.selectInput}>
            <p className={styles.label}>{t(label)}</p>
            {description && <p dangerouslySetInnerHTML={{ __html: t(description) }} className={styles.description} />}
            <Autocomplete
                ref={ref}
                defaultValue={defaultValues}
                getOptionLabel={(option) => t((option as SelectOption).label)}
                onChange={(event, value) => onChange(value)}
                options={options}
                renderInput={(params) => <SelectInput inputParams={params} placeholder={placeholder} />}
                renderOption={(option, { selected }) => (
                    <Option label={(option as SelectOption).label} selected={selected} />
                )}
                multiple
            />
            <ErrorMessage errorMessage={errorMessage} name={name} />
        </div>
    );
};

export default MultiSelectInput;
