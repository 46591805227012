import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Job from 'core/domain/model/job';
import talentManagerSelector from '../../../../../../../core/useCases/talentManager/selectors';
import EmojiStar from '../../../../../../../images/emoji-star.svg';
import styles from './index.module.scss';

interface JobInfosProps {
    job: Job;
}

const JobInfos: FC<JobInfosProps> = ({ job }) => {
    const { t } = useTranslation();
    const talentManager = useSelector(talentManagerSelector);

    return (
        <div className={styles.infosWrapper}>
            <h4>{t('jobCard.expand.tabs.aboutJob.moreInfos.title')}</h4>
            <h5>{t('jobCard.expand.tabs.aboutJob.moreInfos.subtitle', { companyName: job.company.name })}</h5>
            <p className={styles.talentManagerName}>
                {job.contact.firstName} {job.contact.lastName}
            </p>
            <p>{job.contact.designation}</p>
            {job.additionalInfo && (
                <>
                    <hr />
                    <div className={styles.additionalInfo}>
                        <h5>
                            {t('jobCard.expand.tabs.aboutJob.moreInfos.additionalInfo', {
                                talentManager: talentManager?.firstName,
                            })}
                        </h5>
                        <div className={styles.svgWrapper}>
                            <img alt="star" src={EmojiStar} />
                        </div>
                    </div>
                    <p className={styles.additionalInfoContent}>{job.additionalInfo}</p>
                </>
            )}
        </div>
    );
};

export default JobInfos;
