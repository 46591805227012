import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from 'images/close-cross.svg';
import useClickOutside from 'hooks/useClickOutside';
import TalentNotification from 'core/domain/model/talentNotification';
import NotificationCard from 'components/NotificationCard';
import styles from './index.module.scss';

interface NotificationsProps {
    onClose: () => void;
    notifications: TalentNotification[];
    onToggleNotification: (id: string) => void;
    readAll: () => void;
    hasUnreadNotifications: boolean;
}

const NotificationsDesktop: FC<NotificationsProps> = ({
    onClose,
    notifications,
    onToggleNotification,
    readAll,
    hasUnreadNotifications,
}) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement | null>(null);
    useClickOutside({ ref, submenuIsOpen: false, callback: onClose });

    return (
        <div ref={ref} className={styles.menuWrapper}>
            <div className={styles.menuTitle}>
                <h2>{t('header.notifications.title')}</h2>
                <button onClick={onClose} type="button">
                    <img alt="X" src={CloseIcon} />
                </button>
            </div>
            <hr />
            <div className={styles.rightAlign}>
                <button onClick={readAll} type="button">
                    <span className={`${styles.readAll} ${hasUnreadNotifications ? styles.activated : ''}`}>
                        {t('header.notifications.readAll')}
                    </span>
                </button>
            </div>
            <div className={styles.scrollable}>
                {notifications &&
                    notifications.map((n) => (
                        <NotificationCard notification={n} onToggleNotification={onToggleNotification} />
                    ))}
            </div>
            <hr />
        </div>
    );
};

export default NotificationsDesktop;
