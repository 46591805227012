export const validatePasswordConfirmation = (
    password: string | undefined,
    confirmation: string | undefined,
): boolean => {
    return !!confirmation && password === confirmation;
};

export const validatePassordLength = (password: string | undefined): boolean => {
    return !!password && password.length >= 6;
};

export const validateCGUApproval = (hasApproved: boolean | undefined): boolean => {
    return !!hasApproved;
};

const validateSignup = (
    password: string | undefined,
    confirmation: string | undefined,
    hasApproved: boolean | undefined,
): boolean => {
    return (
        validatePasswordConfirmation(password, confirmation) &&
        validatePassordLength(password) &&
        validateCGUApproval(hasApproved)
    );
};

export default validateSignup;
