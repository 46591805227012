import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Company from 'core/domain/model/company';
import webIcon from 'images/icon-external-link.svg';
import linkedinIcon from 'images/icon-linkedin.svg';
import styles from './index.module.scss';

interface ExternalLinkProps {
    url: string;
    altKey: string;
    iconPath: string;
}

const ExternalLink: FC<ExternalLinkProps> = ({ url, altKey, iconPath }) => {
    const { t } = useTranslation();

    const href = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;

    return (
        <a className={styles.urlContainer} href={href} rel="noreferrer" target="_blank">
            <img alt={t(altKey)} src={iconPath} />
        </a>
    );
};

interface CompanyNameAndLinksProps {
    company: Company;
}

const CompanyNameAndLinks: FC<CompanyNameAndLinksProps> = ({ company }) => (
    <div className={styles.companyContainer}>
        <p>{company.name}</p>
        {company.websiteUrl && (
            <ExternalLink altKey="jobCard.expand.webLinkAlt" iconPath={webIcon} url={company.websiteUrl} />
        )}
        {company.linkedinUrl && (
            <ExternalLink altKey="jobCard.expand.linkedinAlt" iconPath={linkedinIcon} url={company.linkedinUrl} />
        )}
    </div>
);

export default CompanyNameAndLinks;
