/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../ErrorMessage';
import styles from './index.module.scss';

interface CheckboxInput {
    name: string;
    label: string;
    labelData?: Record<string, unknown>;
    validate(value: boolean): boolean;
    errorMessage?: string;
}

const CheckboxInput: FC<CheckboxInput> = ({ name, label, labelData, validate, errorMessage }) => {
    const { register } = useFormContext();
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.horizontalCheckbox}>
                <input
                    ref={register({ validate })}
                    className={styles.checkboxInput}
                    id={name}
                    name={name}
                    type="checkbox"
                />
                <div className={styles.labelWrapper}>
                    <label
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: t(label, { ...labelData }) }}
                        className={styles.label}
                        htmlFor={name}
                    />
                </div>
            </div>
            <ErrorMessage errorMessage={errorMessage} name={name} />
        </>
    );
};

export default CheckboxInput;
