import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ErrorText.module.scss';

interface ErrorTextProps {
    text: string;
}

const ErrorText: FC<ErrorTextProps> = ({ text }) => {
    const { t } = useTranslation();

    return <span className={styles.errorMessage}>{t(text)}</span>;
};

export default ErrorText;
