import React, { FC } from 'react';
import HeaderMobile from '../../HeaderMobile';

interface HeaderConnectedProps {
    title: string;
}

const HeaderConnected: FC<HeaderConnectedProps> = ({ title }) => {
    return <HeaderMobile headerTitle={title} />;
};

export default HeaderConnected;
