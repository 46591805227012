import { NextStepCustomLabel } from 'core/domain/model/nextStep';
import { ThunkAction } from 'core/store';
import SessionActionTypes from './types';

export interface SetNextStepLabelsAction {
    type: SessionActionTypes.SET_NEXT_STEP_LABELS;
    payload: NextStepCustomLabel[];
}

export const setNextStepLabels = (nextStepLabels: NextStepCustomLabel[]): SetNextStepLabelsAction => ({
    type: SessionActionTypes.SET_NEXT_STEP_LABELS,
    payload: nextStepLabels,
});

export type GetNextStepLabelOptionsFetcher = () => Promise<NextStepCustomLabel[]>;

const getNextStepLabelOptions = (nextStepLabelsFetcher: GetNextStepLabelOptionsFetcher) => (): ThunkAction => async (
    dispatch,
) => {
    const nextSteps = await nextStepLabelsFetcher();
    dispatch(setNextStepLabels(nextSteps));
};

export default getNextStepLabelOptions;
