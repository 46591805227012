import User from 'core/domain/model/user';
import { ThunkAction } from '../../store';
import { SessionFetcher } from './syncSessionAction';
import SessionActionTypes, { LoginErrorType } from './types';

export interface SetSessionUserAction {
    type: SessionActionTypes.SET_SESSION_USER;
    payload: User;
}

export const setSessionUserAction = (user: User): SetSessionUserAction => ({
    type: SessionActionTypes.SET_SESSION_USER,
    payload: user,
});

export interface LogInErrorAction {
    type: SessionActionTypes.LOG_IN_ERROR;
    payload: LoginErrorType | null;
}

export const logInErrorAction = (message: LoginErrorType | null): LogInErrorAction => ({
    type: SessionActionTypes.LOG_IN_ERROR,
    payload: message,
});

export type LoginFetcher = (email: string, password: string) => Promise<User>;

const logUserIn = (loginFetcher: LoginFetcher, sessionFetcher: SessionFetcher) => (
    email: string,
    password: string,
): ThunkAction => async (dispatch) => {
    try {
        await loginFetcher(email, password);
        const user = await sessionFetcher();

        dispatch(setSessionUserAction(user));
        dispatch(logInErrorAction(null));
    } catch (error) {
        dispatch(logInErrorAction(error.message));
    }
};

export default logUserIn;
