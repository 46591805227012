import { ThunkAction } from 'core/store';
import jobsFetcher from 'fetchers/jobsFetcher';
import LoadingState from '../types';
import listUserJobsAction from './setJobsAction';
import JobActionTypes, { UserInterestErrorType } from './types';

export interface SetUserInterestAction {
    type: JobActionTypes.SET_USER_INTEREST;
    payload: string;
}

const setUserInterest = (jobCrmId: string): SetUserInterestAction => ({
    type: JobActionTypes.SET_USER_INTEREST,
    payload: jobCrmId,
});

export interface SetUserInterestErrorAction {
    type: JobActionTypes.SET_USER_INTEREST_ERROR;
    payload: UserInterestErrorType | null;
}

const setUserInterestError = (error: UserInterestErrorType | null): SetUserInterestErrorAction => ({
    type: JobActionTypes.SET_USER_INTEREST_ERROR,
    payload: error,
});

export interface SetUserInterestLoadingAction {
    type: JobActionTypes.SET_USER_INTEREST_LOADING;
    payload: LoadingState;
}

export const setUserInterestLoading = (loading: LoadingState): SetUserInterestLoadingAction => ({
    type: JobActionTypes.SET_USER_INTEREST_LOADING,
    payload: loading,
});

export type SendInterestFetcher = (jobCrmId: string, interest: boolean, reasonWhy: string) => Promise<void>;
type OnSuccessCallback = () => void;

const sendUserInterest = (sendInterestFetcher: SendInterestFetcher, onSuccess?: OnSuccessCallback) => (
    jobCrmId: string,
    interest: boolean,
    reasonWhy: string,
): ThunkAction => async (dispatch) => {
    dispatch(setUserInterestLoading(LoadingState.LOADING));
    try {
        await sendInterestFetcher(jobCrmId, interest, reasonWhy);
        setTimeout(() => {
            dispatch(listUserJobsAction(jobsFetcher, false)());
            dispatch(setUserInterestLoading(LoadingState.LOADED));
            if (onSuccess) {
                onSuccess();
            }
        }, 6000);

        dispatch(setUserInterestError(null));
        dispatch(setUserInterest(jobCrmId));
    } catch (error) {
        dispatch(setUserInterestError(error.message));
    }
};

export default sendUserInterest;
