import LoadingState from '../types';
import EventActionTypes from './types';

export interface SetLoadingAction {
    type: EventActionTypes.SET_EVENTS_LOADING;
    payload: LoadingState;
}

const setLoading = (loading: LoadingState): SetLoadingAction => ({
    type: EventActionTypes.SET_EVENTS_LOADING,
    payload: loading,
});

export default setLoading;
