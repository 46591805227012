import React, { FC } from 'react';
import Portal from './Portal';
import styles from './index.module.scss';

interface BaseModalProps {
    closeModal?(): void;
}

const BaseModal: FC<BaseModalProps> = ({ children, closeModal }) => (
    <Portal>
        <div className={styles.overlay} {...(closeModal && { onClick: closeModal })}>
            {children}
        </div>
    </Portal>
);

export default BaseModal;
