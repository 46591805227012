import React from 'react';
import DataProvider from 'contexts/data';
import { Provider as LanguageProvider } from 'contexts/language';
import Router from 'pages/Router';
import AuthInitializer from 'contexts/auth';

const App: React.FC = () => {
    return (
        <DataProvider>
            <AuthInitializer>
                <LanguageProvider>
                    <Router />
                </LanguageProvider>
            </AuthInitializer>
        </DataProvider>
    );
};

export default App;
