import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderMobile from '../../HeaderMobile';
import { NETWORK_LINK, WHO_ARE_WE_LINK, OUR_CLIENTS_LINK } from '../../../../../utils/constants';
import styles from './index.module.scss';

const HeaderDisconnected: FC = () => {
    const { t } = useTranslation();

    return (
        <HeaderMobile
            headerTitle={t('header.mobile.titleDisconnected')}
            menuTitle={t('header.mobile.menuDisconnected')}
        >
            <div className={styles.menuContent}>
                <a href={OUR_CLIENTS_LINK} rel="noreferrer noopener" target="_blank">
                    <p>{t('ourClients')}</p>
                </a>
                <a href={NETWORK_LINK} rel="noreferrer noopener" target="_blank">
                    <p>{t('network')}</p>
                </a>
                <a href={WHO_ARE_WE_LINK} rel="noreferrer noopener" target="_blank">
                    <p>{t('whoAreWe')}</p>
                </a>
            </div>
        </HeaderMobile>
    );
};

export default HeaderDisconnected;
