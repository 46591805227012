import EventActionTypes, { EventsErrorType } from './types';

export interface SetErrorAction {
    type: EventActionTypes.SET_ERROR;
    payload: EventsErrorType | null;
}

const setError = (errorMessage: EventsErrorType | null): SetErrorAction => ({
    type: EventActionTypes.SET_ERROR,
    payload: errorMessage,
});

export default setError;
