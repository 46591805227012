import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TalentNotification from 'core/domain/model/talentNotification';
import doubleArrows from 'images/double-arrows.svg';
import chip from 'images/chip.svg';
import chipFull from 'images/chip-full.svg';
import styles from './index.module.scss';

interface NotificationCardProps {
    notification: TalentNotification;
    onToggleNotification: (id: string) => void;
}

const NotificationCard: FC<NotificationCardProps> = ({ notification, onToggleNotification }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const onClick = () => {
        if (!notification.read) {
            onToggleNotification(notification.id);
        }
        if (!history) {
            return;
        }
        history.push(`/mes-process/jobs/${notification.jobCrmId}/process`);
    };

    return (
        <div className={styles.card} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
            <div className={styles.chip}>
                <button
                    className={styles.readButton}
                    data-gtm-id="header-menu"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onToggleNotification(notification.id);
                    }}
                    type="button"
                >
                    <img alt="notification" src={notification.read ? chip : chipFull} />
                </button>
            </div>
            <div className={`${styles.notification} ${notification.read ? styles.read : ''}`}>
                <div>
                    <span className={styles.bold}>{notification.title} </span>
                    <span>{notification.description}</span>
                </div>
                <div className={styles.footer}>
                    <img alt="notification" src={doubleArrows} />
                    <span className={styles.duration}>{t('jobCard.updatedAt', { updatedAt: notification.date })}</span>
                </div>
            </div>
        </div>
    );
};

export default NotificationCard;
