import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Job from 'core/domain/model/job';
import update from 'images/update.svg';
import ExclamationPoint from 'images/exclamation-point-gold.svg';
import { useSyncModal } from 'hooks/modal';
import gtmAttributes from 'utils/gtmAttributes';
import CompanyLogo from '../CompanyLogo';
import { nextStepLabelSelector } from '../../core/useCases/jobs/selectors';
import styles from './index.module.scss';
import JobCardExpand from './JobCardExpand';

export enum JobcardVariant {
    BASE = 'base',
    GOLD = 'gold',
}

interface IJobcardProps {
    job: Job;
    variant: JobcardVariant;
    expanded: boolean;
}

const JobCard: FC<IJobcardProps> = ({ job, variant, expanded }) => {
    const { t } = useTranslation();
    const badgeLabel = useSelector(nextStepLabelSelector(job.crmId));

    const stageName = t(`columns.${job.stage}.title`);
    const { isOpen: submenuIsOpen, closeModal, openModal } = useSyncModal({
        shouldBeOpen: expanded,
        onOpenRedirectTo: `/mes-process/jobs/${job.crmId}/process`,
        onCloseRedirectTo: `/mes-process/?stage=${stageName}`,
    });

    const gold = variant === JobcardVariant.GOLD;

    return (
        <>
            <button
                className={classNames(styles.jobCardWrapper, {
                    [styles.gold]: gold,
                })}
                onClick={openModal}
                type="button"
                {...gtmAttributes('collaspe')}
            >
                <div className={styles.jobCardContent}>
                    <div className={styles.companyAvatarWrapper}>
                        <div className={styles.companyLogo}>
                            <CompanyLogo job={job} />
                        </div>
                    </div>
                    <div className={styles.jobInfos}>
                        <div>
                            <h4>{job.name}</h4>
                            <p>{job.company.name}</p>
                        </div>
                        {badgeLabel && <p className={styles.jobCardBadge}>{badgeLabel}</p>}
                    </div>
                </div>
                <div className={styles.jobUpdate}>
                    <img alt="update icon" src={update} />
                    <p>{t('jobCard.updatedAt', { updatedAt: job.updatedAt })}</p>
                </div>
                {gold && (
                    <div className={styles.jobAlert}>
                        <img alt="/!\" src={ExclamationPoint} />
                    </div>
                )}
            </button>
            {submenuIsOpen && <JobCardExpand job={job} onClose={closeModal} />}
        </>
    );
};

export default JobCard;
