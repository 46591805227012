import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import More from 'images/more.svg';
import styles from './index.module.scss';

interface NoEventsCardProps {
    onClick: () => void;
}

const NotificationCard: FC<NoEventsCardProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.card}>
            <p>{t('noEventsCard.noEvents')}</p>
            <br />
            <button className={styles.mainButton} onClick={() => onClick()} type="button">
                <img alt="create" src={More} />
                {t('noEventsCard.createEvent')}
            </button>
        </div>
    );
};

export default NotificationCard;
