import TalentEvent from 'core/domain/model/event';
import { CreateEventsFetcher } from 'core/useCases/events/createEventAction';
import { JobsErrorType } from 'core/useCases/jobs/types';
import BACKEND_URL from 'utils/backendUrl';
import { POST } from 'utils/http';
import { CreateEventArgs } from './createCustomEventFetcher';

interface CreateEventResponse {
    event: TalentEvent;
}

const createCustomEventsFetcher: CreateEventsFetcher = async (payload: CreateEventArgs) => {
    try {
        const response = (await POST<CreateEventResponse>(
            `${BACKEND_URL}/calendar-events/update`,
            payload,
        )) as CreateEventResponse;

        return response.event;
    } catch (error) {
        throw new Error(JobsErrorType.RETRIVAL_ERROR);
    }
};

export default createCustomEventsFetcher;
