import { useMemo } from 'react';
import { BackgroundType } from 'components/Kanban/shared/Column';
import { JobStage } from 'core/domain/model/job';
import { StagedJobs } from 'core/useCases/jobs/selectors';

const columnsConfig = [
    {
        backgroundType: BackgroundType.LIGHT,
        title: 'columns.suggestion.title',
        placeholder: 'columns.suggestion.placeholder',
        alwaysVisible: true,
        stage: JobStage.SUGGESTION,
    },
    {
        backgroundType: BackgroundType.BASE,
        title: 'columns.ongoing.title',
        placeholder: 'columns.ongoing.placeholder',
        alwaysVisible: true,
        stage: JobStage.ONGOING,
    },
    {
        backgroundType: BackgroundType.BASE,
        title: 'columns.offer.title',
        placeholder: 'columns.offer.placeholder',
        alwaysVisible: true,
        stage: JobStage.OFFER,
    },
    {
        backgroundType: BackgroundType.BASE,
        title: 'columns.placed.title',
        placeholder: 'columns.placed.placeholder',
        alwaysVisible: false,
        stage: JobStage.PLACED,
    },
    {
        backgroundType: BackgroundType.DARK,
        title: 'columns.notConclude.title',
        placeholder: 'columns.notConclude.placeholder',
        alwaysVisible: true,
        stage: JobStage.NOT_CONCLUDE,
    },
];

export interface Column {
    backgroundType: BackgroundType;
    title: string;
    placeholder: string;
    alwaysVisible: boolean;
    stage: JobStage;
}

const useColumns = (jobs: StagedJobs): Column[] => {
    const columns = useMemo(() => {
        return columnsConfig.filter(({ alwaysVisible, stage }) => alwaysVisible || jobs[stage].length > 0);
    }, [jobs]);

    return columns;
};

export default useColumns;
