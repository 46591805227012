import { Tooltip } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NextStep, { NextStepDefaultLabel } from 'core/domain/model/nextStep';
import iconTrash from 'images/icon-trash.svg';
import { Label, NextStepData } from 'core/useCases/nextSteps/setNextStepAction';
import DateTimePickerInput from 'components/form/lib/DateTimePickerInput';
import SearchableSelectInput, { SelectOption } from 'components/form/lib/SelectInput/SearchableSelectInput';
import RoundedCheckbox from 'components/form/lib/CheckboxInput/RoundedCheckbox';
import { userNextStepCustomLabelsSelector } from 'core/useCases/session/selectors';
import gtmAttributes from 'utils/gtmAttributes';
import { FormValues } from '../NextStepForm';
import styles from './index.module.scss';

const useNextStepLabelOptions = (): SelectOption[] => {
    const defaultLabelsOptions = Object.values(NextStepDefaultLabel).map((label) => ({
        value: { default: true, value: label, existing: true },
        label: `jobCard.expand.tabs.myNextStep.content.form.label.options.${label}`,
    }));

    const nextStepCustomLabels = useSelector(userNextStepCustomLabelsSelector);

    const customLabelOptions = nextStepCustomLabels.map((label) => ({
        value: { default: false, value: label.id, existing: true },
        label: label.value,
    }));

    const options = [...defaultLabelsOptions, ...customLabelOptions];

    return options;
};

const getDefaultLabel = (nextStep: Partial<NextStep>): Label | null => {
    if (nextStep.defaultLabel) {
        return { default: true, value: nextStep.defaultLabel, existing: true };
    }

    if (nextStep.customLabel) {
        return { default: false, value: nextStep.customLabel.id, existing: true };
    }

    return null;
};

interface WrappingElementProps {
    displayTooltip: boolean;
    children: ReactElement;
}

const WrappingElement: FC<WrappingElementProps> = ({ displayTooltip, children }) => {
    const { t } = useTranslation();

    return displayTooltip ? (
        <Tooltip placement="top" title={t('jobCard.expand.tabs.myNextStep.content.form.labelHint') as string} arrow>
            {children}
        </Tooltip>
    ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
    );
};

interface NextStepProps {
    name: string;
    nextStep: Partial<NextStep>;
    index: number;
    remove(id: string): void;
    update(id: string, nextStepData: NextStepData): void;
}

const NextStepElement: FC<NextStepProps> = ({ name, nextStep, remove, index, update }) => {
    const { getValues } = useFormContext<FormValues>();

    const options = useNextStepLabelOptions();

    const defaultLabel = getDefaultLabel(nextStep);
    const defaultDate = nextStep.dueDate ? new Date(nextStep.dueDate) : new Date();

    const autoSaveData = () => {
        const values = getValues();

        const { label, dueDate, done } = values.nextSteps[index];

        update(nextStep.id as string, { label, dueDate, done });
    };

    const { done } = nextStep;
    const notEditable = nextStep.defaultLabel === NextStepDefaultLabel.POSITION_ON_JOB;

    return (
        <WrappingElement displayTooltip={notEditable && !done}>
            <div
                className={classNames(styles.nextStepWrapper, {
                    [styles.done]: done,
                    [styles.disabled]: done || notEditable,
                })}
            >
                <div>
                    <RoundedCheckbox
                        defaultChecked={!!nextStep.done}
                        gtmAttributes={gtmAttributes('nextstep-done')}
                        name={`${name}.done`}
                        onValueChange={autoSaveData}
                    />
                    <SearchableSelectInput
                        defaultValue={defaultLabel}
                        disabled={done || notEditable}
                        name={`${name}.label`}
                        onValueChange={autoSaveData}
                        options={options}
                        placeholder="jobCard.expand.tabs.myNextStep.content.form.label.placeholder"
                    />
                </div>
                <div>
                    <DateTimePickerInput
                        defaultValue={defaultDate}
                        disabled={done || notEditable}
                        name={`${name}.dueDate`}
                        onValueChange={autoSaveData}
                        placeholder="jobCard.expand.tabs.myNextStep.content.form.dueDate.placeholder"
                        type="datetime"
                    />
                    <div className={styles.delete}>
                        {!notEditable && (
                            <button
                                onClick={() => remove(nextStep.id as string)}
                                type="button"
                                {...gtmAttributes('nextstep-delete')}
                            >
                                <img alt="delete" src={iconTrash} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </WrappingElement>
    );
};

export default NextStepElement;
