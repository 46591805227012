import React, { FC } from 'react';
import BaseModal from '../BaseModal';
import styles from './index.module.scss';

interface SimpleModalProps {
    closeModal?(): void;
}

const SimpleModal: FC<SimpleModalProps> = ({ children, closeModal }) => (
    <BaseModal closeModal={closeModal}>
        <div className={styles.container} {...(closeModal && { onClick: (e) => e.stopPropagation() })}>
            <div className={styles.modalContainer}>{children}</div>
        </div>
    </BaseModal>
);

export default SimpleModal;
