/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import dompurify from 'dompurify';
import styles from './index.module.scss';

interface HtmlDescriptionProps {
    description: string;
}

const HtmlDescription: FC<HtmlDescriptionProps> = ({ description }) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(description) }} className={styles.htmlDescription} />
    );
};

export default HtmlDescription;
