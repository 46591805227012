import React, { useEffect, useMemo } from 'react';

const DESKTOP_BREAKPOINT = 768;

const getWidth = (): number => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const useCurrentWidth = (): [number, boolean] => {
    // save current window width in the state object
    const [width, setWidth] = React.useState(getWidth());

    const isMobile = useMemo(() => width < DESKTOP_BREAKPOINT, [width]);

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId: number;
        const resizeListener = () => {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = window.setTimeout(() => setWidth(getWidth()), 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return [width, isMobile];
};

export default useCurrentWidth;
