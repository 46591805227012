import Job from 'core/domain/model/job';
import LoadingState from '../types';
import { SetNextStepAction } from '../nextSteps/setNextStepAction';
import NextStepActionTypes from '../nextSteps/types';
import { SetNextStepsAction } from '../nextSteps/setNextStepsAction';
import NextStep from '../../domain/model/nextStep';
import { DeleteNextStepAction } from '../nextSteps/deleteNextStepAction';
import { SetErrorAction } from './setError';
import { SetJobsAction } from './setJobsAction';
import { SetLoadingAction } from './setLoading';
import JobActionTypes, { JobsErrorType, UserInterestErrorType } from './types';
import { SetUserInterestAction, SetUserInterestErrorAction, SetUserInterestLoadingAction } from './sendUserInterest';

export interface JobState {
    list: Job[];
    loading: LoadingState;
    error: JobsErrorType | null;
    userInterestLoading: LoadingState;
    userInterestError: UserInterestErrorType | null;
    refresh: boolean | null;
}

const init = {
    list: [],
    loading: LoadingState.NOT_LOADED,
    error: null,
    userInterestLoading: LoadingState.NOT_LOADED,
    userInterestError: null,
    refresh: null,
};

type Action =
    | SetJobsAction
    | SetLoadingAction
    | SetErrorAction
    | SetNextStepAction
    | SetNextStepsAction
    | DeleteNextStepAction
    | SetUserInterestAction
    | SetUserInterestErrorAction
    | SetUserInterestLoadingAction;

const getNewStateWithUpdatedNextStep = (state: JobState, action: SetNextStepAction): JobState => {
    const { id, jobCrmId } = action.payload;
    const jobIndex = state.list.findIndex((job) => job.crmId === jobCrmId);

    return {
        ...state,
        list: state.list.map((job, index) => {
            if (jobIndex !== index) {
                return job;
            }

            const nextStepId = job.nextSteps.findIndex((_job) => _job.id === id);
            if (nextStepId === -1) {
                return {
                    ...job,
                    nextSteps: [...job.nextSteps, action.payload],
                };
            }

            return {
                ...job,
                nextSteps: job.nextSteps.map((_nextStep) => {
                    if (_nextStep.id !== id) {
                        return _nextStep;
                    }

                    return action.payload;
                }),
            };
        }),
    };
};

const getNewStateWithUpdatedNextSteps = (state: JobState, action: SetNextStepsAction): JobState => {
    const nextStepsArray = Object.entries(action.payload).reduce((acc, [id, nextStep]) => {
        return [...acc, { id, ...nextStep }];
    }, [] as NextStep[]);

    if (!nextStepsArray.length) {
        return state;
    }
    const { jobCrmId } = nextStepsArray[0];
    const jobIndex = state.list.findIndex((job) => job.crmId === jobCrmId);

    return {
        ...state,
        list: state.list.map((job, index) => {
            if (jobIndex !== index) {
                return job;
            }

            return {
                ...job,
                nextSteps: nextStepsArray,
            };
        }),
    };
};

const getNewStateWithDeleteNextStep = (state: JobState, action: DeleteNextStepAction): JobState => {
    const jobIndex = state.list.findIndex((job) => job.nextSteps.find((nextStep) => nextStep.id === action.payload));

    return {
        ...state,
        list: state.list.map((job, index) => {
            if (jobIndex !== index) {
                return job;
            }

            return {
                ...job,
                nextSteps: job.nextSteps.filter((nextStep) => nextStep.id !== action.payload),
            };
        }),
    };
};

const getNewStateWithUpdatedJob = (state: JobState, action: SetUserInterestAction): JobState => {
    const jobIndex = state.list.findIndex((job) => job.crmId === action.payload);

    return {
        ...state,
        list: state.list.map((job, index) => {
            if (jobIndex !== index) {
                return job;
            }

            return { ...job, userInterestHasBeenSent: true };
        }),
    };
};

const jobReducer = (state: JobState = init, action: Action): JobState => {
    switch (action.type) {
        case NextStepActionTypes.SET_NEXT_STEP:
            return getNewStateWithUpdatedNextStep(state, action);
        case NextStepActionTypes.SET_NEXT_STEPS:
            return getNewStateWithUpdatedNextSteps(state, action);
        case NextStepActionTypes.DELETE_NEXT_STEP:
            return getNewStateWithDeleteNextStep(state, action);
        case JobActionTypes.SET_JOBS:
            return {
                ...state,
                list: action.payload,
            };
        case JobActionTypes.SET_JOBS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case JobActionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case JobActionTypes.SET_USER_INTEREST:
            return getNewStateWithUpdatedJob(state, action);
        case JobActionTypes.SET_USER_INTEREST_ERROR:
            return { ...state, userInterestError: action.payload };
        case JobActionTypes.SET_USER_INTEREST_LOADING:
            return { ...state, userInterestLoading: action.payload };
        default:
            return state;
    }
};

export default jobReducer;
