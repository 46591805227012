/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AutoComplete from 'react-google-autocomplete';
import { Tooltip } from '@material-ui/core';
import { validateEmail, validateFirstName } from 'core/domain/behavior/validateMyInformations';
import { sessionSelector } from 'core/useCases/session/selectors';
import useModal from 'hooks/modal';
import Camera from 'images/camera.svg';
import Part1 from 'images/tick_part1.svg';
import Part2 from 'images/tick_part2.svg';
import Cross from 'images/cross.svg';
import createCustomEventsFetcher, { CreateEventArgs } from 'fetchers/createCustomEventFetcher';
import updateCustomEventsFetcher from 'fetchers/updateCustomEventFetcher';

import createCustomEventAction from 'core/useCases/events/createEventAction';

import TalentEvent from 'core/domain/model/event';
import deleteCustomEventsFetcher from 'fetchers/deleteCustomEventFetcher';
import deleteCustomEventAction from 'core/useCases/events/deleteEventAction';
import talentManagerSelector from 'core/useCases/talentManager/selectors';
import { TextInput } from '../lib';
import ConfirmationModal from '../lib/ConfirmationModal';
import DateTimePickerInput from '../lib/DateTimePickerInput';
import styles from './index.module.scss';

interface CreateEventFormProps {
    onSuccess(): void;
    selectedEvent?: TalentEvent;
}

export interface Place {
    // eslint-disable-next-line camelcase
    formatted_address: string;
}

enum LOCATION {
    REMOTE = 'remote',
    ONSITE = 'onsite',
    CHOOSE = 'choose',
}

const GOOGLE_API_KEY = 'AIzaSyB87b7Rt-2giZbzr-WKIQm6_K6EDpdvWp8';

const computeEventTime = (event?: TalentEvent): [Date, Date] | undefined => {
    return event ? [new Date(event.start.dateTime), new Date(event.end.dateTime)] : undefined;
};

const computeEventLocation = (event?: TalentEvent) => {
    if (!event) {
        return LOCATION.CHOOSE;
    }
    const loc: LOCATION = event.isRemote ? LOCATION.REMOTE : LOCATION.ONSITE;

    return loc;
};

const CreateEventForm: FC<CreateEventFormProps> = ({ onSuccess, selectedEvent }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const connectedUser = useSelector(sessionSelector);
    const talentManager = useSelector(talentManagerSelector);

    const defaultAttendees = [];
    if (connectedUser?.email) {
        defaultAttendees.push(connectedUser.email);
    }

    if (talentManager?.email) {
        defaultAttendees.push('talent-manager@avizio.fr');
    }

    const [updating, setUpdating] = useState(false);
    const [attendees, setAttendees] = useState<string[]>(
        selectedEvent?.attendees?.map((a) => a.email) || defaultAttendees,
    );

    const [startTime, setStartTime] = useState<Date | undefined>(
        selectedEvent ? new Date(selectedEvent.start.dateTime as string) : undefined,
    );
    const [endTime, setEndTime] = useState<Date | undefined>(
        selectedEvent ? new Date(selectedEvent.end.dateTime as string) : undefined,
    );
    const [place, setPlace] = useState<Place | undefined>(
        selectedEvent ? { formatted_address: selectedEvent?.location || '' } : undefined,
    );
    const [activeChip, setActiveChip] = useState<string>();
    const [isRemote, setIsRemote] = useState<LOCATION>(computeEventLocation(selectedEvent));
    const { isOpen: confirmationIsOpen, openModal, closeModal } = useModal();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            title: selectedEvent?.summary,
            time: computeEventTime(selectedEvent),
        },
    });
    const { isOpen: deletionIsOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

    const [currentAttendee, setCurrentAttendee] = useState<string>('');

    const {
        getValues,
        formState: { isValid },
    } = methods;

    const computeCurrentAttendee = () => {
        if (!currentAttendee) {
            return;
        }
        if (validateEmail(currentAttendee)) {
            setAttendees([...attendees, currentAttendee]);
            setCurrentAttendee('');
        }
    };

    // Function to just open confirmation modal
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        computeCurrentAttendee();
        openModal();
    };

    // Cleanup function, triggered once submit has actually successed
    const handleSubmitSuccess = () => {
        setUpdating(false);
        closeModal();
        onSuccess();
    };

    // Function to actually submit values
    const submitValues = () => {
        setUpdating(true);
        const values = getValues();
        const event: CreateEventArgs = {
            id: selectedEvent?.id,
            userId: connectedUser?.id as string,
            title: values.title as string,
            startTime,
            endTime,
            attendees,
            isRemote: isRemote === LOCATION.REMOTE,
            place: place?.formatted_address,
        };
        if (event.id) {
            dispatch(
                createCustomEventAction(updateCustomEventsFetcher, { ...event, isCustom: selectedEvent?.isCustom })(),
            );
        } else {
            dispatch(createCustomEventAction(createCustomEventsFetcher, event)());
        }
        handleSubmitSuccess();
    };

    const handleLocation = (state: LOCATION) => {
        setIsRemote(state);
    };

    const handleAttendees = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentAttendee(e.target.value);
        if (!currentAttendee) {
            return;
        }
        if (
            (e.target.value.endsWith(',') || e.target.value.endsWith(' ')) &&
            validateEmail(e.target.value.substr(0, e.target.value.length - 1))
        ) {
            setAttendees([...attendees, e.target.value.substr(0, e.target.value.length - 1)]);
            setCurrentAttendee('');
        }
    };

    const deleteChip = (e: React.KeyboardEvent<HTMLButtonElement>, chip: string) => {
        if ((e.key === 'Delete' || e.key === 'Backspace' || e.keyCode === 8) && chip === activeChip) {
            const att = [...attendees];
            const iof = att.indexOf(chip);
            att.splice(iof, 1);
            setAttendees(att);
        }
    };

    const deleteOneChip = (chip: string) => {
        const att = [...attendees];
        const iof = att.indexOf(chip);
        att.splice(iof, 1);
        setAttendees(att);
    };

    const chips = attendees.map((chip) => {
        return (
            <button
                key={chip}
                className={styles.chip}
                onClick={() => setActiveChip(chip)}
                onKeyDown={(e) => deleteChip(e, chip)}
                type="button"
            >
                {chip === activeChip ? (
                    <div className={styles.activeChip}>
                        <span>{chip}</span>
                        <button onClick={() => deleteOneChip(chip)} type="button">
                            <img alt="cross" className={styles.cross} src={Cross} />
                        </button>
                    </div>
                ) : (
                    <span className={styles.chipValue}>{chip}</span>
                )}
            </button>
        );
    });

    const handleEventTime = () => {
        const values = getValues();
        if (values.time) {
            setStartTime(new Date(values.time[0]));
            setEndTime(new Date(values.time[1]));
        }
    };

    const disableSubmition =
        !isValid || !startTime || !endTime || isRemote === LOCATION.CHOOSE || attendees.length === 0;

    const getTitle = () => {
        return getValues().title;
    };

    // eslint-disable-next-line camelcase
    const handleSelectPlace = (selectedPlace: Place) => {
        setPlace({ formatted_address: selectedPlace.formatted_address });
    };

    const deleteEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        openDeleteModal();
    };

    const submitDeleteAction = () => {
        dispatch(
            deleteCustomEventAction(
                deleteCustomEventsFetcher,
                connectedUser?.id as string,
                selectedEvent?.id as string,
            )(),
        );
        handleSubmitSuccess();
    };

    const inputEl = useRef<HTMLInputElement>(null);

    const onButtonClick = () => {
        inputEl?.current?.focus();
    };

    return (
        <>
            <FormProvider {...methods}>
                <form className={styles.formContainer} onSubmit={handleSubmit}>
                    <div onClick={() => computeCurrentAttendee()} role="button" tabIndex={0}>
                        <p>{t('createEvent.subtitle')}</p>

                        <Tooltip title={getTitle}>
                            <TextInput
                                errorMessage="myInformations.errorMessages.text"
                                label="createEvent.fields.title"
                                name="title"
                                validate={validateFirstName}
                            />
                        </Tooltip>

                        <label htmlFor="date">{t('createEvent.fields.date')}</label>
                        <div className={styles.dateTimeSelect}>
                            <DateTimePickerInput
                                defaultValue={computeEventTime(selectedEvent)}
                                name="time"
                                onValueChange={handleEventTime}
                                placeholder={['Début', 'Fin']}
                                type="timerange"
                            />
                        </div>

                        <label htmlFor="attendees">{t('createEvent.fields.attendees')}</label>
                        <div
                            className={styles.chips}
                            onClick={() => {
                                onButtonClick();
                            }}
                            onKeyDown={() => {
                                onButtonClick();
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            {chips}
                            <input
                                ref={inputEl}
                                className={styles.chipsInput}
                                name="attendees"
                                onChange={handleAttendees}
                                type="text"
                                value={currentAttendee}
                            />
                        </div>

                        <p className={`${styles.helperText} ${styles.block}`}>
                            {t('createEvent.fields.attendeesSubTitle')}
                            <span className={styles.bold}> {t('createEvent.fields.attendeesSubTitle2')} </span>
                            {t('createEvent.fields.attendeesSubTitle3')}
                            <span className={styles.bold}>{talentManager?.email}</span>
                        </p>

                        <label htmlFor="location">{t('createEvent.fields.location')}</label>
                        {isRemote === LOCATION.CHOOSE && (
                            <div className={styles.locationSelect}>
                                <button
                                    className={styles.visioButton}
                                    name="location"
                                    onClick={() => handleLocation(LOCATION.REMOTE)}
                                    type="button"
                                >
                                    <img alt="visio" src={Camera} />
                                    {t('createEvent.fields.remote')}
                                </button>
                                <button
                                    className={styles.onsiteButton}
                                    onClick={() => handleLocation(LOCATION.ONSITE)}
                                    type="button"
                                >
                                    <span className={styles.tick}>
                                        <img alt="part1" className={styles.part1} src={Part1} />
                                        <img alt="part2" className={styles.part2} src={Part2} />
                                    </span>

                                    <span>{t('createEvent.fields.onsite')}</span>
                                </button>
                            </div>
                        )}
                        {isRemote === LOCATION.REMOTE && (
                            <div className={styles.location}>
                                <div className={styles.locationPic}>
                                    <img alt="visio" src={Camera} />
                                </div>
                                {selectedEvent ? (
                                    <p>{selectedEvent.conferenceData?.entryPoints[0].uri}</p>
                                ) : (
                                    <p>{t('createEvent.visio')}</p>
                                )}
                            </div>
                        )}
                        {isRemote === LOCATION.ONSITE && (
                            <div className={styles.location}>
                                <div className={styles.locationPic}>
                                    <div className={styles.tick}>
                                        <img alt="part1" className={styles.part1} src={Part1} />
                                        <img alt="part2" className={styles.part2} src={Part2} />
                                    </div>
                                </div>
                                <AutoComplete
                                    apiKey={GOOGLE_API_KEY}
                                    className={styles.locationInput}
                                    defaultValue={place?.formatted_address}
                                    onPlaceSelected={(selectedPlace) => handleSelectPlace(selectedPlace)}
                                    options={{
                                        componentRestrictions: { country: ['fr'] },
                                        language: 'fr',
                                        types: [],
                                    }}
                                />
                            </div>
                        )}
                        {isRemote !== LOCATION.CHOOSE && (
                            <button
                                className={styles.changeLocation}
                                onClick={() => handleLocation(LOCATION.CHOOSE)}
                                type="button"
                            >
                                {t('createEvent.fields.changeLocation')}
                            </button>
                        )}
                    </div>
                    {!selectedEvent && (
                        <div className={styles.formAction}>
                            <button disabled={disableSubmition} type="submit">
                                {t('createEvent.create')}
                            </button>
                        </div>
                    )}
                    {selectedEvent && (
                        <div className={styles.formActions}>
                            <button onClick={deleteEvent} type="submit">
                                {t('createEvent.delete')}
                            </button>
                            <button disabled={disableSubmition} type="submit">
                                {t('createEvent.edit')}
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {confirmationIsOpen && (
                <ConfirmationModal
                    cancel={closeModal}
                    loading={updating}
                    submitValues={submitValues}
                    titleKey={selectedEvent ? 'createEvent.editConfirmation' : 'createEvent.createConfirmation'}
                />
            )}

            {deletionIsOpen && (
                <ConfirmationModal
                    cancel={closeDeleteModal}
                    loading={updating}
                    submitValues={submitDeleteAction}
                    titleKey="createEvent.deleteConfirmation"
                />
            )}
        </>
    );
};

export default CreateEventForm;
