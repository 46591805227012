import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GtmProps } from 'utils/gtmAttributes';
import styles from './index.module.scss';

// Hack to require at least 'link' or 'onClick' prop
type HeaderElementProps = { textKey: string; gtmAttributes?: GtmProps } & (
    | { link: string; onClick?: undefined }
    | { onClick(): void; link?: undefined }
);

const HeaderElement: FC<HeaderElementProps> = ({ link, textKey, onClick, gtmAttributes }) => {
    const { t } = useTranslation();

    if (link) {
        return (
            <a href={link} rel="noreferrer noopener" target="_blank" {...gtmAttributes}>
                <div className={styles.menuElement}>{t(textKey)}</div>
            </a>
        );
    }

    return (
        <button onClick={onClick} type="button" {...gtmAttributes}>
            <div className={styles.menuElement}>{t(textKey)}</div>
        </button>
    );
};

export default HeaderElement;
