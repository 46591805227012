import User from 'core/domain/model/user';
import { SignUserUpFetcher } from 'core/useCases/session/signUserUp';
import { SignupErrorType } from 'core/useCases/session/types';
import BACKEND_URL from 'utils/backendUrl';
import { PUT } from 'utils/http';

interface ResponseData {
    user: User;
}

const signUserUpFetcher: SignUserUpFetcher = async (id, password, resume, favoriteCommunicationChannels) => {
    try {
        const formData = new FormData();

        formData.append('password', password);
        if (resume) {
            formData.append('resume', resume);
        }
        formData.append('favoriteCommunicationChannels', JSON.stringify(favoriteCommunicationChannels));

        const response = (await PUT<ResponseData>(`${BACKEND_URL}/users/${id}/activate`, formData)) as ResponseData;

        return response.user;
    } catch (error) {
        throw new Error(SignupErrorType.AUTH_ERROR);
    }
};

export default signUserUpFetcher;
