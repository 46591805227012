import React, { FC } from 'react';
import MultiSelectInput, { SelectOption } from 'components/form/lib/SelectInput/MultiSelectInput';
import { FavoriteCommunicationChannel } from 'core/domain/model/user';

interface FavoritesCommunicationChannelsProps {
    description?: string;
    defaultValues?: FavoriteCommunicationChannel[];
}

export const options: SelectOption[] = [
    { value: FavoriteCommunicationChannel.PHONE_CALL, label: 'signup.favoriteCommunicationChannels.options.phoneCall' },
    { value: FavoriteCommunicationChannel.EMAIL, label: 'signup.favoriteCommunicationChannels.options.email' },
    { value: FavoriteCommunicationChannel.SMS, label: 'signup.favoriteCommunicationChannels.options.sms' },
    { value: FavoriteCommunicationChannel.WHATSAPP, label: 'signup.favoriteCommunicationChannels.options.whatsapp' },
];

const FavoriteCommunicationChannels: FC<FavoritesCommunicationChannelsProps> = ({ defaultValues, description }) => {
    return (
        <MultiSelectInput
            defaultValues={
                defaultValues
                    ? defaultValues?.map((channel) => options.filter((option) => option.value === channel)[0])
                    : []
            }
            description={description || ''}
            errorMessage="signup.errorMessages.text"
            label="signup.favoriteCommunicationChannels.label"
            name="favoriteCommunicationChannels"
            options={options}
            placeholder="signup.favoriteCommunicationChannels.placeholder"
            validate={(value) => value.length > 0}
        />
    );
};

export default FavoriteCommunicationChannels;
