import { ThunkAction } from 'core/store';
import User, { FavoriteCommunicationChannel } from '../../domain/model/user';
import { setSessionUserAction } from './logInAction';
import SessionActionTypes, { SignupErrorType } from './types';

export interface SignupErrorAction {
    type: SessionActionTypes.SIGN_UP_ERROR;
    payload: SignupErrorType | null;
}

export const signupErrorAction = (message: SignupErrorType | null): SignupErrorAction => ({
    type: SessionActionTypes.SIGN_UP_ERROR,
    payload: message,
});

export type SignUserUpFetcher = (
    id: string,
    password: string,
    resume: File | null,
    favoriteCommunicationChannels: FavoriteCommunicationChannel[],
) => Promise<User>;

const signUserUp = (fetcher: SignUserUpFetcher) => (
    id: string,
    password: string,
    resume: File | null,
    favoriteCommunicationChannels: FavoriteCommunicationChannel[],
): ThunkAction => async (dispatch, getState, { history }) => {
    try {
        const user = await fetcher(id, password, resume, favoriteCommunicationChannels);
        dispatch(setSessionUserAction(user));

        history.replace('/');
    } catch (error) {
        dispatch(signupErrorAction(error.message));
    }
};

export default signUserUp;
