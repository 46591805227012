import { JobsErrorType } from 'core/useCases/jobs/types';
import BACKEND_URL from 'utils/backendUrl';
import { DELETE } from 'utils/http';

export type DeleteEventsFetcher = (userId: string, eventId: string) => Promise<boolean>;
const deleteCustomEventsFetcher: DeleteEventsFetcher = async (userId: string, eventId: string) => {
    try {
        const response = (await DELETE<boolean>(
            `${BACKEND_URL}/calendar-events/delete?eventId=${eventId}&userId=${userId}`,
        )) as boolean;

        return response;
    } catch (error) {
        throw new Error(JobsErrorType.RETRIVAL_ERROR);
    }
};

export default deleteCustomEventsFetcher;
