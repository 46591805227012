import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DescriptionFile } from 'core/domain/model/job';
import talentManagerSelector from 'core/useCases/talentManager/selectors';
import newTabIcon from 'images/icon-new-tab.svg';
import gtmAttributes from 'utils/gtmAttributes';

interface FileDescriptionProps {
    description: DescriptionFile;
}

const FileDescription: FC<FileDescriptionProps> = ({ description }) => {
    const { t } = useTranslation();
    const talentManager = useSelector(talentManagerSelector);

    const openDescriptionPdf = (): void => {
        window.open(description.fileLink, '_blank');
    };

    return (
        <>
            <p>
                {t('jobCard.expand.tabs.aboutJob.content.fileDescription.text', {
                    talentManager: talentManager?.firstName,
                })}
            </p>
            <button onClick={openDescriptionPdf} type="button" {...gtmAttributes('expand-openjobdesc')}>
                <img alt={t('jobCard.expand.tabs.aboutJob.content.fileDescription.button')} src={newTabIcon} />
                <p>{t('jobCard.expand.tabs.aboutJob.content.fileDescription.button')}</p>
            </button>
        </>
    );
};

export default FileDescription;
