import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@material-ui/core';
import iconChecked from 'images/icon-checkbox-checked.svg';
import iconUnchecked from 'images/icon-checkbox-unchecked.svg';
import styles from './index.module.scss';

interface IconProps {
    path: string;
    altKey: string;
}

const Icon: FC<IconProps> = ({ path, altKey }) => {
    const { t } = useTranslation();

    return <img alt={t(altKey)} className={styles.icon} src={path} />;
};

interface OptionProps {
    label: string;
    selected: boolean;
}

const Option: FC<OptionProps> = ({ label, selected }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.option}>
            <Checkbox
                checked={selected}
                checkedIcon={<Icon altKey="signup.favoriteCommunicationChannels.alts.checked" path={iconChecked} />}
                icon={<Icon altKey="signup.favoriteCommunicationChannels.alts.unChecked" path={iconUnchecked} />}
            />
            <p className={classNames({ [styles.selected]: selected })}>{t(label)}</p>
        </div>
    );
};

export default Option;
