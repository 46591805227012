import { InputAdornment, TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import iconList from 'images/icon-list-gray.svg';
import styles from './index.module.scss';

interface SelectInputProps {
    placeholder: string;
    inputParams: AutocompleteRenderInputParams;
}

// eslint-disable-next-line jsx-a11y/alt-text
const Icon = () => <img src={iconList} />;

const SelectInput: FC<SelectInputProps> = ({ inputParams, placeholder }) => {
    const { t } = useTranslation();

    return (
        <TextField
            {...inputParams}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                ...inputParams.InputProps,
                startAdornment: (
                    <InputAdornment position="start">
                        <Icon />
                    </InputAdornment>
                ),
            }}
            className={styles.input}
            placeholder={t(placeholder)}
            variant="outlined"
        />
    );
};

export default SelectInput;
