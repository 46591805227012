import Job from 'core/domain/model/job';
import { ThunkAction } from '../../store';
import LoadingState from '../types';
import setError from './setError';
import setLoading from './setLoading';
import JobActionTypes from './types';

export interface SetJobsAction {
    type: JobActionTypes.SET_JOBS;
    payload: Job[];
}

export const setJobs = (jobs: Job[]): SetJobsAction => ({
    type: JobActionTypes.SET_JOBS,
    payload: jobs,
});

export type JobsFetcher = (backendUrl: string, isMock?: boolean) => Promise<Job[]>;

const listUserJobsAction = (jobsFetcher: JobsFetcher, isMock?: boolean) => (): ThunkAction => async (
    dispatch,
    getState,
    { backendUrl },
) => {
    dispatch(setLoading(LoadingState.LOADING));
    try {
        const jobs = await jobsFetcher(backendUrl, isMock);

        dispatch(setError(null));
        dispatch(setJobs(jobs));
    } catch (error) {
        dispatch(setError(error.message));
    } finally {
        dispatch(setLoading(LoadingState.LOADED));
    }
};

export default listUserJobsAction;
