/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { GtmProps } from 'utils/gtmAttributes';
import styles from './index.module.scss';

interface RoundedCheckboxProps {
    name: string;
    defaultChecked: boolean;
    onValueChange(): void;
    gtmAttributes?: GtmProps;
}

const RoundedCheckbox: FC<RoundedCheckboxProps> = ({ name, defaultChecked, onValueChange, gtmAttributes }) => {
    const { register } = useFormContext();

    return (
        <div className={styles.checkboxContainer} {...gtmAttributes}>
            <div className={styles.round}>
                <input
                    ref={register()}
                    defaultChecked={defaultChecked}
                    id={name}
                    name={name}
                    onChange={onValueChange}
                    type="checkbox"
                />
                <label htmlFor={name} />
            </div>
        </div>
    );
};

export default RoundedCheckbox;
