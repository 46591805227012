import React, { FC } from 'react';
import Logo from 'images/logo-avizio-secondary-white.svg';
import Menu from 'images/menu.svg';
import useToggle from 'hooks/toggle';
import MenuMobile from '../MenuMobile';
import Drawer from '../Drawer';
import styles from './index.module.scss';

interface IHeaderMobile {
    menuTitle?: string;
    headerTitle: string;
}

const HeaderMobile: FC<IHeaderMobile> = ({ headerTitle, menuTitle }) => {
    const [open, toggle] = useToggle(false);

    return (
        <div className={styles.headerWrapper}>
            <button onClick={toggle} type="button">
                <img key="left" alt="Menu" src={Menu} />
            </button>
            <h2>{headerTitle}</h2>
            <div className={styles.logoWrapper}>
                <img alt="Logo Avizio" src={Logo} />
            </div>
            <Drawer open={open} toggle={toggle}>
                <MenuMobile onClose={toggle} title={menuTitle} />
            </Drawer>
        </div>
    );
};

export default HeaderMobile;
