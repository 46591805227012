import React, { FC } from 'react';
import useModal from 'hooks/modal';
import FormModal from 'components/layouts/shared/Modal/FormModal';
import MyInformationsForm from 'components/form/MyInformationsForm';
import gtmAttributes from 'utils/gtmAttributes';
import HeaderElement from '../HeaderElement';

interface MyInformationsProps {
    onModalChange?: null | (() => void);
}

const MyInformations: FC<MyInformationsProps> = ({ onModalChange }) => {
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <>
            <HeaderElement
                gtmAttributes={gtmAttributes('header-menu-info')}
                onClick={() => {
                    openModal();
                    if (onModalChange) {
                        onModalChange();
                    }
                }}
                textKey="header.menu.myInfo"
            />
            {isOpen && (
                <FormModal
                    closeModal={() => {
                        closeModal();
                        if (onModalChange) {
                            onModalChange();
                        }
                    }}
                    titleKey="myInformations.title"
                >
                    <MyInformationsForm
                        onSuccess={() => {
                            closeModal();
                            if (onModalChange) {
                                onModalChange();
                            }
                        }}
                    />
                </FormModal>
            )}
        </>
    );
};

export default MyInformations;
