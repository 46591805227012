import React, { FC, useEffect, useState } from 'react';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import listUserEventsAction from 'core/useCases/events/setEventsAction';
import eventsFetcher from 'fetchers/eventsFetcher';
import eventsSelector, { eventsAreLoadingSelector } from 'core/useCases/events/selectors';
import TalentEvent from 'core/domain/model/event';
import NoEventsCard from 'components/NoEventsCard';
import EventCard from 'components/EventCard/desktop';
import useModal from 'hooks/modal';
import CreateEventForm from 'components/form/CreateEventForm';
import FormModal from 'components/layouts/shared/Modal/FormModal';
import More from 'images/more.svg';
import useCurrentWidth from 'hooks/width';
import styles from './index.module.scss';
import ActivityPlaceholder from './Placeholder';

const Activities: FC = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const events = useSelector(eventsSelector);

    const [newEventFormIsOpen, setNewEventFormIsOpen] = useState(false);
    const { isOpen, openModal, closeModal } = useModal();
    const [selectedTalentEvent, setSelectedTalentEvent] = useState<TalentEvent>();

    const onModalChange = () => {
        setNewEventFormIsOpen(!newEventFormIsOpen);
    };

    useEffect(() => {
        dispatch(listUserEventsAction(eventsFetcher)());
    }, []);

    const [eventsToCome, setEventsToCome] = useState<TalentEvent[]>([]);
    const [eventsFinished, setEventsFinished] = useState<TalentEvent[]>([]);

    useEffect(() => {
        if (!events) {
            return;
        }
        setEventsToCome(
            events
                .filter((event) => isAfter(parseISO(event.end.dateTime), new Date()))
                .sort((a, b) => (isBefore(parseISO(b.end.dateTime), parseISO(a.end.dateTime)) ? 1 : -1)),
        );
        setEventsFinished(
            events
                .filter((event) => isBefore(parseISO(event.end.dateTime), new Date()))
                .sort((a, b) => (isBefore(parseISO(b.end.dateTime), parseISO(a.end.dateTime)) ? -1 : 1)),
        );
    }, [events]);

    const loading = useSelector(eventsAreLoadingSelector);

    const openEventForm = (event?: TalentEvent) => {
        setSelectedTalentEvent(event);
        openModal();
    };

    const closeEventForm = () => {
        setSelectedTalentEvent(undefined);
        closeModal();
    };

    const [, isMobile] = useCurrentWidth();

    return (
        <div className={styles.container}>
            {loading ? (
                <div className={styles.placeholders}>
                    <ActivityPlaceholder />
                    <ActivityPlaceholder />
                    <ActivityPlaceholder />
                    <ActivityPlaceholder />
                </div>
            ) : (
                <>
                    {eventsToCome.length ? (
                        <button className={styles.mainButton} onClick={openModal} type="button">
                            <img alt="create" src={More} />
                            {isMobile ? t('createEvent.createMobile') : t('createEvent.create')}
                        </button>
                    ) : undefined}
                    <div className={`${styles.subContainer} ${styles.firstContainer}`}>
                        <h1 className={styles.title}>{t('events.toCome')}</h1>
                        {eventsToCome.length ? (
                            eventsToCome.map((e) => <EventCard key={e.id} event={e} onClickEdit={openEventForm} />)
                        ) : (
                            <NoEventsCard onClick={openModal} />
                        )}
                    </div>
                    <div className={styles.subContainer}>
                        {eventsFinished.length !== 0 && (
                            <>
                                <h1 className={styles.title}>{t('events.finished')}</h1>
                                {eventsFinished.map((e) => (
                                    <EventCard key={e.id} event={e} onClickEdit={openEventForm} />
                                ))}
                            </>
                        )}
                    </div>
                </>
            )}
            {isOpen && (
                <FormModal
                    closeModal={() => {
                        closeEventForm();
                        if (onModalChange) {
                            onModalChange();
                        }
                    }}
                    closeModalOnOverlayClick={false}
                    titleKey="createEvent.title"
                >
                    <CreateEventForm
                        onSuccess={() => {
                            closeEventForm();
                            if (onModalChange) {
                                onModalChange();
                            }
                        }}
                        selectedEvent={selectedTalentEvent}
                    />
                </FormModal>
            )}
        </div>
    );
};

export default Activities;
