import TalentNotification from 'core/domain/model/talentNotification';
import { NotificationErrorType } from 'core/useCases/session/types';
import BACKEND_URL from 'utils/backendUrl';
import { POST } from 'utils/http';

interface ResponseData {
    notification: TalentNotification;
}

const toggleNotificationFetcher = async (id: string): Promise<TalentNotification> => {
    try {
        const response = (await POST(`${BACKEND_URL}/notifications/toggle/${id}`, {
            id,
        })) as ResponseData;

        return response.notification;
    } catch (error) {
        throw new Error(NotificationErrorType.UNKNOWN_ERROR);
    }
};

export default toggleNotificationFetcher;
