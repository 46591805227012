import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Job from 'core/domain/model/job';
import ExclamationPoint from 'images/exclamation-point.svg';
import JobCardPlaceholder from 'components/JobCard/Placeholder';
import { jobsAreLoadingSelector } from 'core/useCases/jobs/selectors';
import SuggestionLogo from '../../../../images/icon-propositions.svg';
import OnGoingLogo from '../../../../images/icon-ongoing.svg';
import OfferLogo from '../../../../images/icon-offer.svg';
import NotConcludeLogo from '../../../../images/icon-not-concluded.svg';
import PlacedLogo from '../../../../images/icon-placed.svg';
import talentManagerSelector from '../../../../core/useCases/talentManager/selectors';
import styles from './index.module.scss';
import Jobs from './Jobs';

export enum BackgroundType {
    BASE = 'base',
    LIGHT = 'light',
    DARK = 'dark',
}

interface ColumnProps {
    alwaysVisible: boolean;
    title: string;
    placeholder: string;
    backgroundType: BackgroundType;
    jobs: Job[];
}

const getLogo = (title: string) => {
    switch (title.split('.')[1]) {
        case 'suggestion':
            return SuggestionLogo;
        case 'ongoing':
            return OnGoingLogo;
        case 'offer':
            return OfferLogo;
        case 'placed':
            return PlacedLogo;
        case 'notConclude':
            return NotConcludeLogo;
        default:
            return NotConcludeLogo;
    }
};

const Column: FC<ColumnProps> = ({ alwaysVisible, title, backgroundType, placeholder, jobs }) => {
    const { t } = useTranslation();
    const loading = useSelector(jobsAreLoadingSelector);
    const talentManager = useSelector(talentManagerSelector);
    const light = backgroundType === BackgroundType.LIGHT;
    const base = backgroundType === BackgroundType.BASE;
    const dark = backgroundType === BackgroundType.DARK;

    const isEmpty = jobs.length === 0;

    const logo = getLogo(title);

    return (
        <div className={styles.columnWrapper}>
            {(alwaysVisible || !isEmpty) && (
                <>
                    <div className={styles.columnHeader}>
                        <div className={styles.headerSpace} />
                        <div className={styles.headerTitle}>
                            <span>{t(title, { talentManager: talentManager?.firstName })}</span>
                            <div className={styles.svgWrapper}>
                                <img alt={`logo ${title}`} src={logo} />
                            </div>
                        </div>
                        <div className={styles.badgeWrapper}>
                            {light && !isEmpty && !loading && (
                                <>
                                    <div className={styles.badgeIcon}>
                                        <img alt="/!\" src={ExclamationPoint} />
                                    </div>

                                    <div className={styles.badgeText}>
                                        <span>
                                            {t('columns.suggestion.badge', { talentManager: talentManager?.firstName })}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className={classNames(styles.columnContent, {
                            [styles.light]: light,
                            [styles.base]: base,
                            [styles.dark]: dark,
                        })}
                    >
                        {loading ? (
                            <JobCardPlaceholder />
                        ) : (
                            <Jobs isEmpty={isEmpty} jobs={jobs} light={light} placeholder={placeholder} />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Column;
