/* eslint-disable jsx-a11y/alt-text */
import React, { FC, useMemo } from 'react';
import { Autocomplete, createFilterOptions, FilterOptionsState } from '@material-ui/lab';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import arrowIcon from 'images/icon-down-arrow.svg';
import { NextStepDefaultLabel } from 'core/domain/model/nextStep';
import Option from './Option';
import SelectInput from './SelectInput';
import styles from './index.module.scss';

interface Value {
    default: boolean;
    value: string;
    existing: boolean;
}

export type SelectOption = {
    inputValue?: string;
    value: Value;
    label: string;
};

const filter = (newOptions: SelectOption[], params: FilterOptionsState<SelectOption>) => {
    const options = newOptions.filter((option) => option.value.value !== NextStepDefaultLabel.POSITION_ON_JOB);

    return createFilterOptions<SelectOption>()(options, params);
};

interface SearchableSelectInputProps {
    disabled?: boolean;
    name: string;
    validate?(value: string): boolean;
    placeholder: string;
    defaultValue: Value | null;
    options: SelectOption[];
    onValueChange?(): void;
}

const Icon = () => (
    <div className={styles.arrowWrapper}>
        <img src={arrowIcon} />
    </div>
);

const SearchableSelectInput: FC<SearchableSelectInputProps> = ({
    disabled = false,
    defaultValue,
    name,
    placeholder,
    validate,
    onValueChange,
    options,
}) => {
    const { t } = useTranslation();

    const {
        field: { onChange, ref },
    } = useController({ name, rules: { validate }, defaultValue });

    const initialValue = useMemo(() => options.find(({ value: { value } }) => value === defaultValue?.value), [
        options,
    ]);

    return (
        <div className={styles.inputWrapper}>
            <Autocomplete
                ref={ref}
                ListboxProps={{ className: styles.listBox }}
                defaultValue={initialValue}
                disabled={disabled}
                filterOptions={(newOptions, params) => {
                    const { inputValue } = params;
                    const filtered = filter(newOptions, params) as SelectOption[];

                    if (inputValue !== '') {
                        filtered.push({
                            inputValue,
                            label: t('jobCard.expand.tabs.myNextStep.content.form.label.options.create', {
                                inputValue,
                            }),
                            value: { default: false, existing: false, value: inputValue },
                        });
                    }

                    return filtered;
                }}
                getOptionLabel={(option) => t((option as SelectOption).label)}
                getOptionSelected={(option, selectedOption) => option.value.value === selectedOption.value.value}
                onChange={(event, newValue) => {
                    onChange(newValue.value);

                    if (onValueChange) {
                        onValueChange();
                    }
                }}
                options={options}
                popupIcon={<Icon />}
                renderInput={(params) => <SelectInput inputParams={params} placeholder={placeholder} />}
                renderOption={(option) => <Option label={(option as SelectOption).label} />}
                disableClearable
            />
        </div>
    );
};

export default SearchableSelectInput;
