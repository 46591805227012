import { ThunkAction } from 'core/store';
import NextStepActionTypes from './types';

export interface DeleteNextStepAction {
    type: NextStepActionTypes.DELETE_NEXT_STEP;
    payload: string;
}

const deleteNextStepAction = (nextStepId: string): DeleteNextStepAction => ({
    type: NextStepActionTypes.DELETE_NEXT_STEP,
    payload: nextStepId,
});

export type DeleteNextStepFetcher = (jobCrmId: string, nextStepId: string) => Promise<void>;

export const deleteNextStep = (nextStepFetcher: DeleteNextStepFetcher) => (
    jobCrmId: string,
    nextStepId: string,
): ThunkAction => async (dispatch) => {
    await nextStepFetcher(jobCrmId, nextStepId);
    dispatch(deleteNextStepAction(nextStepId));
};

export default deleteNextStep;
