import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Job, {
    JobDescription as JobDescriptionType,
    DescriptionFile,
    descriptionIsHtml,
    descriptionIsText,
} from 'core/domain/model/job';
import TextDescription from './TextDescription';
import styles from './index.module.scss';
import FileDescription from './FileDescription';
import HtmlDescription from './HtmlDescription';

interface DescriptionProps {
    description: JobDescriptionType;
}

const Description: FC<DescriptionProps> = ({ description }) => {
    if (!description) {
        return null;
    }

    if (descriptionIsHtml(description)) {
        return <HtmlDescription description={description as string} />;
    }

    if (descriptionIsText(description)) {
        return <TextDescription description={description as string} />;
    }

    return <FileDescription description={description as DescriptionFile} />;
};

interface JobDescriptionProps {
    job: Job;
}

const JobDescription: FC<JobDescriptionProps> = ({ job }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.descriptionWrapper}>
            <h4>{t('jobCard.expand.tabs.aboutJob.content.jobDescription')}</h4>
            <Description description={job.description} />
        </div>
    );
};

export default JobDescription;
