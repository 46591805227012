import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { favoriteChannelsSelector, sessionSelector } from 'core/useCases/session/selectors';
import {
    validateEmail,
    validateFirstName,
    validateLastName,
    validatePhone,
} from 'core/domain/behavior/validateMyInformations';
import updateUserInformations from 'core/useCases/session/updateUserInformations';
import useModal from 'hooks/modal';
import userInformationsFetcher from 'fetchers/userInformationsFetcher';
import { TextInput } from '../lib';
import ConfirmationModal from '../lib/ConfirmationModal';
import FavoriteCommunicationChannels from '../SignupForm/FavoriteCommunicationChannels';
import { SelectOption } from '../lib/SelectInput/MultiSelectInput';
import UploadResume from '../SignupForm/UploadResume';
import styles from './index.module.scss';
import PasswordUpdateInput from './PasswordUpdateInput';

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    favoriteCommunicationChannels: SelectOption[];
    resume: FileList;
}

interface MyInformationsFormProps {
    onSuccess(): void;
}

const MyInformationsForm: FC<MyInformationsFormProps> = ({ onSuccess }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const connectedUser = useSelector(sessionSelector);
    const favoriteChannels = useSelector(favoriteChannelsSelector);

    const [updating, setUpdating] = useState(false);
    const { isOpen: confirmationIsOpen, openModal, closeModal } = useModal();

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            firstName: connectedUser?.firstName || '',
            lastName: connectedUser?.lastName || '',
            email: connectedUser?.email || '',
            phone: connectedUser?.phone || '',
            favoriteCommunicationChannels: favoriteChannels || [],
        },
    });

    const {
        getValues,
        formState: { isValid, isDirty },
    } = methods;

    // Function to just open confirmation modal
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        openModal();
    };

    // Cleanup function, triggered once submit has actually successed
    const handleSubmitSuccess = () => {
        setUpdating(false);
        closeModal();
        onSuccess();
    };

    // Function to actually submit values
    const submitValues = () => {
        setUpdating(true);
        const { firstName, lastName, email, phone, favoriteCommunicationChannels, resume } = getValues();
        dispatch(
            updateUserInformations(userInformationsFetcher, handleSubmitSuccess)(
                firstName,
                lastName,
                email,
                phone,
                favoriteCommunicationChannels.map((channel) => channel.value),
                resume && resume.length > 0 ? resume.item(0) : null,
            ),
        );
    };

    const disableSubmition = !isValid || !isDirty;

    return (
        <>
            <FormProvider {...methods}>
                {/* onSubmit, we ask user for confirmation in modal */}
                <form onSubmit={handleSubmit}>
                    <div className={styles.formContainer}>
                        <TextInput
                            errorMessage="myInformations.errorMessages.text"
                            label="myInformations.firstName.label"
                            name="firstName"
                            placeholder="myInformations.firstName.placeholder"
                            validate={validateFirstName}
                        />
                        <TextInput
                            errorMessage="myInformations.errorMessages.text"
                            label="myInformations.lastName.label"
                            name="lastName"
                            placeholder="myInformations.lastName.placeholder"
                            validate={validateLastName}
                        />
                        <TextInput
                            errorMessage="myInformations.errorMessages.email"
                            label="myInformations.email.label"
                            name="email"
                            placeholder="myInformations.email.placeholder"
                            validate={validateEmail}
                        />
                        <TextInput
                            errorMessage="myInformations.errorMessages.text"
                            label="myInformations.phone.label"
                            name="phone"
                            placeholder="myInformations.phone.placeholder"
                            validate={validatePhone}
                        />
                        <FavoriteCommunicationChannels defaultValues={connectedUser?.favoriteCommunicationChannels} />
                        <UploadResume
                            label="myInformations.resume.label"
                            resumeFileName={connectedUser?.resumeFileName || null}
                            resumeLink={connectedUser?.resumeLink || null}
                        />
                        <PasswordUpdateInput />
                    </div>
                    <div className={styles.formActions}>
                        <button disabled={disableSubmition} type="submit">
                            {t('myInformations.submit')}
                        </button>
                    </div>
                </form>
            </FormProvider>
            {confirmationIsOpen && (
                <ConfirmationModal
                    cancel={closeModal}
                    loading={updating}
                    submitValues={submitValues}
                    titleKey="myInformations.confirmation.question"
                />
            )}
        </>
    );
};

export default MyInformationsForm;
