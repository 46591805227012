import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useModal from 'hooks/modal';
import updateUserPassword from 'core/useCases/session/updateUserPassword';
import {
    validateNewPasswordConfirmation,
    validateNewPasswordLength,
    validatePassword,
} from 'core/domain/behavior/validateUpdateUserPassword';
import { updateUserPasswordErrorSelector } from 'core/useCases/session/selectors';
import userPasswordFetcher from 'fetchers/updatePasswordFetcher';
import { TextInput } from '../lib';
import ConfirmationModal from '../lib/ConfirmationModal';
import ErrorText from '../lib/ErrorMessage/ErrorText';
import styles from './index.module.scss';

interface FormValues {
    password: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

interface UpdatePasswordFormProps {
    onSuccess(): void;
}

const UpdatePasswordForm: FC<UpdatePasswordFormProps> = ({ onSuccess }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [updating, setUpdating] = useState(false);
    const { isOpen: confirmationIsOpen, openModal, closeModal } = useModal();

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            password: '',
            newPassword: '',
            newPasswordConfirmation: '',
        },
    });

    const {
        getValues,
        formState: { isValid },
    } = methods;

    const formError = useSelector(updateUserPasswordErrorSelector);

    // Function to just open confirmation modal
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Avoid my informations form to be submitted
        event.stopPropagation();

        openModal();
    };

    // Cleanup function, triggered once submit has actually successed
    const handleSubmitSuccess = () => {
        setUpdating(false);
        closeModal();
        onSuccess();
    };

    const handleSubmitFailed = () => {
        setUpdating(false);
        closeModal();
    };

    // Function to actually submit values
    const submitValues = () => {
        setUpdating(true);
        const { password, newPassword, newPasswordConfirmation } = getValues();

        dispatch(
            updateUserPassword(userPasswordFetcher, handleSubmitSuccess, handleSubmitFailed)(
                password,
                newPassword,
                newPasswordConfirmation,
            ),
        );
    };

    const disableSubmition = !isValid;

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit}>
                    {formError && <ErrorText text={`updatePassword.errorMessages.${formError}`} />}
                    <div className={styles.formContainer}>
                        <TextInput
                            errorMessage="updatePassword.errorMessages.text"
                            label="updatePassword.password.label"
                            name="password"
                            type="password"
                            validate={validatePassword}
                            showPassword
                        />
                        <TextInput
                            errorMessage="updatePassword.errorMessages.length"
                            label="updatePassword.newPassword.label"
                            name="newPassword"
                            type="password"
                            validate={validateNewPasswordLength}
                            showPassword
                        />
                        <TextInput
                            errorMessage="updatePassword.errorMessages.verification"
                            label="updatePassword.newPasswordConfirmation.label"
                            name="newPasswordConfirmation"
                            type="password"
                            validate={(value) => validateNewPasswordConfirmation(getValues('newPassword'), value)}
                            showPassword
                        />
                    </div>
                    <div>
                        <div className={styles.formActions}>
                            <button disabled={disableSubmition} type="submit">
                                {t('updatePassword.submit')}
                            </button>
                        </div>
                    </div>
                </form>
            </FormProvider>
            {confirmationIsOpen && (
                <ConfirmationModal
                    cancel={closeModal}
                    loading={updating}
                    submitValues={submitValues}
                    titleKey="myInformations.confirmation.question"
                />
            )}
        </>
    );
};

export default UpdatePasswordForm;
