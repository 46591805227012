import User from 'core/domain/model/user';
import { UpdateUserPasswordErrorType } from 'core/useCases/session/types';
import { UserPasswordFetcher } from 'core/useCases/session/updateUserPassword';
import BACKEND_URL from 'utils/backendUrl';
import { PUT } from 'utils/http';

interface ResponseData {
    user: User;
}

const userPasswordFetcher: UserPasswordFetcher = async (password, newPassword, newPasswordConfirmation) => {
    try {
        const response = (await PUT<ResponseData>(`${BACKEND_URL}/users/me/password`, {
            password,
            newPassword,
            newPasswordConfirmation,
        })) as ResponseData;

        return response.user;
    } catch (error) {
        if (error.status === 400) {
            throw new Error(UpdateUserPasswordErrorType.INVALID_PASSWORD);
        }

        throw new Error(UpdateUserPasswordErrorType.UNKNOWN_ERROR);
    }
};

export default userPasswordFetcher;
