import React, { FC } from 'react';
import MaterialCarousel from 'react-material-ui-carousel';
import styles from './index.module.scss';

interface CarouselProps {
    activeFrameIndex?: number;
}

const Carousel: FC<CarouselProps> = ({ activeFrameIndex, children }) => (
    <MaterialCarousel
        activeIndicatorProps={{ className: 'carousel-indicator-active', style: {} }}
        animation="slide"
        autoPlay={false}
        className={styles.carousel}
        indicatorContainerProps={{ className: 'carousel-indicators', style: {} }}
        timeout={200}
        indicators
        navButtonsAlwaysInvisible
        {...(activeFrameIndex && { index: activeFrameIndex })}
    >
        {children}
    </MaterialCarousel>
);

export default Carousel;
