import { SelectOption } from 'components/form/lib/SelectInput/MultiSelectInput';
import { options } from 'components/form/SignupForm/FavoriteCommunicationChannels';
import { NextStepCustomLabel } from 'core/domain/model/nextStep';
import User from 'core/domain/model/user';
import { RootState } from 'core/store';
import LoadingState from '../types';
import { LoginErrorType, SignupErrorType, UpdateUserPasswordErrorType, UserData } from './types';

export const loginErrorSelector = (state: RootState): LoginErrorType | null => state.session.loginErrorType;
export const signupErrorSelector = (state: RootState): SignupErrorType | null => state.session.signupErrorType;
export const updateUserPasswordErrorSelector = (state: RootState): UpdateUserPasswordErrorType | null =>
    state.session.updateUserPasswordErrorType;

export const sessionSelector = (state: RootState): User | null => state.session.user;
export const favoriteChannelsSelector = (state: RootState): SelectOption[] | null =>
    state.session.user?.favoriteCommunicationChannels.map(
        (channel) => options.filter((option) => option.value === channel)[0],
    ) || [];
export const sessionIsLoadingSelector = (state: RootState): boolean => state.session.loading === LoadingState.LOADING;
export const sessionLoadingStateSelector = (state: RootState): LoadingState => state.session.loading;

export const userDataSelector = (state: RootState): UserData | null => state.session.userData;

export const userNextStepCustomLabelsSelector = (state: RootState): NextStepCustomLabel[] =>
    state.session.nextStepCustomLabels;

export const menuItemSelector = (state: RootState): number => state.session.menuItem;
