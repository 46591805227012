import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelector } from 'core/useCases/session/selectors';
import NotificationConfig, { NotificationChannel } from 'core/domain/model/notificationConfig';
import { FavoriteCommunicationChannel } from 'core/domain/model/user';
import updateUserNotifConfig from 'core/useCases/session/updateUserNotifConfig';
import userNotifConfigFetcher from 'fetchers/notificationConfigFetcher';
import useModal from 'hooks/modal';
import ConfirmationModal from '../lib/ConfirmationModal';
import ConfigTile from './ConfigTile';
import styles from './index.module.scss';

interface MyNotificationsProps {
    onSuccess(): void;
}

const MyNotificationsForm: FC<MyNotificationsProps> = ({ onSuccess }) => {
    const connectedUser = useSelector(sessionSelector);
    const [configs, setConfigs] = useState<NotificationConfig[] | undefined>(connectedUser?.notificationsConfigs);
    const [configChanged, setConfigChanged] = useState<boolean>(false);
    const { isOpen: confirmationIsOpen, openModal, closeModal } = useModal();
    const [updating, setUpdating] = useState(false);
    const [noConf, setNoConf] = useState(false);

    // Function to just open confirmation modal
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (!configs?.find((c) => c.isActive)) {
            setNoConf(true);
        } else {
            setNoConf(false);
        }
        openModal();
    };

    // Cleanup function, triggered once submit has actually successed
    const handleSubmitSuccess = () => {
        setUpdating(false);
        closeModal();
        onSuccess();
    };
    const dispatch = useDispatch();
    const handleChange = (config: NotificationConfig) => {
        if (configs) {
            const confToUpdate = configs?.find((c) => c.type === config.type);
            const newConfigs = [...configs];
            if (confToUpdate) {
                newConfigs.splice(newConfigs.indexOf(confToUpdate), 1, config);
                if (config.isActive && !config.channels.length) {
                    config.channels.push(NotificationChannel.EMAIL);
                }
                setConfigs(newConfigs);
            }
        }
    };

    const checkModification = () => {
        let hasChanged = false;
        if (!connectedUser || !configs) {
            return;
        }
        const originalConfigs = [...connectedUser?.notificationsConfigs];
        const newConfigs = [...configs];

        for (const c of originalConfigs) {
            const newConfig = newConfigs.find((cfg) => cfg.type === c.type);
            if (!newConfig) {
                break;
            }
            for (const key of Object.keys(c)) {
                if (c[key] instanceof Array) {
                    const originalArray = (c[key] as Array<FavoriteCommunicationChannel>).sort();
                    const newArray = (newConfig[key] as Array<FavoriteCommunicationChannel>).sort();

                    if (newArray.length !== originalArray.length) {
                        hasChanged = true;
                    }

                    if (JSON.stringify(newArray) !== JSON.stringify(originalArray)) hasChanged = true;
                } else if (newConfig[key] !== c[key]) {
                    hasChanged = true;
                }
            }
        }

        setConfigChanged(hasChanged);
    };

    useEffect(() => {
        checkModification();
    }, [configs]);

    const submitValues = () => {
        dispatch(
            updateUserNotifConfig(userNotifConfigFetcher, onSuccess)(
                connectedUser?.id as string,
                configs as NotificationConfig[],
            ),
        );
        handleSubmitSuccess();
    };

    return (
        <div className={styles.formContainer}>
            {configs?.map((config) => {
                return (
                    <div key={configs?.indexOf(config)}>
                        <ConfigTile key={configs?.indexOf(config)} config={config} onChange={handleChange} />
                        {configs.length > 1 && configs.indexOf(config) !== configs.length - 1 ? <hr /> : undefined}
                    </div>
                );
            })}
            <div className={styles.formActions}>
                <button disabled={!configChanged} onClick={handleSubmit} type="submit">
                    Enregistrer
                </button>
            </div>
            {confirmationIsOpen && (
                <ConfirmationModal
                    cancel={closeModal}
                    loading={updating}
                    submitValues={submitValues}
                    titleKey="myInformations.confirmation.question"
                    warning={noConf ? 'myNotifications.warning' : undefined}
                />
            )}
        </div>
    );
};

export default MyNotificationsForm;
