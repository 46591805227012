import React, { FC } from 'react';
import { Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NotificationConfig, { NotificationChannel } from 'core/domain/model/notificationConfig';
import talentManagerSelector from 'core/useCases/talentManager/selectors';
import styles from './index.module.scss';

interface ConfigTileProps {
    config: NotificationConfig;
    onChange: (config: NotificationConfig) => void;
}

const ConfigTile: FC<ConfigTileProps> = ({ config, onChange }) => {
    const { t } = useTranslation();
    const talentManager = useSelector(talentManagerSelector);
    const handleChannels = (channel: NotificationChannel) => {
        const newChannels = [...config.channels];
        if (newChannels.includes(channel)) {
            newChannels.splice(newChannels.indexOf(channel), 1);
        } else {
            newChannels.push(channel);
        }

        if (newChannels.length === 0) {
            onChange({ ...config, isActive: false, channels: newChannels });
        } else {
            onChange({ ...config, channels: newChannels });
        }
    };

    return (
        <>
            <div className={styles.tileElement}>
                <div>{config.type}</div>
                <Switch
                    checked={config.isActive}
                    className={styles.switch}
                    onClick={() => onChange({ ...config, isActive: !config.isActive })}
                />
            </div>
            <p className={styles.subtitle}>
                {t(`myNotifications.subtitles.${config.type}`, { talentManager: talentManager?.firstName })}
            </p>
            <div>
                {Object.values(NotificationChannel).map((c) => {
                    return (
                        <div key={c} className={styles.channel} hidden={!config.isActive}>
                            <div>{t(`myNotifications.channels.${c}`)}</div>
                            <Switch checked={config.channels.includes(c)} onClick={() => handleChannels(c)} />
                        </div>
                    );
                })}
            </div>
            <hr />
        </>
    );
};

export default ConfigTile;
