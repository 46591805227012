import { ThunkAction } from 'core/store';
import SessionActionTypes, { UserData } from './types';

export interface SetUserDataAction {
    type: SessionActionTypes.SET_USER_DATA;
    payload: UserData;
}

const setUserDataAction = (userData: UserData) => ({
    type: SessionActionTypes.SET_USER_DATA,
    payload: userData,
});

type Fetcher = (id: string, token: string) => Promise<UserData>;

const validateCredentials = (fetcher: Fetcher) => (
    id: string | undefined,
    token: string | undefined,
): ThunkAction => async (dispatch, getState, { history }) => {
    if (!id || !token) {
        history.replace('/login');
    } else {
        try {
            const userData = await fetcher(id, token);
            dispatch(setUserDataAction(userData));
        } catch (error) {
            console.error(error);

            history.replace('/login');
        }
    }
};

export default validateCredentials;
