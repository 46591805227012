import TalentEvent from 'core/domain/model/event';
import { CreateEventArgs } from 'fetchers/createCustomEventFetcher';
import eventsFetcher from 'fetchers/eventsFetcher';
import { ThunkAction } from '../../store';
import LoadingState from '../types';
import setError from './setError';
import { setEvents } from './setEventsAction';
import setLoading from './setLoading';
import EventActionTypes from './types';

export interface CreateCustomEventAction {
    type: EventActionTypes.CREATE_EVENT;
    payload: CreateEventArgs;
}

export const setNextStepAction = (payload: CreateEventArgs): CreateCustomEventAction => ({
    type: EventActionTypes.CREATE_EVENT,
    payload,
});
export type CreateEventsFetcher = (payload: CreateEventArgs) => Promise<TalentEvent>;

const createCustomEventAction = (
    createEventsFetcher: CreateEventsFetcher,
    payload: CreateEventArgs,
    isMock?: boolean,
) => (): ThunkAction => async (dispatch, getState, { backendUrl }) => {
    dispatch(setLoading(LoadingState.LOADING));
    try {
        await createEventsFetcher(payload);
        const events = await eventsFetcher(backendUrl, isMock);

        dispatch(setError(null));
        dispatch(setEvents(events));
    } catch (error) {
        dispatch(setError(error.message));
    } finally {
        dispatch(setLoading(LoadingState.LOADED));
    }
};

export default createCustomEventAction;
