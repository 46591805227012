import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import ErrorText from './ErrorText';

interface ErrorMessage {
    name: string;
    errorMessage?: string;
}

const ErrorMessage: FC<ErrorMessage> = ({ name, errorMessage }) => {
    const { errors } = useFormContext();

    const error = errors[name];

    return error ? <ErrorText text={errorMessage || error.message} /> : null;
};

export default ErrorMessage;
