import NextStep, { NextStepCustomLabel } from 'core/domain/model/nextStep';
import { DeleteNextStepFetcher } from 'core/useCases/nextSteps/deleteNextStepAction';
import { CreateNextStepFetcher, UpdateNextStepFetcher } from 'core/useCases/nextSteps/setNextStepAction';
import { GetNextStepLabelOptionsFetcher } from 'core/useCases/session/getNextStepLabelOptions';
import BACKEND_URL from 'utils/backendUrl';
import { DELETE, GET, POST, PUT } from 'utils/http';

interface CreateOrUpdateNextStepResponse {
    nextStep: NextStep;
    labels: NextStepCustomLabel[];
}

const createNextStepFetcher: CreateNextStepFetcher = async (jobCrmId) => {
    const response = (await POST<CreateOrUpdateNextStepResponse>(
        `${BACKEND_URL}/jobs/${jobCrmId}/next-steps`,
    )) as CreateOrUpdateNextStepResponse;

    return response.nextStep;
};

export const updateNextStepFetcher: UpdateNextStepFetcher = async (jobCrmId, nextStepId, nextStepData) => {
    const response = (await PUT<CreateOrUpdateNextStepResponse>(
        `${BACKEND_URL}/jobs/${jobCrmId}/next-steps/${nextStepId}`,
        { ...nextStepData },
    )) as CreateOrUpdateNextStepResponse;

    return { nextStep: response.nextStep, labels: response.labels };
};

export const deleteNextStepFetcher: DeleteNextStepFetcher = async (jobCrmId, nextStepId) => {
    await DELETE(`${BACKEND_URL}/jobs/${jobCrmId}/next-steps/${nextStepId}`);
};

interface GetNextStepLabelsResponse {
    nextStepLabels: NextStepCustomLabel[];
}

export const getNextStepLabelOptionsFetcher: GetNextStepLabelOptionsFetcher = async () => {
    const response = (await GET<GetNextStepLabelsResponse>(
        `${BACKEND_URL}/next-step-custom-labels`,
    )) as GetNextStepLabelsResponse;

    return response.nextStepLabels;
};

export default createNextStepFetcher;
