import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import gtmAttributes from 'utils/gtmAttributes';
import useModal from '../../../../../../hooks/modal';
import StopProcessModal from '../../../../../layouts/shared/Modal/StopProcessModal';
import styles from './index.module.scss';

const TabElement: FC = ({ children }) => {
    const { t } = useTranslation();
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <div className={styles.contentWrapper}>
            {children}
            <button className={styles.footer} onClick={openModal} type="button" {...gtmAttributes('expand-linknogo')}>
                {t('jobCard.expand.stop.button')}
            </button>
            {isOpen && <StopProcessModal closeModal={closeModal} />}
        </div>
    );
};

export default TabElement;
