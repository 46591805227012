import { TalentManager } from '../../domain/model/collaborator';
import { ThunkAction } from '../../store';
import TalentManagerActionTypes from './types';

export interface SetTalentManagerAction {
    type: TalentManagerActionTypes.SET_TALENT_MANAGER;
    payload: TalentManager;
}

const setTalentManagerAction = (talentManager: TalentManager): SetTalentManagerAction => ({
    type: TalentManagerActionTypes.SET_TALENT_MANAGER,
    payload: talentManager,
});

type TalentManagerFetcher = () => Promise<TalentManager>;

const setTalentManager = (talentManagerFetcher: TalentManagerFetcher) => (): ThunkAction => async (dispatch) => {
    try {
        const talentManager = await talentManagerFetcher();
        dispatch(setTalentManagerAction(talentManager));
    } catch (error) {
        console.error(error);
    }
};

export default setTalentManager;
