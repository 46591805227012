import React, { FC } from 'react';
import JobCardExpandModal from 'components/layouts/shared/Modal/JobCardExpandModal';
import Job from '../../../core/domain/model/job';
import HeaderSection from './HeaderSection';
import ContentSection from './ContentSection';

interface JobCardExpandProps {
    job: Job;
    onClose: () => void;
}

const JobCardExpand: FC<JobCardExpandProps> = ({ job, onClose }) => (
    <JobCardExpandModal closeModal={onClose}>
        <HeaderSection job={job} onClose={onClose} />
        <ContentSection job={job} />
    </JobCardExpandModal>
);

export default JobCardExpand;
