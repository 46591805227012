export enum NotificationChannel {
    EMAIL = 'email',
    SMS = 'sms',
}

interface NotificationConfig {
    type: string;
    isActive: boolean;
    channels: NotificationChannel[];
    [key: string]: unknown;
}

export default NotificationConfig;
