enum JobActionTypes {
    SET_JOBS = 'SET_JOBS',
    SET_JOBS_LOADING = 'SET_JOBS_LOADING',
    SET_ERROR = 'SET_ERROR',
    SET_NEXT_STEPS = 'SET_NEXT_STEPS',
    SET_NEXT_STEP = 'SET_NEXT_STEP',
    DELETE_NEXT_STEP = 'DELETE_NEXT_STEP',
    SET_USER_INTEREST = 'SET_USER_INTEREST',
    SET_USER_INTEREST_LOADING = 'SET_USER_INTEREST_LOADING',
    SET_USER_INTEREST_ERROR = 'SET_USER_INTEREST_ERROR',
    SET_LIST_REFRESH = 'SET_LIST_REFRESH',
}

export enum JobsErrorType {
    RETRIVAL_ERROR = 'retrivalError',
}

export enum UserInterestErrorType {
    INVALID_JOB_STAGE = 'invalidJobStage',
}

export default JobActionTypes;
