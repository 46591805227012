import TalentNotification from 'core/domain/model/talentNotification';
import { NotificationErrorType } from 'core/useCases/session/types';
import BACKEND_URL from 'utils/backendUrl';
import { POST } from 'utils/http';

interface ResponseDataReadAll {
    notifications: TalentNotification[];
}

const readAllNotificationsFetcher = async (userId: string): Promise<TalentNotification[]> => {
    try {
        const response = (await POST(`${BACKEND_URL}/notifications/readall/${userId}`, {
            userId,
        })) as ResponseDataReadAll;

        return response.notifications;
    } catch (error) {
        throw new Error(NotificationErrorType.UNKNOWN_ERROR);
    }
};

export default readAllNotificationsFetcher;
