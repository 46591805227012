import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

interface UseModalReturnType {
    isOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
    toggleModal: () => void;
}

const useModal = (initialState: boolean = false): UseModalReturnType => {
    const [isOpen, setIsOpen] = React.useState<boolean>(initialState);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    return { isOpen, openModal, closeModal, toggleModal };
};

type UseSyncModalOptions = {
    shouldBeOpen: boolean;
    onCloseRedirectTo: string;
    onOpenRedirectTo: string;
};

export function useSyncModal(options: UseSyncModalOptions): UseModalReturnType {
    const { shouldBeOpen, onOpenRedirectTo, onCloseRedirectTo } = options;

    const history = useHistory();

    const [isOpen, setIsOpen] = React.useState<boolean>(shouldBeOpen);

    // Triggers a redirection
    const openModal = () => {
        history.push(onOpenRedirectTo);
    };

    // Triggers a redirection
    const closeModal = () => {
        setIsOpen(false);
        history.push(onCloseRedirectTo);
    };

    // Either close or open modal depending on current state
    const toggleModal = () => {
        if (isOpen) {
            closeModal();
        } else {
            openModal();
        }
    };

    // Everytime 'shouldBeOpen' is updated, we open/close the modal
    useEffect(() => {
        if (shouldBeOpen) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [shouldBeOpen]);

    return { isOpen, openModal, closeModal, toggleModal };
}

export default useModal;
