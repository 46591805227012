import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import NewPasswordForm from '../../components/form/NewPasswordForm';
import useQueryParams from '../../utils/queryParams';

const NewPasswordPage: FC = () => {
    const { t } = useTranslation();
    const { token, id } = useQueryParams() as { id?: string; token?: string };

    if (!token || !id) {
        return <Redirect to="/login" />;
    }

    const userData = {
        id,
        token,
    };

    return (
        <>
            <h2>{t('newPassword.title')}</h2>
            <NewPasswordForm userData={userData} />
        </>
    );
};

export default NewPasswordPage;
