import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import LogoutIcon from 'images/logout.svg';
import { ABOUT_US_LINK, HELP_FAQ_LINK } from 'utils/constants';
import MyInformations from 'components/layouts/mobile/ConnectedLayout/MyInformations';
import gtmAttributes from 'utils/gtmAttributes';
import MyNotifications from 'components/layouts/mobile/ConnectedLayout/MyNotifications';
import InitialsIcon from '../InitialsIcon';
import styles from './index.module.scss';

export interface IUser {
    firstName: string;
    lastName: string;
    email: string;
}

interface MenuProps {
    onDisconnect: () => void;
    onModalChange: () => void;
    user: IUser;
}

const MenuContent: FC<MenuProps> = ({ onDisconnect, onModalChange, user }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.menuContent}>
            <div className={classNames(styles.menuElement, styles.userMailWrapper)}>
                <InitialsIcon user={user} />
                <div className={styles.userInformation}>
                    <h4>{`${user.firstName} ${user.lastName}`}</h4>
                </div>
            </div>
            <hr className={styles.hrElement} />
            <MyInformations onModalChange={onModalChange} />
            <MyNotifications onModalChange={onModalChange} />
            <hr className={styles.hrElement} />
            <div className={classNames(styles.menuElement, styles.menuText)}>
                <a href={ABOUT_US_LINK} rel="noreferrer noopener" target="_blank" {...gtmAttributes('menu-apropos')}>
                    <div>{t('whoAreWe')}</div>
                </a>
            </div>{' '}
            <div className={classNames(styles.menuElement, styles.menuText)}>
                <a href={HELP_FAQ_LINK} rel="noreferrer noopener" target="_blank" {...gtmAttributes('header-faq')}>
                    <div>{t('header.help')}</div>
                </a>
            </div>
            <hr className={styles.hrElement} />
            <button onClick={onDisconnect} type="button">
                <div className={classNames(styles.menuElement, styles.menuLogout)}>
                    <img alt="Logout" src={LogoutIcon} />
                    <h4>{t('header.menu.logout')}</h4>
                </div>
            </button>
        </div>
    );
};

export default MenuContent;
