import { UserData } from 'core/useCases/session/types';
import BACKEND_URL from 'utils/backendUrl';
import { POST } from 'utils/http';

interface CredentialFetcherResponse {
    data: UserData;
}

const credentialFetcher = async (id: string, token: string): Promise<UserData> => {
    const response = (await POST<CredentialFetcherResponse>(`${BACKEND_URL}/users/${id}/validate-credentials`, {
        token,
    })) as CredentialFetcherResponse;

    return response.data;
};

export default credentialFetcher;
