interface Details {
    one: string;
    other: string;
}

type Value = string | Details;

const formatDistanceLocale: Record<string, Value> = {
    lessThanXSeconds: {
        one: '<1sec',
        other: '<{{count}}sec',
    },

    xSeconds: {
        one: '1sec',
        other: '{{count}}sec',
    },

    halfAMinute: '30sec',

    lessThanXMinutes: {
        one: '<1m',
        other: '<{{count}}m',
    },

    xMinutes: {
        one: '1m',
        other: '{{count}}m',
    },

    aboutXHours: {
        one: '1h',
        other: '{{count}}h',
    },

    xHours: {
        one: '1h',
        other: '{{count}}h',
    },

    xDays: {
        one: '1j',
        other: '{{count}}j',
    },

    aboutXWeeks: {
        one: '1sem',
        other: '{{count}}sem',
    },

    xWeeks: {
        one: '1sm',
        other: '{{count}}sem',
    },

    aboutXMonths: {
        one: '1mo',
        other: '{{count}}mo',
    },

    xMonths: {
        one: '1mo',
        other: '{{count}}mo',
    },

    aboutXYears: {
        one: '1a',
        other: '{{count}}a',
    },

    xYears: {
        one: '1a',
        other: '{{count}}a',
    },

    overXYears: {
        one: '>1a',
        other: '>{{count}}a',
    },

    almostXYears: {
        one: '1a',
        other: '{{count}}a',
    },
};

interface Options {
    addSuffix?: boolean;
    comparison?: number;
}

const formatDistance = (token: string, count: number, options: Options = {}): string => {
    let result;
    if (typeof formatDistanceLocale[token] === 'string') {
        result = formatDistanceLocale[token] as string;
    } else if (count === 1) {
        result = (formatDistanceLocale[token] as Details).one;
    } else {
        result = (formatDistanceLocale[token] as Details).other.replace('{{count}}', count.toString());
    }

    if (options.addSuffix) {
        if (options.comparison && options.comparison > 0) {
            return `dans ${result}`;
        }

        return `il y a ${result}`;
    }

    return result;
};

export default formatDistance;
