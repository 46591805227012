import classNames from 'classnames';
import React, { FC } from 'react';
import baseStyles from '../index.module.scss';
import styles from './index.module.scss';

const JobCardPlaceholder: FC = () => (
    <div className={baseStyles.jobCardWrapper}>
        <div className={baseStyles.jobCardContent}>
            <div className={baseStyles.companyAvatarWrapper}>
                <div className={baseStyles.companyLogo}>
                    <div className={classNames(styles.avatarPlaceholder, styles.placeholder)} />
                </div>
            </div>
            <div className={baseStyles.jobInfos}>
                <div className={classNames(styles.textPlaceholder, styles.placeholder)} />
                <div className={classNames(styles.textPlaceholder, styles.placeholder)} />
            </div>
        </div>
    </div>
);

export default JobCardPlaceholder;
