import Company from './company';
import { Contact } from './collaborator';
import NextStep from './nextStep';

export enum JobStage {
    SUGGESTION = 'suggestion',
    ONGOING = 'ongoing',
    OFFER = 'offer',
    PLACED = 'placed',
    NOT_CONCLUDE = 'notConclude',
}

export interface DescriptionFile {
    fileName: string;
    fileLink: string;
}

export type JobDescription = string | DescriptionFile | null;

interface Job {
    id: string;
    name: string;
    stage: JobStage;
    crmId: string;
    company: Company;
    contact: Contact;
    updatedAt: string;
    description: JobDescription;
    additionalInfo: string;
    nextSteps: NextStep[];
    userInterestHasBeenSent: boolean;
}

const HTML_ELEMENT_REGEX = /(<([^>]+)>)/gi;

export const descriptionIsText = (description: JobDescription): boolean => {
    return typeof description === 'string';
};

export const descriptionIsHtml = (description: JobDescription): boolean => {
    if (typeof description !== 'string') {
        return false;
    }

    return HTML_ELEMENT_REGEX.test(description);
};

export default Job;
