import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Activities from 'components/Activities';
import ConnectCalendar from 'components/ConnectCalendar';
import { sessionSelector } from 'core/useCases/session/selectors';
import User from 'core/domain/model/user';
import ErrorCalendar from 'components/ErrorCalendar';
import selectMenuItem from 'core/useCases/session/selectMenuItem';
import useTalentManager from 'hooks/talentManager';

const ActivityPage: FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(sessionSelector) as User;
    const params = useParams<{ error: string }>();
    useTalentManager();

    useEffect(() => {
        dispatch(selectMenuItem(2));
    }, []);

    if (params.error) {
        return <ErrorCalendar error={params.error} />;
    }

    return user.googleToken ? <Activities /> : <ConnectCalendar />;
};

export default ActivityPage;
