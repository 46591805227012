import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import informationIcon from 'images/exclamation-point.svg';
import processIcon from 'images/icon-process.svg';
import listIcon from 'images/icon-list-white.svg';
import Process, { ProcessCompType } from 'components/JobCard/JobCardExpand/ContentSection/Tabs/Process';
import AboutJob, { AboutJobCompType } from 'components/JobCard/JobCardExpand/ContentSection/Tabs/AboutJob';
import Job from 'core/domain/model/job';
import MyNextStep from 'components/JobCard/JobCardExpand/ContentSection/Tabs/MyNextStep';

type TabComponent = ProcessCompType | AboutJobCompType;

export interface Tab {
    alt: string;
    icon: string;
    label: string;
    to: string;
    Component: TabComponent;
    gtmId: string;
}

export const getTabs = (job: Job): Tab[] => [
    {
        icon: processIcon,
        alt: 'jobCard.expand.tabs.process.alt',
        label: 'jobCard.expand.tabs.process.title',
        to: 'process',
        Component: Process,
        gtmId: 'process',
    },
    ...(job.description
        ? [
              {
                  icon: informationIcon,
                  alt: 'jobCard.expand.tabs.aboutJob.alt',
                  label: 'jobCard.expand.tabs.aboutJob.title',
                  to: 'a-propos-du-job',
                  Component: AboutJob,
                  gtmId: 'apropos',
              },
          ]
        : []),
    {
        icon: listIcon,
        alt: 'jobCard.expand.tabs.myNextStep.alt',
        label: 'jobCard.expand.tabs.myNextStep.title',
        to: 'ma-next-step',
        Component: MyNextStep,
        gtmId: 'nextstep',
    },
];

const useTabs = (
    job: Job,
): { tabs: Tab[]; activeTabIndex: number | undefined; ActiveTabComponent: TabComponent | null } => {
    const history = useHistory();
    const params = useParams<{ id: string; tab: string }>();

    const tabs = getTabs(job);

    const activeTab = useMemo(() => tabs.findIndex((tab) => tab.to === params.tab), [params]);
    const activeTabIndex = activeTab === -1 ? undefined : activeTab;

    const ActiveTabComponent = useMemo(() => (activeTabIndex !== undefined ? tabs[activeTabIndex].Component : null), [
        activeTabIndex,
    ]);

    useEffect(() => {
        const { id } = params;

        // Use strict condition to avoid redirection when activeTabIndex is 0
        if (activeTabIndex === undefined) {
            history.push(`/mes-process/jobs/${id}/process`);
        }
    }, [activeTabIndex]);

    return { tabs, activeTabIndex, ActiveTabComponent };
};

export default useTabs;
