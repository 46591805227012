import classNames from 'classnames';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Job from 'core/domain/model/job';
import checkIcon from 'images/icon-check.svg';
import crossIcon from 'images/refuse.svg';
import { userInterestHasBeenSentSelector, userInterestIsLoadingSelector } from 'core/useCases/jobs/selectors';
import sendUserInterest from 'core/useCases/jobs/sendUserInterest';
import useModal from 'hooks/modal';
import ConfirmationModal from 'components/form/lib/ConfirmationModal';
import gtmAttributes from 'utils/gtmAttributes';
import AskModal from 'components/form/lib/AskModal';
import sendInterestFetcher from 'fetchers/sendInterestFetcher';
import styles from './index.module.scss';

interface InterestButtonProps {
    job: Job;
    interest: boolean;
}

const InterestButton: FC<InterestButtonProps> = ({ job, interest }) => {
    const { t } = useTranslation();
    const { isOpen: confirmationIsOpen, openModal, closeModal } = useModal();
    const interestHasBeenSent = useSelector(userInterestHasBeenSentSelector(job.crmId));
    const interestIsSending = useSelector(userInterestIsLoadingSelector);
    const history = useHistory();

    const onSuccess = () => {
        history.push(`/mes-process/`);
    };
    const dispatch = useDispatch();

    const sendInterest = () => {
        dispatch(sendUserInterest(sendInterestFetcher, onSuccess)(job.crmId, interest, ''));
    };

    const sendDisinterest = (reasonWhy: string) => {
        dispatch(sendUserInterest(sendInterestFetcher, onSuccess)(job.crmId, interest, reasonWhy));
    };

    return (
        <>
            <button
                className={classNames(styles.interestButton, interest ? styles.green : styles.red)}
                disabled={interestHasBeenSent}
                onClick={openModal}
                type="button"
                {...gtmAttributes('expand-okrencontre')}
            >
                <img alt="check" src={interest ? checkIcon : crossIcon} />
                {interest
                    ? t('jobCard.expand.tabs.process.content.suggestion.sendInterest')
                    : t('jobCard.expand.tabs.process.content.suggestion.sendDisinterest')}
            </button>
            {confirmationIsOpen && interest && (
                <ConfirmationModal
                    cancel={closeModal}
                    loading={interestIsSending}
                    submitValues={sendInterest}
                    titleData={{ companyName: job.company.name }}
                    titleKey="jobCard.expand.tabs.process.content.suggestion.confirmation"
                />
            )}
            {confirmationIsOpen && !interest && (
                <AskModal
                    cancel={closeModal}
                    loading={interestIsSending}
                    submitValues={sendDisinterest}
                    subtitleKey="jobCard.expand.tabs.process.content.suggestion.confirmationDisinterestSubtitle"
                    titleData={{ companyName: job.company.name }}
                    titleKey="jobCard.expand.tabs.process.content.suggestion.confirmationDisinterest"
                />
            )}
        </>
    );
};

export default InterestButton;
