import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleModal from '../Modal/SimpleModal';

interface IPopup {
    onAccept: () => void;
    onCancel: () => void;
}

const PopupDisconnect: FC<IPopup> = ({ onAccept, onCancel }) => {
    const { t } = useTranslation();

    return (
        <SimpleModal>
            <h2>{t('popup.disconnect')}</h2>
            <div className="popupButtonsWrapper">
                <button onClick={onCancel} type="button">
                    {t('popup.cancel')}
                </button>
                <button onClick={onAccept} type="button">
                    {t('popup.validate')}
                </button>
            </div>
        </SimpleModal>
    );
};

export default PopupDisconnect;
