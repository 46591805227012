import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ErrorIcon from 'images/error.svg';
import BACKEND_URL from 'utils/backendUrl';
import { sessionSelector } from 'core/useCases/session/selectors';
import { GET } from 'utils/http';
import styles from './index.module.scss';

interface ResponseData {
    url: Location;
}

interface ErrorCalendarProps {
    error: string;
}

const ErrorCalendar: FC<ErrorCalendarProps> = ({ error }) => {
    const { t } = useTranslation();
    const user = useSelector(sessionSelector);

    let errorText = '';

    if (error === 'erreur-connexion-compte') {
        errorText = `${t('calendarConnect.errorText')} ${user?.email}`;
    }

    if (error === 'erreur-scopes') {
        errorText = t('calendarConnect.errorScopes');
    }

    const redirectToGoogleConnect = async () => {
        const { url } = (await GET(`${BACKEND_URL}/google-apis/auth/get-url?redirect=/mon-activite`)) as ResponseData;
        window.location = url;
    };

    return (
        <div className={styles.container}>
            <img alt="errorIcon" className={styles.userIcon} src={ErrorIcon} />
            <div className={styles.textContainer}>
                <h1 className={styles.title}>{t('calendarConnect.errorTitle')}</h1>
                <p className={styles.errorText}>{errorText}</p>
            </div>
            <button className={styles.connectButton} onClick={() => redirectToGoogleConnect()} type="button">
                {t('calendarConnect.reconnectCalendar')}
            </button>
        </div>
    );
};

export default ErrorCalendar;
