import React, { FC } from 'react';
import styles from './index.module.scss';

interface IUser {
    firstName: string;
    lastName: string;
    email: string;
}

interface InitialsIconProps {
    user: IUser;
}

const getInitialsFromName = (user: IUser): string => {
    return user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase();
};

const InitialsIcon: FC<InitialsIconProps> = ({ user }) => {
    return <div className={styles.icon}>{getInitialsFromName(user)}</div>;
};

export default InitialsIcon;
