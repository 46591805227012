import React, { FC } from 'react';
import styles from './index.module.scss';

interface DrawerProps {
    open: boolean;
    toggle(): void;
}

const Drawer: FC<DrawerProps> = ({ children, open, toggle }) => {
    if (!open) {
        return null;
    }

    return (
        <div className={styles.overlay} onClick={toggle} role="presentation">
            <div className={styles.container} onClick={(e) => e.stopPropagation()} role="presentation">
                {children}
            </div>
        </div>
    );
};

export default Drawer;
