import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ResetPasswordForm from '../../components/form/ResetPasswordForm';

const ResetPasswordPage: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <h2>{t('resetPassword.title')}</h2>
            <h3>{t('resetPassword.subtitle')}</h3>
            <ResetPasswordForm />
        </>
    );
};

export default ResetPasswordPage;
