import { combineReducers, Reducer } from 'redux';
import { RootState } from 'core/store';
import jobReducer from './jobs/reducer';
import sessionReducer from './session/reducer';
import SessionActionTypes from './session/types';
import talentManagerReducer from './talentManager/reducer';
import eventReducer from './events/reducer';

export const appReducer = combineReducers({
    job: jobReducer,
    session: sessionReducer,
    talentManager: talentManagerReducer,
    event: eventReducer,
});

const rootReducer: Reducer = (state: RootState | undefined, action): RootState => {
    // That trick allows us to clear store everytime user logout
    // Passing state as undefined will make all reducers fallback to their initial state value (aka reset)
    if (action.type === SessionActionTypes.CLEAR_SESSION_USER) {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
