import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCurrentWidth from 'hooks/width';
import { StagedJobs, stagedJobsSelector } from 'core/useCases/jobs/selectors';
import useColumns from 'hooks/columns';
import Company from 'core/domain/model/company';
import Job, { JobStage } from 'core/domain/model/job';
import listUserJobsAction from 'core/useCases/jobs/setJobsAction';
import useTalentManager from 'hooks/talentManager';
import { NextStepDefaultLabel } from 'core/domain/model/nextStep';
import jobsFetcher from 'fetchers/jobsFetcher';
import { Contact } from '../../core/domain/model/collaborator';
import KanbanMobile from './mobile';
import KanbanDesktop from './desktop';
import styles from './index.module.scss';

const blizzard: Company = {
    id: 'azeaze',
    name: 'Blizzard',
    avatarUrl:
        'https://cdn.phenompeople.com/CareerConnectResources/BLENGLOBAL/fr_fr/mobile/assets/images/v-1606557684515-header-logo.png',
    crmId: '123123',
    websiteUrl: 'https://google.com',
    linkedinUrl: 'https://linkedin.com',
};

const davidKim: Contact = {
    id: 123,
    firstName: 'David',
    lastName: 'Kim',
    calendarLink: null,
    contactNumber: '0102030405',
    designation: 'Head of Starcraft 2 balance',
    email: 'david.kim@blizzard.com',
};

export const mockedJobs: Job[] = [
    {
        id: 'id-12',
        name: 'Test job',
        stage: JobStage.SUGGESTION,
        crmId: '1988',
        company: blizzard,
        contact: davidKim,
        updatedAt: '2020-10-01T13:36:01.000Z',
        description: 'toto',
        additionalInfo: 'very good job !',
        userInterestHasBeenSent: false,
        nextSteps: [],
    },
    {
        id: 'id-0',
        name: 'Commentateur & Desk Analyst Starcraft 2',
        stage: JobStage.PLACED,
        crmId: '1206',
        company: blizzard,
        contact: davidKim,
        updatedAt: '2020-10-01T13:36:01.000Z',
        description: 'toto',
        additionalInfo: 'very good job !',
        userInterestHasBeenSent: false,
        nextSteps: [],
    },
    {
        id: 'id-1',
        name: 'Joueur Pro Overwatch',
        stage: JobStage.ONGOING,
        crmId: '1207',
        company: blizzard,
        contact: davidKim,
        updatedAt: '2020-08-01T13:36:01.000Z',
        description: 'toto',
        additionalInfo: 'very good job !',
        userInterestHasBeenSent: false,
        nextSteps: [
            {
                id: '3131ceac-4ade-4374-984f-ff47fe2953d8',
                defaultLabel: NextStepDefaultLabel.AWAITING_FEEDBACK,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-09T00:00:00.000Z',
                done: false,
            },
            {
                id: '1e390db4-c2ce-41f9-9574-5a184b74fe29',
                defaultLabel: NextStepDefaultLabel.PREPARE_BUSINESS_CASE,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-09T00:00:00.000Z',
                done: false,
            },
            {
                id: 'f966c223-c5be-4ca2-b595-0de1e11c8b30',
                defaultLabel: NextStepDefaultLabel.UPCOMING_IRL_APPOINTMENT,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-04T00:00:00.000Z',
                done: false,
            },
        ],
    },
    {
        id: 'id-2',
        name: 'Desk Analyst Hearthstone',
        stage: JobStage.OFFER,
        crmId: '1208',
        company: blizzard,
        contact: davidKim,
        updatedAt: '2020-06-01T13:36:01.000Z',
        description: 'toto',
        additionalInfo: 'very good job !',
        userInterestHasBeenSent: false,
        nextSteps: [
            {
                id: '1e390db4-c2ce-41f9-9574-5a184b74fe29',
                defaultLabel: NextStepDefaultLabel.PREPARE_BUSINESS_CASE,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-09T00:00:00.000Z',
                done: false,
            },
            {
                id: 'f966c223-c5be-4ca2-b595-0de1e11c8b30',
                defaultLabel: NextStepDefaultLabel.UPCOMING_CALL,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-04T00:00:00.000Z',
                done: false,
            },
        ],
    },
    {
        id: 'id-3',
        name: 'Desk Analyst Hearthstone',
        stage: JobStage.ONGOING,
        crmId: '1209',
        company: blizzard,
        contact: davidKim,
        updatedAt: '2021-02-11T13:36:01.000Z',
        description: 'toto',
        additionalInfo: 'very good job !',
        userInterestHasBeenSent: false,
        nextSteps: [
            {
                id: '1e390db4-c2ce-41f9-9574-5a184b74fe29',
                defaultLabel: NextStepDefaultLabel.AWAITING_FEEDBACK,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-09T00:00:00.000Z',
                done: false,
            },
            {
                id: 'f966c223-c5be-4ca2-b595-0de1e11c8b30',
                defaultLabel: NextStepDefaultLabel.AWAITING_FEEDBACK,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-04T00:00:00.000Z',
                done: false,
            },
        ],
    },
    {
        id: 'id-4',
        name: 'Game Design Starcraft 2',
        stage: JobStage.ONGOING,
        crmId: '1210',
        company: blizzard,
        contact: davidKim,
        updatedAt: '2021-02-11T13:36:01.000Z',
        description: 'toto',
        additionalInfo: 'very good job !',
        userInterestHasBeenSent: false,
        nextSteps: [
            {
                id: '3131ceac-4ade-4374-984f-ff47fe2953d8',
                defaultLabel: NextStepDefaultLabel.AWAITING_FEEDBACK,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-09T00:00:00.000Z',
                done: false,
            },
            {
                id: '1e390db4-c2ce-41f9-9574-5a184b74fe29',
                defaultLabel: NextStepDefaultLabel.AWAITING_FEEDBACK,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-09T00:00:00.000Z',
                done: false,
            },
            {
                id: 'f966c223-c5be-4ca2-b595-0de1e11c8b30',
                defaultLabel: NextStepDefaultLabel.AWAITING_FEEDBACK,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-04T00:00:00.000Z',
                done: false,
            },
        ],
    },
    {
        id: 'id-5',
        name: 'Balance Team Hearthstone',
        stage: JobStage.NOT_CONCLUDE,
        crmId: '1211',
        company: blizzard,
        contact: davidKim,
        updatedAt: '2021-02-11T13:36:01.000Z',
        description: 'toto',
        additionalInfo: 'very good job !',
        userInterestHasBeenSent: false,
        nextSteps: [
            {
                id: '1e390db4-c2ce-41f9-9574-5a184b74fe29',
                defaultLabel: NextStepDefaultLabel.AWAITING_FEEDBACK,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-09T00:00:00.000Z',
                done: false,
            },
            {
                id: 'f966c223-c5be-4ca2-b595-0de1e11c8b30',
                defaultLabel: NextStepDefaultLabel.AWAITING_FEEDBACK,
                customLabel: null,
                jobCrmId: '11817194',
                dueDate: '2021-03-04T00:00:00.000Z',
                done: false,
            },
        ],
    },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useJobsFromApi = (): StagedJobs => {
    const dispatch = useDispatch();
    const jobs = useSelector(stagedJobsSelector);

    useEffect(() => {
        dispatch(listUserJobsAction(jobsFetcher)());
    }, []);

    return jobs;
};

const Kanban: FC = () => {
    const jobs = useJobsFromApi();
    const columns = useColumns(jobs);
    const [, isMobile] = useCurrentWidth();
    useTalentManager();

    const KanbanComponent = isMobile ? KanbanMobile : KanbanDesktop;

    return (
        <div className={styles.kanbanWrapper}>
            <KanbanComponent columns={columns} jobs={jobs} />
        </div>
    );
};

export default Kanban;
