import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TOptions } from 'i18next';
import closeIcon from 'images/close-cross.svg';
import BaseModal from '../BaseModal';
import styles from './index.module.scss';

interface FormModalProps {
    titleKey: string;
    titleParams?: TOptions;
    closeModal(): void;
    closeModalOnOverlayClick?: boolean;
}

const FormModal: FC<FormModalProps> = ({
    children,
    titleKey,
    titleParams,
    closeModal,
    closeModalOnOverlayClick = true,
}) => {
    const { t } = useTranslation();

    return (
        <BaseModal closeModal={closeModalOnOverlayClick ? closeModal : undefined}>
            <div className={styles.container} {...(closeModal && { onClick: (e) => e.stopPropagation() })}>
                <div className={styles.modalContainer}>
                    <button className={styles.closeIcon} onClick={closeModal} type="button">
                        <img alt={t('jobCard.expand.closeAlt')} src={closeIcon} />
                    </button>
                    <h2>{t(titleKey, titleParams)}</h2>
                    <hr />
                    {children}
                </div>
            </div>
        </BaseModal>
    );
};

export default FormModal;
