import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next/';
import BACKEND_URL from 'utils/backendUrl';
import { GET } from 'utils/http';
import Spinner from 'components/layouts/shared/Spinner';
import useQueryParams from 'utils/queryParams';
import styles from './index.module.scss';

interface ResponseData {
    url: Location;
}

const GoogleConnectCallback: FC = () => {
    const params = useQueryParams() as { code: string };
    const { t } = useTranslation();

    const storeToken = async () => {
        const { url } = (await GET(`${BACKEND_URL}/google-apis/auth/callback?code=${params.code}`)) as ResponseData;
        window.location = url;
    };

    useEffect(() => {
        (async function anyNameFunction() {
            await storeToken();
        })();
    }, []);

    return (
        <div className={styles.loaderWrapper}>
            <div className={styles.spinner}>
                <Spinner />
            </div>
            <p className={styles.message}>{t('sessionLoadingMessage')}</p>
        </div>
    );
};

export default GoogleConnectCallback;
