import React, { FC, useMemo } from 'react';
import User from 'core/domain/model/user';
import ConnectedLayoutDesktop from './ConnectedLayout';
import DisconnectedLayoutDesktop from './DisconnectedLayout';

interface DesktopLayoutProps {
    user: User | null;
}

const DesktopLayout: FC<DesktopLayoutProps> = ({ children, user }) => {
    const isConnected = useMemo(() => !!user, [user]);

    const LayoutComponent = isConnected ? ConnectedLayoutDesktop : DisconnectedLayoutDesktop;

    return <LayoutComponent>{children}</LayoutComponent>;
};

export default DesktopLayout;
