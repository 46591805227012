import { TalentManager } from '../../domain/model/collaborator';
import { SetTalentManagerAction } from './setTalentManagerAction';
import TalentManagerActionTypes from './types';

export type TalentManagerState = TalentManager | null;

const init = null;

type Action = SetTalentManagerAction;

const talentManagerReducer = (state: TalentManagerState = init, action: Action): TalentManagerState => {
    switch (action.type) {
        case TalentManagerActionTypes.SET_TALENT_MANAGER:
            return action.payload;
        default:
            return state;
    }
};

export default talentManagerReducer;
