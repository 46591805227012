import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Job from 'core/domain/model/job';
import styles from './index.module.scss';

interface EmptyListProps {
    job: Job;
}

const EmptyList: FC<EmptyListProps> = ({ job }) => {
    const { t } = useTranslation();

    return (
        <p className={styles.emptyList}>
            {t('jobCard.expand.tabs.myNextStep.empty', { companyName: job.company.name })}
        </p>
    );
};

export default EmptyList;
