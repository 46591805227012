import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CloseIcon from 'images/close-cross.svg';
import LogoutIcon from 'images/logout.svg';
import useModal from 'hooks/modal';
import logUserOut from 'core/useCases/session/logOutAction';
import User from 'core/domain/model/user';
import { menuItemSelector, sessionSelector } from 'core/useCases/session/selectors';
import { ABOUT_US_LINK, HELP_FAQ_LINK, RECOMMEND_TALENT_LINK } from 'utils/constants';
import gtmAttributes from 'utils/gtmAttributes';
import logoutFetcher from 'fetchers/logoutFetcher';
import information from 'images/information.svg';
import megaphoneIcon from 'images/icon-megaphone.svg';
import notificationFullIcon from 'images/Notification-full.svg';
import notificationIcon from 'images/Notification.svg';
import FormModal from 'components/layouts/shared/Modal/FormModal';
import toggleNotification from 'core/useCases/session/toggleNotification';
import toggleNotificationFetcher from 'fetchers/toggleNotificationFetcher';
import readAllNotifications from 'core/useCases/session/readAllNotifications';
import readAllNotificationsFetcher from 'fetchers/readAllNotificationsFetcher';
import MyInformationsForm from 'components/form/MyInformationsForm';
import selectMenuItem from 'core/useCases/session/selectMenuItem';
import MyNotificationsForm from 'components/form/MyNotificationsForm';
import InitialsIcon from '../../shared/InitialsIcon';
import PopupDisconnect from '../../shared/PopupDisconnect';
import TalentManagerContact from '../../../TalentManagerContact';
import Notifications from '../Notifications';
// import MyNotifications from '../ConnectedLayout/MyNotifications';
import HeaderElement from '../ConnectedLayout/HeaderElement';
import styles from './index.module.scss';

interface IMenuConnectedMobile {
    title?: string;
    onClose: () => void;
}

const MenuMobile: FC<IMenuConnectedMobile> = ({ title, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector(sessionSelector) as User;

    const { isOpen: submenuIsOpen, closeModal, openModal } = useModal();

    const lougUserOut = () => {
        dispatch(logUserOut(logoutFetcher)());
        closeModal();
    };

    const hasUnreadNotifications = Boolean(user?.talentNotifications?.filter((n) => !n.read)?.length);

    const {
        isOpen: isOpenNotificationsModal,
        openModal: openNotificationsModal,
        closeModal: closeNotificationsModal,
    } = useModal();

    const onToggleNotification = (id: string) => {
        dispatch(toggleNotification(toggleNotificationFetcher)(id));
    };

    const readAll = () => {
        dispatch(readAllNotifications(readAllNotificationsFetcher)(user.id));
    };

    const setSelectedItem = (item: number) => {
        dispatch(selectMenuItem(item));
    };

    const selectedItem = useSelector(menuItemSelector);

    const computeSelected = (index: number) => {
        if (selectedItem === index) {
            return styles.selected;
        }

        return '';
    };

    const {
        isOpen: isOpenMyInformationsModal,
        openModal: openMyInformationsModal,
        closeModal: closeMyInformationsModal,
    } = useModal();

    const {
        isOpen: isOpenMyNotificationsModal,
        openModal: openMyNotificationsModal,
        closeModal: closeMyNotificationsModal,
    } = useModal();

    return (
        <div className={styles.menuWrapper}>
            {title && (
                <div className={styles.menuTitle}>
                    <h2>{title}</h2>
                </div>
            )}
            <button className={styles.closeButton} onClick={onClose} type="button">
                <img alt="X" src={CloseIcon} />
            </button>
            <div className={styles.menuContent}>
                <div>
                    <hr />
                    <div className={classNames(styles.menuElement, styles.userMailWrapper)}>
                        <InitialsIcon user={user} />
                        <div className={styles.userInformation}>
                            <h4>{`${user.firstName} ${user.lastName}`}</h4>
                        </div>
                    </div>
                    <div className={styles.nav}>
                        <Tooltip placement="bottom" title={t('header.menu.notifications') as string} arrow>
                            <div className={styles.navRight}>
                                <button
                                    data-gtm-id="header-menu"
                                    onClick={() => {
                                        openNotificationsModal();
                                    }}
                                    type="button"
                                >
                                    <img
                                        alt="notification"
                                        className={styles.icon}
                                        src={hasUnreadNotifications ? notificationFullIcon : notificationIcon}
                                    />
                                </button>
                            </div>
                        </Tooltip>
                        <Tooltip placement="bottom" title={t('header.help') as string} arrow>
                            <div className={styles.navRight}>
                                <a
                                    href={HELP_FAQ_LINK}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    {...gtmAttributes('header-faq')}
                                >
                                    <img alt="help" className={styles.icon} src={information} />
                                </a>
                            </div>
                        </Tooltip>
                        <Tooltip placement="bottom" title={t('header.recommendation') as string} arrow>
                            <div className={styles.navRight}>
                                <a
                                    href={RECOMMEND_TALENT_LINK}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    {...gtmAttributes('header-reco')}
                                >
                                    <img alt="recommend-talent" className={styles.icon} src={megaphoneIcon} />
                                </a>
                            </div>
                        </Tooltip>
                    </div>
                    <br />
                    <div className={styles.marged}>
                        <Link
                            onClick={() => {
                                setSelectedItem(1);
                                onClose();
                            }}
                            to="/mes-process"
                        >
                            <div className={`${styles.menuElement} ${computeSelected(1)}`}>{t('process')}</div>
                        </Link>

                        <Link
                            onClick={() => {
                                setSelectedItem(2);
                                onClose();
                            }}
                            to="/mon-activite"
                        >
                            <div className={`${styles.menuElement} ${computeSelected(2)}`}>{t('activities')}</div>
                        </Link>
                    </div>
                    <br />
                    <hr />
                    <br />

                    <HeaderElement
                        gtmAttributes={gtmAttributes('header-menu-info')}
                        onClick={() => {
                            openMyInformationsModal();
                        }}
                        textKey="header.menu.myInfo"
                    />
                    <HeaderElement
                        gtmAttributes={gtmAttributes('header-menu-info')}
                        onClick={() => {
                            openMyNotificationsModal();
                        }}
                        textKey="header.mobile.myNotifs"
                    />
                    <HeaderElement
                        gtmAttributes={gtmAttributes('menu-apropos')}
                        link={ABOUT_US_LINK}
                        textKey="whoAreWe"
                    />
                </div>
                <div>
                    <div className={styles.menuElement}>
                        <TalentManagerContact />
                    </div>
                    <button onClick={openModal} type="button">
                        <div className={classNames(styles.menuElement, styles.menuLogout)}>
                            <img alt="Logout" src={LogoutIcon} />
                            <h4>{t('header.menu.logout')}</h4>
                        </div>
                    </button>
                    {submenuIsOpen && <PopupDisconnect onAccept={lougUserOut} onCancel={closeModal} />}
                </div>
                {isOpenNotificationsModal && (
                    <FormModal
                        closeModal={() => {
                            closeNotificationsModal();
                        }}
                        titleKey="notifications.title"
                    >
                        <Notifications
                            hasUnreadNotifications={hasUnreadNotifications}
                            notifications={user.talentNotifications}
                            onToggleNotification={onToggleNotification}
                            readAll={readAll}
                        />
                    </FormModal>
                )}
                {isOpenMyInformationsModal && (
                    <FormModal
                        closeModal={() => {
                            closeMyInformationsModal();
                        }}
                        titleKey="myInformations.title"
                    >
                        <MyInformationsForm
                            onSuccess={() => {
                                closeMyInformationsModal();
                            }}
                        />
                    </FormModal>
                )}
                {isOpenMyNotificationsModal && (
                    <FormModal
                        closeModal={() => {
                            closeMyNotificationsModal();
                        }}
                        titleKey="myNotifications.title"
                    >
                        <MyNotificationsForm
                            onSuccess={() => {
                                closeMyNotificationsModal();
                            }}
                        />
                    </FormModal>
                )}
            </div>
        </div>
    );
};

export default MenuMobile;
