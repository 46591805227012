import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { menuItemSelector } from 'core/useCases/session/selectors';
import HeaderConnected from './HeaderConnected';
import styles from './index.module.scss';

// TODO : Implement side bar and scss file

const ConnectedLayoutMobile: FC = ({ children }) => {
    const selectedItem = useSelector(menuItemSelector);

    const { t } = useTranslation();

    const computeTitle = () => {
        switch (selectedItem) {
            case 1:
                return t('process');

            case 2:
                return t('activities');

            default:
                return '';
        }
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainSection}>
                <HeaderConnected title={computeTitle()} />
                {children}
            </div>
        </div>
    );
};

export default ConnectedLayoutMobile;
