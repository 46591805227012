import React, { FC } from 'react';
import Job from 'core/domain/model/job';
import useTabs from 'hooks/tabs';
import Tabs from './Tabs';

interface ComponentProps {
    job: Job;
}

const ContentSection: FC<ComponentProps> = ({ job }) => {
    const { tabs, activeTabIndex, ActiveTabComponent } = useTabs(job);

    return (
        <>
            <Tabs activeTabIndex={activeTabIndex} job={job} tabs={tabs} />
            {ActiveTabComponent && <ActiveTabComponent job={job} />}
        </>
    );
};

export default ContentSection;
