import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from 'images/close-cross.svg';
import useClickOutside from 'hooks/useClickOutside';
import MenuContent, { IUser } from '../../shared/MenuContent';
import styles from './index.module.scss';

interface MenuProps {
    onClose: () => void;
    onDisconnect: () => void;
    user: IUser;
}

const MenuDesktop: FC<MenuProps> = ({ onClose, onDisconnect, user }) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement | null>(null);
    const [submenuIsOpen, setSubmenuIsOpen] = useState(false);

    const onModalChange = () => {
        setSubmenuIsOpen(!submenuIsOpen);
    };

    useClickOutside({ ref, submenuIsOpen, callback: onClose });

    return (
        <div ref={ref} className={styles.menuWrapper}>
            <div className={styles.menuTitle}>
                <h2>{t('header.menu.title')}</h2>
                <button onClick={onClose} type="button">
                    <img alt="X" src={CloseIcon} />
                </button>
            </div>
            <hr />
            <MenuContent onDisconnect={onDisconnect} onModalChange={onModalChange} user={user} />
        </div>
    );
};

export default MenuDesktop;
