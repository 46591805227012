import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HeaderConnected from 'components/layouts/desktop/ConnectedLayout/HeaderConnected';
import { menuItemSelector } from 'core/useCases/session/selectors';
import selectMenuItem from 'core/useCases/session/selectMenuItem';
import AvizioLogo from '../../../../images/logo-avizio-main-white.svg';
import { AVIZIO_HOME_LINK } from '../../../../utils/constants';
import TalentManagerContact from '../../../TalentManagerContact';
import styles from './index.module.scss';
import RefreshKanban from './HeaderConnected/RefreshKanban';
import RefreshEvents from './HeaderConnected/RefreshEvents';

const ConnectedLayoutDesktop: FC = ({ children }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const setSelectedItem = (item: number) => {
        dispatch(selectMenuItem(item));
    };

    const selectedItem = useSelector(menuItemSelector);

    const [overItem, setOverItem] = useState(0);

    const computeSelected = (index: number) => {
        if (overItem === index) {
            return styles.selected;
        }
        if (overItem !== 0 && overItem !== index) {
            return '';
        }
        if (selectedItem === index) {
            return styles.selected;
        }

        return '';
    };

    const computeTitle = () => {
        switch (selectedItem) {
            case 1:
                return t('process');

            case 2:
                return t('activities');

            default:
                return '';
        }
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.sideBar}>
                <div className={styles.secondaryContainer}>
                    <a href={AVIZIO_HOME_LINK} rel="noreferrer noopener" target="_blank">
                        <img alt="Logo Avizio" src={AvizioLogo} />
                    </a>
                    <Link
                        onClick={() => {
                            setSelectedItem(1);
                        }}
                        to="/mes-process"
                    >
                        <div
                            className={`${styles.tab} ${computeSelected(1)}`}
                            onBlur={() => setOverItem(0)}
                            onFocus={() => setOverItem(1)}
                            onMouseOut={() => setOverItem(0)}
                            onMouseOver={() => setOverItem(1)}
                        >
                            <span>{t('process')}</span>
                        </div>
                    </Link>
                    <Link
                        onClick={() => {
                            setSelectedItem(2);
                        }}
                        to="/mon-activite"
                    >
                        <div
                            className={`${styles.tab} ${computeSelected(2)}`}
                            onBlur={() => setOverItem(0)}
                            onFocus={() => setOverItem(2)}
                            onMouseOut={() => setOverItem(0)}
                            onMouseOver={() => setOverItem(2)}
                        >
                            <span>{t('activities')}</span>
                        </div>
                    </Link>
                </div>
                <TalentManagerContact />
            </div>

            <div className={styles.mainSection}>
                <HeaderConnected
                    additionalFeature={selectedItem === 1 ? () => <RefreshKanban /> : () => <RefreshEvents />}
                    title={computeTitle()}
                />
                {children}
            </div>
        </div>
    );
};
export default ConnectedLayoutDesktop;
