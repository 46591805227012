import TalentNotification from 'core/domain/model/talentNotification';
import { ThunkAction } from 'core/store';
import SessionActionTypes from './types';

export interface SetReadAction {
    type: SessionActionTypes.SET_READ_NOTIFICATION;
    payload: TalentNotification;
}

export const setReadNotification = (talentNotification: TalentNotification): SetReadAction => ({
    type: SessionActionTypes.SET_READ_NOTIFICATION,
    payload: talentNotification,
});

export type ToggleNotificationFetcher = (id: string) => Promise<TalentNotification>;

const toggleNotification = (toggleNotificationFetcher: ToggleNotificationFetcher) => (
    id: string,
): ThunkAction => async (dispatch) => {
    const talentNotification = await toggleNotificationFetcher(id);
    dispatch(setReadNotification(talentNotification));
};

export default toggleNotification;
