import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Job from 'core/domain/model/job';
import {
    createNextStep as createNextStepAction,
    NextStepData,
    updateNextStep as updateNextStepAction,
} from 'core/useCases/nextSteps/setNextStepAction';
import deleteNextStepAction from 'core/useCases/nextSteps/deleteNextStepAction';
import iconCross from 'images/close-cross-white.svg';
import { activeJobNextStepsSelector } from 'core/useCases/jobs/selectors';
import getNextStepLabelOptionsAction from 'core/useCases/session/getNextStepLabelOptions';
import gtmAttributes from 'utils/gtmAttributes';
import createNextStepFetcher, {
    deleteNextStepFetcher,
    getNextStepLabelOptionsFetcher,
    updateNextStepFetcher,
} from 'fetchers/nextStepsFetchers';
import TabElement from '../TabElement';
import useCurrentWidth from '../../../../../../hooks/width';
import styles from './index.module.scss';
import NextStepForm from './NextStepForm';

const useNextSteps = (jobCrmId: string) => {
    const dispatch = useDispatch();

    const nextSteps = useSelector(activeJobNextStepsSelector(jobCrmId));

    const createNextStep = () => dispatch(createNextStepAction(createNextStepFetcher)(jobCrmId));

    const updateNextStep = (nextStepId: string, nextStepData: NextStepData) =>
        dispatch(updateNextStepAction(updateNextStepFetcher)(jobCrmId, nextStepId, nextStepData));

    const deleteNextStep = (nextStepId: string) =>
        dispatch(deleteNextStepAction(deleteNextStepFetcher)(jobCrmId, nextStepId));

    const getNextStepCustomLabelOptions = () =>
        dispatch(getNextStepLabelOptionsAction(getNextStepLabelOptionsFetcher)());

    useEffect(() => {
        getNextStepCustomLabelOptions();
    }, []);

    return { nextSteps, createNextStep, updateNextStep, deleteNextStep, getNextStepCustomLabelOptions };
};

interface MyNextStepProps {
    job: Job;
}

const MyNextStep: FC<MyNextStepProps> = ({ job }) => {
    const { t } = useTranslation();
    const [, isMobile] = useCurrentWidth();

    const { nextSteps, createNextStep, updateNextStep, deleteNextStep } = useNextSteps(job.crmId);
    const quantity = nextSteps.filter((nextStep) => !nextStep.done).length;

    return (
        <TabElement>
            <div className={styles.contentWrapper}>
                <div className={styles.myNextStepHeader}>
                    <h2>{t('jobCard.expand.tabs.myNextStep.subtitle', { quantity })}</h2>
                    <button onClick={createNextStep} type="button" {...gtmAttributes('nextstep-addnew')}>
                        <img alt="Icon Plus" src={iconCross} />
                        {t(
                            isMobile
                                ? 'jobCard.expand.tabs.myNextStep.buttonAddMobile'
                                : 'jobCard.expand.tabs.myNextStep.buttonAdd',
                        )}
                    </button>
                </div>
                <hr />
                <NextStepForm
                    create={createNextStep}
                    initialNextSteps={nextSteps}
                    job={job}
                    remove={deleteNextStep}
                    update={updateNextStep}
                />
            </div>
        </TabElement>
    );
};

export default MyNextStep;
