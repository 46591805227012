import TalentEvent from 'core/domain/model/event';
import LoadingState from '../types';
import { SetErrorAction } from './setError';
import { SetLoadingAction } from './setLoading';
import { SetEventsAction } from './setEventsAction';
import EventActionTypes, { EventsErrorType } from './types';

export interface EventState {
    list: TalentEvent[];
    loading: LoadingState;
    error: EventsErrorType | null;
}

const init = {
    list: [],
    loading: LoadingState.NOT_LOADED,
    error: null,
};

type Action = SetEventsAction | SetLoadingAction | SetErrorAction;

const eventReducer = (state: EventState = init, action: Action): EventState => {
    switch (action.type) {
        case EventActionTypes.SET_EVENTS:
            return {
                ...state,
                list: action.payload,
            };
        case EventActionTypes.SET_EVENTS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EventActionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default eventReducer;
