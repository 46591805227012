/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import validateCredentials from 'core/useCases/session/validateCredentials';
import SignupForm from 'components/form/SignupForm';
import { userDataSelector } from 'core/useCases/session/selectors';
import credentialFetcher from 'fetchers/credentialFetcher';
import useQueryParams from '../utils/queryParams';

const useValidateCredentials = () => {
    const dispatch = useDispatch();
    const userData = useSelector(userDataSelector);

    const params = useQueryParams() as { id?: string; token?: string };

    useEffect(() => {
        dispatch(validateCredentials(credentialFetcher)(params.id, params.token));
    }, []);

    return userData;
};

const SignupPage: FC = () => {
    const { t } = useTranslation();
    const userData = useValidateCredentials();

    return (
        <>
            <h2>{t('signup.title', { userFirstName: userData?.firstName })}</h2>
            <h3 dangerouslySetInnerHTML={{ __html: t('signup.subtitle', { userEmail: userData?.email }) }} />
            {userData && <SignupForm userData={userData} />}
        </>
    );
};

export default SignupPage;
