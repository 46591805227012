import { extractLangFromIso639, isAValidLanguage } from './lang';

const getBrowserLanguage = (): string => {
    const userLang = navigator.language;
    const lang = extractLangFromIso639(userLang);

    return isAValidLanguage(lang) ? lang : 'fr';
};

export default getBrowserLanguage;
