import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Google from 'images/btn_google_signin_dark_normal_web.png';
import GoogleFocus from 'images/btn_google_signin_dark_focus_web.png';
import GooglePressed from 'images/btn_google_signin_dark_pressed_web.png';
import BACKEND_URL from 'utils/backendUrl';
import { GET } from 'utils/http';
import styles from './index.module.scss';

interface ResponseData {
    url: Location;
}

const ConnectCalendar: FC = () => {
    const { t } = useTranslation();
    const [pressed, setPressed] = useState(false);

    const redirectToGoogleConnect = async () => {
        setPressed(true);
        const { url } = (await GET(`${BACKEND_URL}/google-apis/auth/get-url?redirect=/mon-activite`)) as ResponseData;
        window.location = url;
    };

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <h1 className={styles.title}>{t('calendarConnect.title')}</h1>

                <p>{t('calendarConnect.chooseCalendar')}</p>
                <br />
                <div className={styles.flex}>
                    <button onClick={() => redirectToGoogleConnect()} type="button">
                        <img
                            alt="google-signin"
                            onBlur={(e) => {
                                e.currentTarget.src = Google;
                            }}
                            onFocus={(e) => {
                                e.currentTarget.src = GoogleFocus;
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.src = Google;
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.src = GoogleFocus;
                            }}
                            src={pressed ? GooglePressed : Google}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConnectCalendar;
