import { ThunkAction } from 'core/store';
import { DeleteEventsFetcher } from 'fetchers/deleteCustomEventFetcher';
import eventsFetcher from 'fetchers/eventsFetcher';
import LoadingState from '../types';
import setError from './setError';
import { setEvents } from './setEventsAction';
import setLoading from './setLoading';

const deleteCustomEventAction = (
    deleteEventsFetcher: DeleteEventsFetcher,
    userId: string,
    eventId: string,
    isMock?: boolean,
) => (): ThunkAction => async (dispatch, getState, { backendUrl }) => {
    dispatch(setLoading(LoadingState.LOADING));
    try {
        await deleteEventsFetcher(userId, eventId);
        const events = await eventsFetcher(backendUrl, isMock);

        dispatch(setError(null));
        dispatch(setEvents(events));
    } catch (error) {
        dispatch(setError(error.message));
    } finally {
        dispatch(setLoading(LoadingState.LOADED));
    }
};

export default deleteCustomEventAction;
