import React, { createContext, ReactNode, useState } from 'react';
import getBrowserLanguage from '../utils/getBrowserLanguage';
import { getAllLangs } from '../utils/lang';

type ContextProps = {
    language: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
    allowedLanguages: string[];
};

const languageContext = createContext<ContextProps | null>(null);

export const Provider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [language, setLanguage] = useState(getBrowserLanguage());

    const allowedLanguages = getAllLangs().map(({ keyLang }) => keyLang);

    return (
        <languageContext.Provider value={{ language, setLanguage, allowedLanguages }}>
            {children}
        </languageContext.Provider>
    );
};

export default languageContext;
