import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Router as BaseRouter, Switch, Route, Redirect, useHistory } from 'react-router';
import { sessionLoadingStateSelector, sessionSelector } from 'core/useCases/session/selectors';
import h from 'utils/history';
import BaseLayout from 'components/layouts/BaseLayout';
import LoadingState from 'core/useCases/types';
import useQueryParams from 'utils/queryParams';
import { GET } from 'utils/http';
import BACKEND_URL from 'utils/backendUrl';
import GoogleConnectCallback from 'components/GoogleConnectCallback';
import ProcessPage from './Process';
import LoginPage from './Login';
import SignupPage from './Signup';
import ResetPasswordPage from './ResetPassword/index';
import NewPasswordPage from './ResetPassword/newPassword';
import ActivityPage from './Activity';

const ConnectedRouter: FC = () => (
    <Switch>
        <Route path={['/mes-process', '/mes-process/jobs/:id/:tab', '/jobs/:id/:tab']} exact>
            <ProcessPage />
        </Route>
        <Route path={['/mon-activite', '/mon-activite/:error']} exact>
            <ActivityPage />
        </Route>
        <Route path={['/google-callback']} exact>
            <GoogleConnectCallback />
        </Route>
        <Redirect to="/mes-process" />
    </Switch>
);

interface ResponseData {
    id: string;
    token: string;
    isActivated: boolean;
}

const getIsActivatedState = async (email: string) => {
    try {
        const response = (await GET(`${BACKEND_URL}/users/${encodeURIComponent(email)}/is-activated`)) as ResponseData;

        return response;
    } catch (error) {
        return error;
    }
};

const DisconnectedRouter: FC = () => {
    const params = useQueryParams() as { email: string };
    const history = useHistory();

    useEffect(() => {
        if (!params.email) {
            return;
        }
        async function fetchActivatedState() {
            const response = await getIsActivatedState(params.email);

            if (response.id) {
                history.push(`/signup?id=${response.id}&token=${response.token}`);
            }
        }

        fetchActivatedState();
    }, [params.email]);

    return (
        <Switch>
            <Route path="/login" exact>
                <LoginPage />
            </Route>
            <Route path="/signup" exact>
                <SignupPage />
            </Route>
            <Route path="/reset-password" exact>
                <ResetPasswordPage />
            </Route>
            <Route path="/new-password/" exact>
                <NewPasswordPage />
            </Route>
            <Redirect to="/login" />
        </Switch>
    );
};

interface RoutesProps {
    loading: boolean;
    userIsConnected: boolean;
}

const Routes: FC<RoutesProps> = ({ loading, userIsConnected }) => {
    if (loading) {
        return null;
    }

    return userIsConnected ? <ConnectedRouter /> : <DisconnectedRouter />;
};

const Router: FC = () => {
    const connectedUser = useSelector(sessionSelector);
    const loadingState = useSelector(sessionLoadingStateSelector);

    const isLoading = loadingState !== LoadingState.LOADED;

    return (
        <BaseRouter history={h}>
            <BaseLayout>
                <Routes loading={isLoading} userIsConnected={!!connectedUser} />
            </BaseLayout>
        </BaseRouter>
    );
};

export default Router;
