import classNames from 'classnames';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail, validatePassword } from 'core/domain/behavior/validateLogin';
import logUserIn from 'core/useCases/session/logInAction';
import { loginErrorSelector } from 'core/useCases/session/selectors';
import loginFetcher from 'fetchers/loginFetcher';
import sessionFetcher from 'fetchers/sessionFetcher';
import { TextInput } from '../lib/index';
import ErrorText from '../lib/ErrorMessage/ErrorText';
import styles from './index.module.scss';

interface FormValues {
    email: string;
    password: string;
}

const LoginForm: FC = () => {
    const { t } = useTranslation();
    const loginError = useSelector(loginErrorSelector);
    const dispatch = useDispatch();

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const { handleSubmit, formState } = methods;

    const { isValid } = formState;

    const onSubmit = handleSubmit((values) => {
        const { email, password } = values;

        dispatch(logUserIn(loginFetcher, sessionFetcher)(email, password));
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                {loginError && <ErrorText text={`login.errorMessages.${loginError}`} />}
                <div className={styles.formContainer}>
                    <TextInput
                        errorMessage="login.errorMessages.text"
                        label="login.email.label"
                        name="email"
                        placeholder="login.email.placeholder"
                        type="email"
                        validate={validateEmail}
                    />
                    <TextInput
                        errorMessage="login.errorMessages.text"
                        label="login.password.label"
                        name="password"
                        placeholder="login.password.placeholder"
                        type="password"
                        validate={validatePassword}
                    />
                    <div className={styles.formOptions}>
                        <a className="pointer" href="/reset-password">
                            {t('login.resetPassword')}
                        </a>
                    </div>
                </div>
                <div className={classNames(styles.formActions)}>
                    <button className={styles.button} disabled={!isValid} type="submit">
                        {t('login.submit')}
                    </button>
                </div>
            </form>
        </FormProvider>
    );
};

export default LoginForm;
