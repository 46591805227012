export enum NextStepDefaultLabel {
    MAKE_FOLLOW_UP = 'makeFollowUp',
    SEND_MY_AVAILABILITY = 'sendMyAvailability',
    PREPARE_APPOINTMENT = 'prepareAppointment',
    AWAITING_FEEDBACK = 'awaitingFeedback',
    SEND_MY_DEBRIEFING = 'sendMyDebriefing',
    UPCOMING_CALL = 'upcomingCall',
    UPCOMING_IRL_APPOINTMENT = 'upcomingIRLAppointment',
    PREPARE_BUSINESS_CASE = 'prepareBusinessCase',
    CALL_COMPANY_BACK = 'callCompanyBack',
    CALL_AVIZIO_BACK = 'callAvizioBack',
    SEND_REFERENCES = 'sendReferences',
    POSITION_ON_JOB = 'positionOnJob',
}

export interface NextStepCustomLabel {
    id: string;
    value: string;
}

interface NextStep {
    id: string;
    defaultLabel: NextStepDefaultLabel | null;
    customLabel: NextStepCustomLabel | null;
    jobCrmId: string;
    dueDate: string;
    done: boolean;
}

interface Label {
    default: boolean;
    value: string;
}

export const getLabel = (nextStep: NextStep | undefined): Label | null => {
    if (!nextStep || (!nextStep.defaultLabel && !nextStep.customLabel)) {
        return null;
    }

    if (nextStep.defaultLabel) {
        return { default: true, value: nextStep.defaultLabel };
    }

    return { default: false, value: (nextStep.customLabel as NextStepCustomLabel).value };
};

export default NextStep;
