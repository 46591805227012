import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import listUserEventsAction from 'core/useCases/events/setEventsAction';
import refreshIcon from 'images/icon-refresh.svg';
import { jobsAreLoadingSelector } from 'core/useCases/jobs/selectors';
import eventsFetcher from 'fetchers/eventsFetcher';
import styles from './index.module.scss';

const RefreshEvents: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loading = useSelector(jobsAreLoadingSelector);
    const [refreshDateTime, setRefreshDateTime] = useState(new Date());

    const reloadJobs = () => {
        dispatch(listUserEventsAction(eventsFetcher)());
        setRefreshDateTime(new Date());
    };

    return (
        <div className={styles.refreshWrapper}>
            <button className={styles.refresh} onClick={reloadJobs} type="button">
                <img alt={t('header.refreshKanban.refreshAlt')} src={refreshIcon} />
                <p>{loading ? '...' : t('header.refreshKanban.refreshDateTime', { dateTime: refreshDateTime })}</p>
            </button>
        </div>
    );
};

export default RefreshEvents;
