import LoadingState from '../types';
import JobActionTypes from './types';

export interface SetLoadingAction {
    type: JobActionTypes.SET_JOBS_LOADING;
    payload: LoadingState;
}

const setLoading = (loading: LoadingState): SetLoadingAction => ({
    type: JobActionTypes.SET_JOBS_LOADING,
    payload: loading,
});

export default setLoading;
