import User from 'core/domain/model/user';
import { UserNewPasswordFetcher } from 'core/useCases/session/newPassword';
import { UpdateUserPasswordErrorType } from 'core/useCases/session/types';
import BACKEND_URL from 'utils/backendUrl';
import { POST } from 'utils/http';

interface ResponseData {
    user: User;
}

const userNewPasswordFetcher: UserNewPasswordFetcher = async (id, token, password, confirmPassword) => {
    try {
        const response = (await POST<ResponseData>(`${BACKEND_URL}/users/${id}/new-password`, {
            token,
            password,
            confirmPassword,
        })) as ResponseData;

        return response.user;
    } catch (error) {
        if (error.type === 'InvalidPasswordTokenError') {
            throw new Error(UpdateUserPasswordErrorType.TOKEN_ERROR);
        }

        throw new Error(UpdateUserPasswordErrorType.UNKNOWN_ERROR);
    }
};

export default userNewPasswordFetcher;
