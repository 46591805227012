import { mockedJobs } from 'components/Kanban';
import Job from 'core/domain/model/job';
import { JobsFetcher } from 'core/useCases/jobs/setJobsAction';
import { JobsErrorType } from 'core/useCases/jobs/types';
import { GET } from 'utils/http';

interface GetJobsResponse {
    jobs: Job[];
}

const jobsFetcher: JobsFetcher = async (backendUrl: string, isMock?: boolean) => {
    if (isMock) return mockedJobs;
    try {
        const response = (await GET<GetJobsResponse>(`${backendUrl}/jobs`)) as GetJobsResponse;

        return response.jobs;
    } catch (error) {
        throw new Error(JobsErrorType.RETRIVAL_ERROR);
    }
};

export default jobsFetcher;
