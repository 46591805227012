import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface OptionProps {
    label: string;
}

const Option: FC<OptionProps> = ({ label }) => {
    const { t } = useTranslation();

    return (
        <p className={styles.option}>
            <span className={styles.bullet}>&bull;</span>
            {t(label)}
        </p>
    );
};

export default Option;
