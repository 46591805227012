import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './index.module.scss';

export enum EmptyColumnVariant {
    BASE = 'base',
    OUTLINED = 'outlined',
}

interface EmptyColumnProps {
    placeholder: string;
    variant: EmptyColumnVariant;
}

const EmptyColumn: FC<EmptyColumnProps> = ({ placeholder, variant }) => {
    const { t } = useTranslation();
    const base = variant === EmptyColumnVariant.BASE;
    const outlined = variant === EmptyColumnVariant.OUTLINED;

    return (
        <div
            className={classNames(styles.emptyColumnWrapper, {
                [styles.base]: base,
                [styles.outlined]: outlined,
            })}
        >
            <h4>{t(placeholder)}</h4>
        </div>
    );
};

export default EmptyColumn;
