import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleModal from 'components/layouts/shared/Modal/SimpleModal';
import Spinner from 'components/layouts/shared/Spinner';

interface ConfirmationModalProps {
    titleKey: string;
    titleData?: Record<string, unknown>;
    submitValues(): void;
    cancel(): void;
    loading: boolean;
    warning?: string;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
    submitValues,
    cancel,
    loading,
    titleKey,
    titleData,
    warning,
}) => {
    const { t } = useTranslation();

    return (
        <SimpleModal>
            {loading ? (
                <>
                    <div className="spinnerWrapper">
                        <Spinner />
                    </div>
                    <p className="messageWrapper">{t('popup.loading')}</p>
                </>
            ) : (
                <>
                    {warning ? <h2 className="warning">{t(warning)}</h2> : undefined}
                    <h2>{t(titleKey, titleData)}</h2>
                    <div className="popupButtonsWrapper">
                        <button onClick={cancel} type="button">
                            {t('myInformations.confirmation.cancel')}
                        </button>
                        <button onClick={submitValues} type="button">
                            {t('myInformations.confirmation.confirm')}
                        </button>
                    </div>
                </>
            )}
        </SimpleModal>
    );
};

export default ConfirmationModal;
