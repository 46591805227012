/* eslint-disable import/no-duplicates */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import frLocale from 'date-fns/locale/fr';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatDate from 'date-fns/format';
import formatDistance from 'utils/formatDistance';
import langFr from './translations/fr.json';

const FR = 'fr';

const dateFnsLocales: Record<string, Locale> = {
    [FR]: {
        ...frLocale,
        formatDistance,
    },
};

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: FR,
        resources: {
            fr: { translation: langFr },
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: (value, format, language) => {
                switch (format) {
                    case 'dateAgo':
                        return formatDistanceToNow(new Date(value), {
                            locale: language ? dateFnsLocales[language] : undefined,
                        });
                    case 'displayDate':
                        return formatDate(new Date(value), 'dd/MM/yyyy');

                    case 'displayTime':
                        return formatDate(new Date(value), 'HH:mm');

                    case 'displayFullDateTime':
                        return formatDate(new Date(value), 'Pp', {
                            locale: language ? dateFnsLocales[language] : undefined,
                        });
                    default:
                        return value;
                }
            },
        },
    });

export default i18n;
