import React, { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import userNewPasswordFetcher from 'fetchers/userNewPasswordFetcher';
import { updateUserPasswordErrorSelector } from '../../../core/useCases/session/selectors';
import ErrorText from '../lib/ErrorMessage/ErrorText';
import styles from '../SignupForm/index.module.scss';
import { TextInput } from '../lib';
import { validatePassordLength, validatePasswordConfirmation } from '../../../core/domain/behavior/validateSignup';
import updateUserNewPassword from '../../../core/useCases/session/newPassword';

interface FormValues {
    password: string;
    confirmPassword: string;
}

interface NewPasswordFormProps {
    userData: {
        id: string;
        token: string;
    };
}

const NewPasswordForm: FC<NewPasswordFormProps> = ({ userData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const newPasswordError = useSelector(updateUserPasswordErrorSelector);

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            password: '',
            confirmPassword: '',
        },
    });

    const { handleSubmit, getValues, formState } = methods;

    const { isValid } = formState;

    const onSubmit = handleSubmit(async ({ password, confirmPassword }) => {
        dispatch(updateUserNewPassword(userNewPasswordFetcher)(userData.id, userData.token, password, confirmPassword));
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                {newPasswordError && <ErrorText text={t(`newPassword.errorMessages.${newPasswordError}`)} />}
                <div className={styles.formContainer}>
                    <TextInput
                        errorMessage="newPassword.errorMessages.length"
                        label="newPassword.password.label"
                        name="password"
                        placeholder="newPassword.password.placeholder"
                        type="password"
                        validate={validatePassordLength}
                    />
                    <TextInput
                        errorMessage="newPassword.errorMessages.verification"
                        label="newPassword.verification.label"
                        name="verification"
                        placeholder="newPassword.verification.placeholder"
                        type="password"
                        validate={(value) => validatePasswordConfirmation(getValues('password'), value)}
                    />
                </div>
                <div className={classNames(styles.formActions)}>
                    <button className={styles.button} disabled={!isValid} type="submit">
                        {t('newPassword.submit')}
                    </button>
                </div>
            </form>
        </FormProvider>
    );
};

export default NewPasswordForm;
