import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CompanyLogo from 'components/CompanyLogo';
import Job from 'core/domain/model/job';
import closeIcon from 'images/close-cross-white.svg';
import updateIcon from 'images/update-white.svg';
import { nextStepLabelSelector } from 'core/useCases/jobs/selectors';
import CompanyNameAndLinks from '../CompanyNameAndLinks';
import styles from './index.module.scss';

interface HeaderSection {
    job: Job;
    onClose: () => void;
}

const HeaderSection: FC<HeaderSection> = ({ job, onClose }) => {
    const { t } = useTranslation();
    const badgeLabel = useSelector(nextStepLabelSelector(job.crmId));

    return (
        <div className={styles.jobCardExpandTopWrapper}>
            <button className={styles.closeIcon} onClick={onClose} type="button">
                <img alt={t('jobCard.expand.closeAlt')} className={styles.closeIcon} src={closeIcon} />
            </button>
            <div className={styles.jobCardExpandTopContentWrapper}>
                <div className={styles.jobCardExpandTopLogoColumn}>
                    <div className={styles.jobCardExpandTopLogo}>
                        <CompanyLogo job={job} />
                    </div>
                </div>
                <div className={styles.jobCardExpandTopContent}>
                    <h1>{job.name}</h1>
                    <CompanyNameAndLinks company={job.company} />
                    {badgeLabel && <div className={styles.jobCardExpandBadge}>{badgeLabel}</div>}
                    <div className={styles.jobUpdate}>
                        <img alt="update icon" src={updateIcon} />
                        <p>{t('jobCard.updatedAt', { updatedAt: job.updatedAt })}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderSection;
