import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import talentManagerFetcher from 'fetchers/talentManagerFetcher';
import { TalentManager } from '../core/domain/model/collaborator';
import setTalentManager from '../core/useCases/talentManager/setTalentManagerAction';

export interface ITalentManagerContact {
    talentManager: TalentManager | null;
}

const useTalentManager = (): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTalentManager(talentManagerFetcher)());
    }, []);
};

export default useTalentManager;
