import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationCard from 'components/NotificationCard';
import TalentNotification from 'core/domain/model/talentNotification';
import styles from './index.module.scss';

interface NotificationsProps {
    onToggleNotification: (id: string) => void;
    notifications: TalentNotification[];
    readAll: () => void;
    hasUnreadNotifications: boolean;
}

const Notifications: FC<NotificationsProps> = ({
    onToggleNotification,
    readAll,
    notifications,
    hasUnreadNotifications,
}) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement | null>(null);

    return (
        <div ref={ref} className={styles.menuWrapper}>
            <div className={styles.rightAlign}>
                <button disabled={!hasUnreadNotifications} onClick={readAll} type="button">
                    <span className={`${styles.readAll} ${hasUnreadNotifications ? styles.activated : ''}`}>
                        {t('header.notifications.readAll')}
                    </span>
                </button>
            </div>
            <div className={styles.scrollable}>
                {notifications &&
                    notifications.map((n) => (
                        <NotificationCard notification={n} onToggleNotification={onToggleNotification} />
                    ))}
            </div>
            <hr />
        </div>
    );
};

export default Notifications;
