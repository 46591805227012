import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { sessionIsLoadingSelector, sessionSelector } from 'core/useCases/session/selectors';
import useCurrentWidth from 'hooks/width';
import DesktopLayout from './desktop/DesktopLayout';
import MobileLayout from './mobile/MobileLayout';
import SessionLoadingScreen from './shared/SessionLoadingScreen';

const BaseLayout: FC = ({ children }) => {
    const connectedUser = useSelector(sessionSelector);
    const sessionIsLoading = useSelector(sessionIsLoadingSelector);
    const [, isMobile] = useCurrentWidth();

    const LayoutComponent = isMobile ? MobileLayout : DesktopLayout;

    return (
        <>
            <div id="modal" />
            {sessionIsLoading ? (
                <SessionLoadingScreen />
            ) : (
                <LayoutComponent user={connectedUser}>{children}</LayoutComponent>
            )}
        </>
    );
};

export default BaseLayout;
