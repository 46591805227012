import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import talentManagerSelector from 'core/useCases/talentManager/selectors';
import mailIcon from 'images/icon-mail.svg';
import Job, { JobStage } from 'core/domain/model/job';
import gtmAttributes from 'utils/gtmAttributes';
import { AVIZIO_HOME_LINK } from 'utils/constants';
import TabElement from '../TabElement';
import ProcessMessage from './ProcessMessage';
import styles from './index.module.scss';
import InterestButton from './InterestButton';

interface ProcessProps {
    job: Job;
}

export type ProcessCompType = FC<ProcessProps>;

const Process: ProcessCompType = ({ job }) => {
    const { t } = useTranslation();

    const talentManager = useSelector(talentManagerSelector);

    return (
        <TabElement>
            <div className={styles.jobCardExpandBottomContentWrapper}>
                <ProcessMessage job={job} talentManagerName={talentManager?.firstName} />
                <div className={styles.jobCardExpandBottomButtons}>
                    {job.stage === JobStage.SUGGESTION && <InterestButton interest={false} job={job} />}
                    {job.stage === JobStage.SUGGESTION ? (
                        <InterestButton job={job} interest />
                    ) : (
                        <>
                            <div>
                                <a
                                    href={talentManager?.calendarLink || AVIZIO_HOME_LINK}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    {...gtmAttributes('talenblock-rdv')}
                                >
                                    <div className={styles.bookButton}>
                                        {t('talentManagerContact.book', { talentManager: talentManager?.firstName })}
                                    </div>
                                </a>
                            </div>
                            <div>
                                <a href={`mailto:${talentManager?.email}`} rel="noreferrer noopener" target="_blank">
                                    <img alt="comment" src={mailIcon} />
                                    {t('jobCard.expand.tabs.process.content.sendMessage', {
                                        talentManager: talentManager?.firstName,
                                    })}
                                </a>
                            </div>
                        </>
                    )}
                </div>
                <p className={styles.jobCardExpandBottomContentPhone}>
                    {job.stage === JobStage.SUGGESTION ? (
                        <>
                            {t('jobCard.expand.tabs.process.content.informations.part1')}
                            <a
                                className={styles.jobCardExpandBottomContentContact}
                                href={talentManager?.calendarLink || AVIZIO_HOME_LINK}
                                rel="noreferrer noopener"
                                target="_blank"
                                {...gtmAttributes('expand-ctardv')}
                            >
                                {t('jobCard.expand.tabs.process.content.informations.part2', {
                                    talentManager: talentManager?.firstName,
                                })}
                            </a>
                        </>
                    ) : undefined}

                    {t('jobCard.expand.tabs.process.content.informations.part3')}
                    <a
                        className={styles.jobCardExpandBottomContentContact}
                        href={`tel:${talentManager?.contactNumber}`}
                        rel="noreferrer noopener"
                        target="_blank"
                        {...gtmAttributes('expand-ctatel')}
                    >
                        {talentManager?.contactNumber}
                    </a>
                </p>
            </div>
        </TabElement>
    );
};

export default Process;
