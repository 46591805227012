export const validateEmail = (email: string): boolean => {
    return !!email.trim();
};

export const validatePassword = (password: string): boolean => {
    return !!password.trim();
};

const validateLogin = (email: string, password: string): boolean => {
    return validateEmail(email) && validatePassword(password);
};

export default validateLogin;
