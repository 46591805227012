import React, { FC, useState } from 'react';
import { isToday, isTomorrow, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Theme, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import TalentEvent from 'core/domain/model/event';
import UserIcon from 'images/user.svg';
import LocationIcon from 'images/location.svg';
import OpenLink from 'images/open.svg';
import Calendar from 'images/calendar.svg';
import Clock from 'images/clock2.svg';
import Edit from 'images/edit.svg';
import useCurrentWidth from 'hooks/width';
import CompanyLogo from 'components/CompanyLogo';
import AvizioGold from 'images/avizio-gold.svg';
import talentManagerSelector from 'core/useCases/talentManager/selectors';
import styles from './index.module.scss';

interface EventCardProps {
    event: TalentEvent;
    onClickEdit: (event: TalentEvent) => void;
}

const EventCardDesktop: FC<EventCardProps> = ({ event, onClickEdit }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const location =
        event.location || event.conferenceData?.entryPoints?.find((cd) => cd.entryPointType === 'video')?.label || '';
    const onClick = () => {
        if (!history) {
            return;
        }
        if (event.job && event.job.id !== 'avizio') history.push(`/mes-process/jobs/${event.job.crmId}/process`);
    };

    const talentManager = useSelector(talentManagerSelector);

    const followLocation = () => {
        const uri = event.location
            ? `https://www.google.fr/maps/place/${event.location}`
            : event.conferenceData?.entryPoints?.find((cd) => cd.entryPointType === 'video')?.uri || '';
        window.open(uri, '_blank');
    };

    const openEvent = () => {
        window.open(event.htmlLink, '_blank');
    };

    const eventIsToday = isToday(parseISO(event.start.dateTime));
    const eventIsTomorrow = isTomorrow(parseISO(event.start.dateTime));

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 14,
        },
    }))(Tooltip);

    const [open, setOpen] = useState(false);
    const [to, setTo] = useState<ReturnType<typeof setTimeout> | null>(null);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
        if (to) {
            clearTimeout(to);
            setTo(null);
        }
        setTo(
            setTimeout(() => {
                setOpen(false);
            }, 3000),
        );
    };

    const computeAttendeesText = () => {
        return (
            <div className={styles.grey}>
                {event?.attendees?.map((a) => (
                    <>
                        <span>{a.responseStatus === 'declined' ? '' : `${a.email}`}</span>
                        <br />
                    </>
                ))}
            </div>
        );
    };

    const [, isMobile] = useCurrentWidth();

    const computeEmailsPart = () => {
        return isMobile ? (
            <LightTooltip onClose={handleTooltipClose} open={open} title={computeAttendeesText()}>
                <div
                    className={styles.aligned}
                    onClick={handleTooltipOpen}
                    onKeyDown={handleTooltipOpen}
                    role="button"
                    tabIndex={0}
                >
                    <img alt="userIcon" className={styles.userIcon} src={UserIcon} />
                    <p className={styles.grey}>
                        {`${event.attendees && event.attendees[0].email ? event.attendees[0].email : ''}...`}
                    </p>
                </div>
            </LightTooltip>
        ) : (
            <LightTooltip title={computeAttendeesText()}>
                <div className={styles.aligned}>
                    <img alt="userIcon" className={styles.userIcon} src={UserIcon} />
                    <p className={styles.grey}>
                        {`${event.attendees && event.attendees[0].email ? event.attendees[0].email : ''}...`}
                    </p>
                </div>
            </LightTooltip>
        );
    };

    const computeTimesPart = () => {
        return (
            <div className={styles.flex}>
                <div className={styles.timeContainer}>
                    <img alt="calendar" src={Calendar} />
                    <p>{t('eventCard.date', { date: event.start.dateTime })}</p>
                </div>
                <div className={styles.timeContainer}>
                    <img alt="clock" src={Clock} />
                    <p>
                        {`${t('eventCard.time', { time: event.start.dateTime })} - 
                ${t('eventCard.time', { time: event.end.dateTime })}`}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.card}>
            <div className={styles.content}>
                <span className={styles.headerTitle}>{`${event.summary}`}</span>
                <div className={styles.headerSecondBlock}>
                    {eventIsToday ? <p className={styles.today}>{t('eventCard.today')}</p> : ''}
                    {eventIsTomorrow ? <p className={styles.tomorrow}>{t('eventCard.tomorrow')}</p> : ''}
                    <button onClick={() => onClickEdit(event)} type="button">
                        <img alt="edit" src={Edit} />
                    </button>
                </div>
            </div>
            <div className={`${styles.content} ${styles.middle}`}>
                {event.job && event.job.id === 'avizio' && (
                    <div className={styles.flex}>
                        <div className={styles.companyLogoWrapper}>
                            <div className={styles.companyLogo}>
                                {event.job.id === 'avizio' ? (
                                    <img alt="company logo" className={styles.companyLogo} src={AvizioGold} />
                                ) : (
                                    <CompanyLogo job={event.job} />
                                )}
                            </div>
                        </div>
                        {event.job.id === 'avizio' ? (
                            talentManager?.firstName
                        ) : (
                            <span className={styles.link}>{event.job.name}</span>
                        )}
                    </div>
                )}
                {event.job && event.job.id !== 'avizio' && (
                    <button onClick={() => onClick()} type="button">
                        <div className={styles.flex}>
                            <div className={styles.companyLogoWrapper}>
                                <div className={styles.companyLogo}>
                                    {event.job.id === 'avizio' ? (
                                        <img alt="company logo" className={styles.companyLogo} src={AvizioGold} />
                                    ) : (
                                        <CompanyLogo job={event.job} />
                                    )}
                                </div>
                            </div>
                            {event.job.id === 'avizio' ? (
                                talentManager?.firstName
                            ) : (
                                <span className={styles.link}>{event.job.name}</span>
                            )}
                        </div>
                    </button>
                )}
                {isMobile ? computeTimesPart() : computeEmailsPart()}
            </div>
            <div className={`${styles.content} ${styles.footer}`}>
                {isMobile ? computeEmailsPart() : computeTimesPart()}

                <div
                    className={styles.aligned}
                    onClick={followLocation}
                    onKeyDown={followLocation}
                    role="button"
                    tabIndex={0}
                >
                    {location && (
                        <>
                            <img alt="locationIcon" className={styles.userIcon} src={LocationIcon} />
                            <p className={`${styles.link} ${styles.location}`}>{location}</p>
                        </>
                    )}
                </div>
                <div
                    className={`${styles.aligned} ${styles.open}`}
                    onClick={openEvent}
                    onKeyDown={openEvent}
                    role="button"
                    tabIndex={0}
                >
                    <img alt="event" className={styles.openIcon} src={OpenLink} />
                    <p className={styles.link}>{t('eventCard.open')}</p>
                </div>
            </div>
        </div>
    );
};

export default EventCardDesktop;
