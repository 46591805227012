import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Kanban from 'components/Kanban';
import SimpleModal from 'components/layouts/shared/Modal/SimpleModal';
import useExpandedJob from 'hooks/expandedJob';
import { jobsErrorSelector } from 'core/useCases/jobs/selectors';
import selectMenuItem from 'core/useCases/session/selectMenuItem';

const useMustRedirect = () => {
    const history = useHistory();

    const { expandedJob, loaded } = useExpandedJob();
    const params = useParams<{ id: string }>();

    useEffect(() => {
        if (loaded && params.id && !expandedJob) {
            history.push('/');
        }
    }, [loaded, params, expandedJob]);

    return null;
};

const ProcessPage: FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const jobsError = useSelector(jobsErrorSelector);
    useEffect(() => {
        dispatch(selectMenuItem(1));
    }, []);

    useMustRedirect();

    return (
        <>
            <Kanban />
            {jobsError && (
                <SimpleModal>
                    <h2>{t('kanbanError')}</h2>
                </SimpleModal>
            )}
        </>
    );
};

export default ProcessPage;
