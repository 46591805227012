import React, { FC } from 'react';
import FileInput from 'components/form/lib/FileInput';

interface UploadResumeProps {
    resumeFileName: string | null;
    resumeLink: string | null;
    description?: string;
    label: string;
}

const UploadResume: FC<UploadResumeProps> = ({ resumeFileName, resumeLink, description, label }) => {
    return (
        <FileInput
            description={description || ''}
            fileName={resumeFileName}
            label={label}
            link={resumeLink}
            name="resume"
            validate={() => true}
        />
    );
};

export default UploadResume;
