import User from 'core/domain/model/user';
import { ThunkAction } from 'core/store';
import LoadingState from '../types';
import { setSessionUserAction } from './logInAction';
import { clearSessionUserAction } from './logOutAction';
import setLoading from './setLoadingAction';

export type SessionFetcher = () => Promise<User>;

const syncSession = (sessionFetcher: SessionFetcher) => (): ThunkAction => async (dispatch, getState) => {
    try {
        dispatch(setLoading()(LoadingState.LOADING));
        const user = await sessionFetcher();
        dispatch(setSessionUserAction(user));
    } catch (error) {
        if (getState().session.user) {
            dispatch(clearSessionUserAction());
        }
    } finally {
        dispatch(setLoading()(LoadingState.LOADED));
    }
};

export default syncSession;
