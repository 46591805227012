import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'components/form/lib';
import useModal from 'hooks/modal';
import FormModal from 'components/layouts/shared/Modal/FormModal';
import UpdateUserPasswordForm from 'components/form/UpdateUserPasswordForm';
import styles from './index.module.scss';

const PasswordUpdateInput: FC = () => {
    const { t } = useTranslation();
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <>
            <div className={styles.passwordWrapper}>
                <TextInput
                    label="myInformations.password.label"
                    name="password"
                    placeholder="myInformations.password.placeholder"
                />
                <div className={styles.updateWrapper}>
                    <button className={styles.update} onClick={openModal} type="button">
                        {t('myInformations.update')}
                    </button>
                </div>
            </div>
            {isOpen && (
                <FormModal closeModal={closeModal} titleKey="updatePassword.title">
                    <UpdateUserPasswordForm onSuccess={closeModal} />
                </FormModal>
            )}
        </>
    );
};

export default PasswordUpdateInput;
